import { Theme, ViewStyles } from 'app'

const styles = {
  ...ViewStyles,
  default: {
    display: 'inline-flex',
    cursor: 'pointer',
    textDecorationStyle: 'none',
    transition: '300ms',
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  growSmall: {
    transition: '100ms',
    '&:hover': {
      transform: 'scale(1.01)',
    },
  },
  disabled: {
    cursor: 'default',
    opacity: 0.7,
  },
  highlight: {
    '&:hover': {
      backgroundColor: Theme.colors.darkBrown,
      opacity: 1,
    },
    '&:active': {
      backgroundColor: Theme.colors.darkBrown,
      opacity: 1,
    },
  },
}

export default styles
