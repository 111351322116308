import { Theme } from 'app'
import { Input, React, useState, View, onUpdate } from 'lib'

const EditorPrice = ({ hasDevelopment, currency, defaultValue, onChange = () => null }) => {
  const [value, setValue] = useState(defaultValue)

  onUpdate(() => {
    onChange(value)
  }, [value])

  const onChangeText = (val, key) => {
    setValue(Number(val))
  }

  return (
    <View style={[styles.container, { marginTop: hasDevelopment ? 4 : 0 }]}>
      <View style={styles.wrapper}>
        <View style={styles.sliderWrapper}>
          <Input
            variant={'pill'}
            wrapperStyle={styles.wrapperStyleInput}
            style={styles.input}
            textStyle={styles.textInput}
            value={value}
            onChangeText={e => onChangeText(e)}
            type={'number'}
          />
        </View>
      </View>
    </View>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '60%',

    [Theme.media.is('small')]: {
      width: '100%',
    },
  },
  wrapper: {
    marginTop: Theme.spacing(1),
    flex: 1,
    alignItems: 'center',
  },
  iconWrapper: {
    ...Theme.square(60),
    ...Theme.center,
    borderRadius: Theme.values.borderRadiusSmall,
    border: `1px solid ${Theme.colors.grey}`,
    display: 'flex',
    flexDirection: 'column',
    height: Theme.spacing(6),
    width: Theme.spacing(6),
    marginRight: Theme.spacing(2),

    [Theme.media.is('small')]: {
      marginRight: Theme.spacing(1),
    },
  },
  wrapperStyleInput: {
    width: '100%',
  },
  sliderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginTop: Theme.spacing(1),
    alignItems: 'center',
    width: 'unset',
  },
  buttonRemove: {
    [Theme.media.is('small')]: {
      padding: Theme.spacing(0),
      marginLeft: Theme.spacing(0),
    },
  },
  textInput: {
  },
  input: {
    flex: 0,
    padding: 0,
    textAlign: 'center',
    width: '100%',
  },
}

export default React.memo(EditorPrice)
