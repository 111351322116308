import { React, Button, Input, Link, View, Text, Checkbox, Validate, useState, Toast } from 'lib'
import { AppStatus, Profile } from 'actions'
import { Theme } from 'app'
import SocialSignin from './SocialSignin'

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  contact_number: '',
  password: '',
  confirmPassword: '',
  checked: false,
  imagePath: null,
}

const SignupCard = (isInvitationScreen) => {
  const [form, setForm] = useState(initialState)
  const [isShowingPassword, setIsShowingPassword] = useState(false)
  const [isShowingConfirmPassword, setIsShowingConfirmPassword] = useState(false)

  const submit = () => {
    const authData = {
      email: form.email,
      password: form.password,
    }

    const passwordData = {
      password: form.password,
      confirm_password: form.confirm_password,
    }

    const prevData = {
      email: form.email,
      first_name: form.first_name,
      last_name: form.last_name,
      contact_number: form.contact_number,
      checked: form.checked,
    }

    if (Validate.signUp(prevData, passwordData)) {
      Profile.signupWithEmail(authData, (uid) => {
        const imagePath = form.imagePath
        const data = {
          ...prevData,
          id: uid,
          active: true,
          // currency: 'AED',
        }

        Profile.create({ data, imagePath })
      })
    }
  }

  function onEnterSubmit(event) {
    const keyCode = event.which || event.keyCode
    if (keyCode === 13 && !event.shiftKey) {
      submit()
    }
  }

  function openLogin() {
    AppStatus.dismissModals()
    AppStatus.setLoginModal(true)
  }

  const handleOpenTerms = () => {
    window.open('/info/terms', '_blank')
  }

  const hideShowPassword = () => {
    if (isShowingPassword) {
      setIsShowingPassword(false)
    } else {
      setIsShowingPassword(true)
    }
  }

  const hideShowConfirmPassword = () => {
    if (isShowingConfirmPassword) {
      setIsShowingConfirmPassword(false)
    } else {
      setIsShowingConfirmPassword(true)
    }
  }

  const renderInput = (
    label,
    haveContainer,
    isPassword,
    isConfirmPassword,
    type) => {

    const labelText = label.replace(/\s/g, '_')
    const data = labelText.toLowerCase()

    return (
      <View variant={`${haveContainer && 'marginTop:2'}`}>
        <Input
          labelMsg={label}
          value={form[data]}
          variant={'marginVertical:2 pill'}
          onKeyDown={onEnterSubmit}
          onChangeText={(val) => setForm({ ...form, [data]: val })}
          errorStyle={isPassword || isConfirmPassword ? styles.passwordError : ''}
          hideShowPassword={isPassword ? hideShowPassword : isConfirmPassword && hideShowConfirmPassword}
          type={type}
        />
      </View>
    )
  }

  return (
    <View style={styles.wrapper}>
      {isInvitationScreen && (
        <Text variant={`h4 marginBottom:4`} msg={`Sign up`}/>
      )}
      <View style={styles.inputs}>
        {renderInput('First Name')}
        {renderInput('Last Name')}
        {renderInput('Email', true)}
        {renderInput('Contact Number', true)}
        {renderInput('Password', true, true, false, isShowingPassword ? 'text' : 'password')}
        {renderInput('Confirm Password', true, false, true, isShowingConfirmPassword ? 'text' : 'password')}
      </View>
      <View variant={`marginVertical:2 row alignCenter`}>
        <Checkbox onPress={() => setForm({ ...form, checked: !form.checked })} value={form.checked}/>
        <Link variant={'silent marginLeft:1'} msg={'I agree...'} onPress={handleOpenTerms}/>
      </View>
      <Button variant={'fullWidth orange'}
        text='SUBMIT' onPress={submit}/>
      <SocialSignin showMsgSign/>
      <View variant={`row center`}>
        <Link variant={'silent textCenter'} msg={'Already a user...'} onPress={openLogin}/>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    display: 'block',
  },
  inputs: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: Theme.spacing(5),

    [Theme.media.is('small')]: {
      gridTemplateColumns: '100%',
    },
  },
}

export default SignupCard
