import { Theme, TextStyles, ViewStyles } from '_app'

const defaultTransition = '300ms'

const wrapper = {
  ...ViewStyles,
  default: {
    ...Theme.center,
    ...Theme.paddingHorizontal(2),
    backgroundColor: Theme.colors.black,
    transition: defaultTransition,
    borderRadius: Theme.values.borderRadiusSmall,
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    height: Theme.values.buttonHeight,
    boxSizing: 'border-box',
    '&:hover': {
      opacity: 0.8,
    },
  },
  orange: {
    backgroundColor: Theme.colors.orange,
  },
  defaultBorder: {
    borderRadius: Theme.values.borderRadius,
  },
  small: {
    height: Theme.spacing(4),
  },
  medium: {
    height: Theme.spacing(5),
  },
  underline: {
    backgroundColor: Theme.colors.white,
    color: '#AAAAAA',
    textDecorationLine: 'underline',
    '&:hover': {
      color: '#DEDEDE',
    },
  },
  outline: {
    backgroundColor: Theme.colors.white,
    borderWidth: Theme.values.borderWidth,
    borderStyle: 'solid',
    borderColor: Theme.colors.black,
    color: Theme.colors.black,
    '&:hover': {
      borderColor: Theme.colors.primary,
      color: Theme.colors.primary,
      opacity: 0.8,
    },
    '&:active': {
      backgroundColor: 'transparent',
      opacity: 0.5,
    },
  },
  fullWidth: {
    flex: 1,
    width: '100%',
    ...Theme.center,
  },
  link: {
    backgroundColor: 'transparent',
  },
  icon: {
    borderRadius: '1000px',
    backgroundColor: 'transparent',
    padding: Theme.spacing(1.25),
  },
  clear: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  get iconBackground() {
    return {
      ...this.icon,
      backgroundColor: Theme.colors.darkBrown,
    }
  },
  get iconText() {
    return {
      ...this.icon,
    }
  },
  get outlineWhite() {
    return {
      ...this.outline,
      borderWidth: 2,
      borderColor: 'white',
      '&:hover': {
        borderColor: 'white',
      },
    }
  },
  disabled: {
    backgroundColor: Theme.colors.light,
    opacity: 0.6,
    '&:hover': {
      backgroundColor: Theme.colors.light,
      opacity: 0.6,
    },
  },
  debug: {
    ...Theme.debug('rgb(0, 255, 0)'),
  },
  plain: {
    paddingLeft: 'unset',
    paddingRight: 'unset',
    backgroundColor: 'unset',
    borderRadius: 'unset',
    height: 'unset',
  },
}

const text = {
  default: {
    color: Theme.colors.white,
    fontWeight: '700',
    ...TextStyles.text.p1,
  },
  primaryColor: {
    color: Theme.colors.primary,
  },
  small: {
    ...TextStyles.text.p2,
  },
  medium: {
    ...TextStyles.text.p2,
  },
  underline: {
    color: Theme.colors.lightGrey,
  },
  center: {
    ...Theme.center,
    justifyText: 'center',
  },
  normal: {
    fontWeight: 'normal',
  },
  link: {
    fontWeight: '700',
    color: Theme.colors.black,
    padding: Theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'capitalize',
    ...TextStyles.text.p1,
    ...TextStyles.text.link,
    '&:hover': {
      color: Theme.colors.primary,
    },
  },
  clear: {},
  capitalize: {
    textTransform: 'capitalize',
  },
  get outline() {
    return {
      color: 'inherit',
    }
  },
  get icon() {
    return {
      color: Theme.colors.white,
    }
  },
  get outlineWhite() {
    return {
      color: 'white',
    }
  },
}

export default {
  wrapper,
  text,
}
