import { React, useRef, useCallback, View, Text, Button, Touchable, FileInput, Overlay } from 'lib'
import { Theme } from 'app'
import { AiOutlinePlus } from 'react-icons/ai'
import { useDrop } from 'react-dnd'
import update from 'immutability-helper'
import AssetItem, { AssetItemTypes } from './AssetItem'

const AddNewButton = ({ addAsset }) => {
  const input = useRef(null)

  const onPress = () => {
    input.current.click()
  }

  const onFileChange = (event) => {
    addAsset(event)
  }

  return (
    <Touchable style={styles.itemWrapper} onPress={onPress}>
      <View style={styles.addWrapper}>
        <AiOutlinePlus size={56} color={Theme.colors.lightGrey}/>
      </View>
      <Text variant={'p1 black marginLeft:4'} text={'Add new photo'}></Text>
      <FileInput
        onChange={onFileChange}
        inputRef={input}
        multiple
      />
    </Touchable>
  )
}

const AssetEditorModal = ({
  open,
  data,
  setData,
  onAddAsset,
  onClose,
  titleMsg,
}) => {
  const [, drop] = useDrop({ accept: AssetItemTypes.DROP_ITEM })

  const handleUpdate = useCallback(
    (asset, index) => {
      setData(update(data, {
        [index]: { $set: asset },
      }))
    },
    [data],
  )

  const handleRemove = (index) => {
    setData(update(data, {
      $splice: [[index, 1]],
    }))
  }

  const handleMove = useCallback(
    (id, targetIndex) => {
      const asset = data.filter((item) => item.id === id)[0]
      const index = data.indexOf(asset)
      setData(
        update(data, {
          $splice: [
            [index, 1],
            [targetIndex, 0, asset],
          ],
        }),
      )
    },
    [data],
  )

  const renderAssetItem = (asset, index) => {
    return (
      <AssetItem
        data={asset}
        key={`${asset.id}`}
        index={index}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
        onMove={handleMove}
      />
    )
  }

  return (
    <Overlay open={open}>
      <View style={styles.container}>
        <View variant={'row justifySpaceBetween alignCenter marginBottom:8'}>
          <Text variant={'h3 bold'} msg={titleMsg}></Text>
          <Button
            msg={'DONE'}
            onPress={onClose}
            variant={'orange'}
          />
        </View>
        <View ref={drop}>
          {data?.map((itemData, index) => renderAssetItem(itemData, index))}
        </View>
        <AddNewButton addAsset={onAddAsset}/>
      </View>
    </Overlay>
  )
}

const styles = {
  itemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Theme.spacing(4),
  },
  addWrapper: {
    ...Theme.center,
    display: 'flex',
    backgroundColor: Theme.colors.offwhite,
    border: `1px solid ${Theme.colors.lightGrey}`,
    width: 170,
    height: 150,
    borderRadius: Theme.values.borderRadius,
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    [Theme.media.is('small')]: {
      padding: Theme.spacing(1),
    },
  },
}

export default React.memo(AssetEditorModal)
