import { Text, React, Touchable, getStyles } from 'lib'
import { ButtonStyles, Theme } from 'app'
import { uniqueId } from 'lodash'


function Button(props) {
  const { style, textStyle, icon, iconSide, children, hideMsg, ...otherProps } = props
  const text = props.text || null
  const msg = props.msg || null
  let iconColor = props.color || Theme.colors.primary

  Tools.rejectProps({ children })

  const content = []
  let variants = props.variant

  if (props.icon && (props.text || props.msg)) variants = `iconText ${variants}`
  if (props.icon) variants = `icon ${variants}`
  if (props.disabled) variants = `${variants} disabled`
  if (props.disabled || variants?.toLowerCase().includes('white')) iconColor = 'white'
  if (variants?.toLowerCase().includes('inverted')) iconColor = 'black'
  const isSmall = variants?.toLowerCase().includes('small')
  const iconSize = props.size || (isSmall ? 16 : 20)

  const styles = getStyles(variants, ButtonStyles)

  const getTextIconStyle = () => {
    if (icon && iconSide == 'left') {
      return { marginLeft: Theme.spacing(2) }
    }
    if (icon && iconSide == 'right') {
      return { marginRight: Theme.spacing(2) }
    }
    return {}
  }

  icon && !iconSide && content.push(<props.icon key={uniqueId()} size={iconSize} color={iconColor}/>)

  if (icon && iconSide == 'left') {
    content.push(<props.icon key={uniqueId()} size={iconSize} color={iconColor}/>)
  }

  if ((text || msg) && !hideMsg) {
    content.push(<Text key={uniqueId()} style={[styles.text, textStyle, getTextIconStyle()]} text={text} msg={msg}/>)
  }

  if (icon && iconSide == 'right') {
    content.push(<props.icon key={uniqueId()} size={iconSize} color={iconColor}/>)
  }

  return (
    <Touchable
      style={[styles.wrapper, style]}
      {...otherProps}
      variant={variants}
    >
      {content}
    </Touchable>
  )
}

export default Button
