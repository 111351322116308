import { React, Image, VideoPreview, PDFPreview } from 'lib'
import { Theme } from 'app'

function AssetPreview(props) {
  const { index, item, debug, style } = props

  function renderImagePreview() {
    return (
      <Image
        source={item.preview || item.file}
        style={[index >= 0 && styles.previewBorderRadius, style && styles.listBorderRadius, styles.preview, style]}
      />
    )
  }

  function renderVideoPreview() {
    return <VideoPreview source={item} style={[styles.preview, style]}/>
  }

  function renderPDFPreview() {
    return (
      <PDFPreview source={item} style={[styles.preview, styles.pdf, style]}/>
    )
  }

  function renderPDFThumbail() {
    return (
      <Image
        source={item.thumbnail}
        style={[styles.preview, styles.pdf, style]}
      />
    )
  }
  const fileType = Tools.getAssetFiletype(item)

  if (debug) {
    log({ fileType, props, index, item, file: item.file })
  }

  if (fileType !== '' && fileType == 'image') return renderImagePreview()
  if (fileType !== '' && fileType == 'video') return renderVideoPreview()
  if (fileType !== '' && fileType == 'pdf') {
    if (item.thumbnail) {
      return renderPDFThumbail()
    }
    return renderPDFPreview()
  }
  return null
}

const styles = {
  preview: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    display: 'flex',
  },
  previewBorderRadius: {
    borderRadius: Theme.values.borderRadius,
  },
  listBorderRadius: {
    borderRadius: Theme.values.borderRadiusSmall,
  },
  pdf: {
    overflow: 'hidden',
    backgroundColor: Theme.colors.white,
    'background-clip': 'content-box',
  },
}

export default AssetPreview
