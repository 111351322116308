import { Theme, TextStyles } from '_app'

const defaultTransition = '0ms'

const wrapper = {
  //
  // default input wrapper
  //
  default: {
    default: {
      position: 'relative',
      borderBottom: `1px solid ${Theme.colors.grey}`,
      transition: defaultTransition,
    },
    get hover() {
      return {
        ...this.default,
        borderBottom: `1px solid ${Theme.colors.grey}`,
      }
    },
    get focus() {
      return {
        ...this.default,
        borderBottom: `1px solid ${Theme.colors.grey}`,
      }
    },
    get populated() {
      return {
        ...this.default,
        borderBottom: `1px solid ${Theme.colors.grey}`,
      }
    },
    get invalid() {
      return {
        ...this.default,
        borderBottomColor: 'rgba(255, 20, 0, 1)',
      }
    },
  },
  //
  // pill input wrapper
  //
  get pill() {
    return {
      default: {
        position: 'relative',
        padding: '1px',
        border: `1px solid ${Theme.colors.light}`,
        borderRadius: Theme.values.borderRadiusSmall,
        transition: defaultTransition,
        flexDirection: 'row',
        alignItems: 'center',
        height: Theme.values.inputHeight,
        width: 'unset !important',
      },
      get hover() {
        return {
          ...this.default,
          position: 'relative',
          border: `1px solid ${Theme.colors.light}`,
          // backgroundColor: `${Theme.colors.lightOrange}`,
          borderRadius: Theme.values.borderRadiusSmall,
          transition: defaultTransition,
          ...Theme.elevated,
        }
      },
      get focus() {
        return {
          ...this.default,
          position: 'relative',
          border: `2px solid ${Theme.colors.lightOrange}`,
          padding: 0,
          backgroundColor: `${Theme.colors.lightOrange}0a`,
          borderRadius: Theme.values.borderRadiusSmall,
          transition: defaultTransition,
          ...Theme.elevated2x,
        }
      },
      get populated() {
        return {
          ...this.default,
          position: 'relative',
          border: `1px solid ${Theme.colors.light}`,
          borderRadius: Theme.values.borderRadiusSmall,
          transition: defaultTransition,
        }
      },
      get invalid() {
        return {
          ...this.default,
          border: `1px solid ${Theme.colors.light}`,
          borderRadius: Theme.values.borderRadiusSmall,
          transition: defaultTransition,
        }
      },
    }
  },
  //
  // plain input wrapper
  //
  get plain() {
    return {
      default: {
        position: 'relative',
        borderWidth: 0,
        borderStyle: 'none',
      },
    }
  },
  //
  // plainUnderline input wrapper
  //
  get plainUnderline() {
    return {
      ...this.default,
    }
  },
}

const label = {
  //
  // default input label
  //
  default: {
    default: {
      position: 'relative',
      color: Theme.colors.black,
    },
    get hover() {
      return {
        ...this.default,
        color: Theme.colors.black,
      }
    },
    get focus() {
      return {
        ...this.default,
        color: Theme.colors.black,
      }
    },
    get populated() {
      return {
        ...this.default,
        color: Theme.colors.black,
      }
    },
    get invalid() {
      return {
        ...this.default,
      }
    },
  },
  //
  // pill input label
  //
  get pill() {
    return {
      ...this.default,
      default: {
        ...TextStyles.text.p1,
        marginBottom: Theme.spacing(1),
        transition: defaultTransition,
      },
      get hover() {
        return {
          ...this.default,
          left: Theme.spacing(2),
        }
      },
      get focus() {
        return {
          ...this.default,
          top: -2,
          left: 0,
          fontSize: 13,
        }
      },
      get populated() {
        return {
          ...this.focus,
        }
      },
      get invalid() {
        return {
          ...this.populated,
        }
      },
    }
  },
  plain: {
    default: {
      display: 'none',
    },
    get hover() { return this.default },
    get focus() { return this.default },
    get populated() { return this.default },
  },
}

const staticStyles = {
  //
  // default input staticStyles
  //
  default: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    innerWrapper: {
      flex: 1,
      cursor: 'text',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    },
    innerLabel: {
      flexDirection: 'column',
    },
    label: {
      pointerEvents: 'none',
      flex: 1,
      display: 'flex',
      width: '100%',
      color: Theme.colors.darkest,
      ...TextStyles.text.h3,
      fontWeight: 'bold',
      marginBottom: Theme.spacing(1),
    },
    input: {
      width: '100%',
      fontFamily: Theme.fontFamily,
      backgroundColor: 'transparent',
      height: Theme.values.inputHeight,
      resize: 'none',
      '::placeholder': {
        ...TextStyles.text.p1,
        color: '#aaa',
      },
      ...TextStyles.text.p1,
    },
    multiline: {
      width: '100%',

      '& textarea': {
        padding: Theme.spacing(2),
      },
    },
    validatorText: {
      marginTop: 6,
      color: 'red',
      ...TextStyles.text.p2,
    },
    showPasswordImage: {
      width: Theme.spacing(3),
      height: Theme.spacing(3),
      paddingRight: Theme.spacing(2),
    },
  },
  //
  // pill input staticStyles
  //
  get pill() {
    return {
      ...this.default,
      input: {
        ...this.default.input,
        height: Theme.values.inputHeight,
        padding: `0 ${Theme.spacing(2)}px`,
      },
    }
  },
  //
  // plain input staticStyles
  //
  get plain() {
    return {
      ...this.default,
      input: {
        ...this.default.input,
        minHeight: null,
        marginTop: null,
      },
    }
  },
  //
  // plainUnderline input staticStyles
  //
  get plainUnderline() {
    return {
      ...this.plain,
    }
  },
}

export default {
  wrapper,
  label,
  staticStyles,
}
