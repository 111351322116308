import { React, View, Text, CenterWrapper, Button } from 'lib'
import { Theme } from 'app'
import { FaArrowLeft } from 'react-icons/fa'

const EditorBanner = (props) => {
  const { onSave, onBack, type } = props

  const isSmall = Theme.hooks.down('mid')

  return (
    <View style={styles.wrapper}>
      <View style={styles.innerWrapper}>
        <CenterWrapper contentContainerStyle={styles.centerWrapper}>
          <View variant={'row justifySpaceBetween marginTop:1'} style={styles.topBar}>
            <View variant={'row'}>
              {!isSmall && (
                <Button
                  style={styles.buttonBack}
                  textStyle={styles.buttonText}
                  icon={FaArrowLeft}
                  size={14}
                  msg={'BACK'}
                  onPress={onBack}
                  color={Theme.colors.grey}
                  variant={'small'}
                />
              )}
              <Text variant={'h4'} msg={`${type === 'create' ? 'New' : 'Edit'} listing`} style={styles.title}/>
            </View>
            <View variant={'row alignCenter'}>
              <Button
                msg={'SAVE'}
                textStyle={styles.saveText}
                onPress={onSave}
                variant={'default orange'}
              />
            </View>
          </View>
        </CenterWrapper>
      </View>
    </View>
  )
}


const styles = {
  wrapper: {
    ...Theme.center,
    zIndex: 99,
    position: 'fixed',
    right: 0,
    left: 0,
    top: Theme.values.headerMenuHeight,
  },
  topBar: {
    paddingTop: Theme.spacing(2),
    paddingBottom: Theme.spacing(2),
    alignItems: 'center',
  },
  saveText: {
    fontWeight: 500,
  },
  innerWrapper: {
    backgroundColor: Theme.colors.white,
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'contents',
  },
  buttonBack: {
    justifyContent: 'end',
    padding: 0,
    marginRight: Theme.spacing(3),
  },
  buttonText: {
    marginLeft: Theme.spacing(1),
    color: Theme.colors.grey,
    fontWeight: 600,

    [Theme.media.is('small')]: {
      marginLeft: Theme.spacing(1),
    },
  },
  centerWrapper: {
    padding: Theme.spacing(1),

    [Theme.media.is('small')]: {
      ...Theme.marginHorizontal(1),
    },
  },
}

export default EditorBanner
