/* eslint no-restricted-imports: 'off' */
import { AppStatus, Fetch, store } from 'actions'

const MODULE_NAME = 'crm/'

//
// Actions to dispatch
//
function receiveContacts(data) {
  store.dispatch({ type: 'RECEIVE_CONTACTS', data })
}

async function fetchContacts(onSuccess = () => null, onFailure = () => null) {
  await Fetch({
    url: MODULE_NAME + 'contact/?limit=100',
    method: 'GET',
    successCallback: (res) => {
      if (res?.results) {
        receiveContacts(res.results)
        onSuccess(res.results)
      }
    },
    failureCallback: (res) => {
      onFailure(res)
    },
  })
}

async function createContact(
  { agency, customer, listing = null },
  onSuccess = () => null,
  onFailure = () => null,
) {
  await Fetch({
    url: MODULE_NAME + 'contact/',
    method: 'POST',
    data: { agency, listing, customer },
    successCallback: (res) => {
      onSuccess(res)
    },
    failureCallback: (res) => {
      onFailure(res)
    },
  })
}

async function addAsContact(data, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + 'contact/',
    method: 'POST',
    data,
    successCallback: (data) => {
      onSuccess(data)
    },
  })
}

export default {
  fetchContacts,
  addAsContact,
  createContact,
}
