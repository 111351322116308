import { useRedux } from 'lib'
import { AppStatus } from 'actions'

const defaultPaths = [
  { id: 'rent', msg: 'Rent', to: '/search/rent/' },
  { id: 'buy', msg: 'Buy', to: '/search/buy/' },
  { id: 'find_agent', msg: 'Find an agent', to: '/construction/find_agent/' },
  { id: 'for_agents', msg: 'For agents', to: '/join/as_agency/' },
]

const agencyPaths = [{ id: 'agency', msg: 'Account', to: '/agency/', icon: true }]

const userPaths = [{ id: 'user', msg: 'Account', to: '/user/', icon: true }]

const guestPaths = [
  {
    id: 'login',
    msg: 'Sign in',
    icon: true,
    run: () => {
      AppStatus.setLoginModal(true)
    },
  },
]

const getPaths = () => {
  const isLoggedIn = useRedux('Session.isLoggedIn')
  const me = useRedux('Session.profile')
  const isAgency = Boolean(me?.agency)

  const paths = Object.assign([], defaultPaths)

  if (isAgency) {
    paths.push(...agencyPaths)
  } else if (isLoggedIn) {
    paths.push(...userPaths)
  } else {
    paths.push(...guestPaths)
  }
  return paths
}

export default {
  getPaths,
}
