/* eslint no-restricted-imports: 'off' */
import { Cookies } from 'lib'
const loggedInCookie = Cookies.get('Session.isLoggedIn')

const initialState = {
  modal: {
    signup: false,
    login: false,
    signin: false,
    password: false,
  },
  activity: loggedInCookie == 'true' ? 'loading' : null,
}

export const AppStatus = (state = Tools.copy(initialState), action) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'SET_SIGNUP_MODAL':
      newState.modal.signup = action.data
      return newState
    case 'SET_LOGIN_MODAL':
      newState.modal.login = action.data
      return newState
    case 'SET_PASSWORD_MODAL':
      newState.modal.password = action.data
      return newState
    case 'DISMISS_ALL_MODALS':
      newState.modal = Tools.copy(initialState.modal)
      return newState
    case 'SET_APP_ACTIVITY':
      newState.activity = action.data
      return newState
    default:
      return state
  }
}
