/* eslint-disable react/jsx-closing-tag-location */
import { React, useRef, View, Text, Image, Touchable, Button } from 'lib'
import { Theme } from 'app'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import { Avatar, ListingFeaturesRow, ListingPrice, SliderComponent } from 'components'

const ListingCardComponent = (props, ref) => {
  const {
    onHoverItem = () => null,
    listing,
    isVertical = false,
    isCompact = false,
    isHighlighted,
    isMobileListing,
    isShowCase,
    hideAgency,
    transform,
  } = props

  const slider = useRef()

  const isSmall = Theme.hooks.down('mid')

  const handleNextSlide = (e) => {
    e.preventDefault()
    slider?.current?.slickNext()
  }

  const handlePrevSlide = (e) => {
    e.preventDefault()
    slider?.current?.slickPrev()
  }

  const renderAgencyRow = () => {
    return (
      <View variant={'row alignCenter'}>
        <Avatar variant={'borders smallCircle'} profile={{ avatar: listing.agency?.logo }}/>
        <Text variant={'clamp grey marginLeft:1 p2'} text={listing.agency?.name}/>
      </View>
    )
  }

  const renderSliderButtons = () => {
    const showSliderButtons = listing?.media && listing?.media.length > 1
    if (!showSliderButtons) return null
    return (
      <>
        <Button
          style={[styles.arrow, !isHighlighted && styles.hidden]}
          size={ARROW_ICON_SIZE}
          color={'black'}
          icon={MdKeyboardArrowLeft}
          variant={'plain'}
          onPress={handlePrevSlide}
        />
        <Button
          style={[styles.arrow, styles.arrowRight, !isHighlighted && styles.hidden]}
          icon={MdKeyboardArrowRight}
          variant={'plain'}
          size={ARROW_ICON_SIZE}
          color={'black'}
          onPress={handleNextSlide}
        />
      </>
    )
  }

  const handleHover = (hover) => {
    onHoverItem(hover, listing)
  }

  return (
    <Touchable
      ref={ref}
      variant={`${isShowCase && 'flex column'}`}
      style={[styles.wrapper,
        isVertical && styles.wrapperVertical,
        isShowCase && styles.wrapperShowCase,
        isCompact && styles.wrapperCompact,
        isMobileListing && styles.wrapperMobileListing,
        isShowCase && isSmall && styles.wrapperShowCaseMobile,
        !isSmall && { transform },
      ]}
      onHover={handleHover}
      {... (!hideAgency ? { to: `/listing/${listing.id}/view` } : {})}
      {...props}
    >
      <View style={[styles.slider,
        isVertical && styles.sliderVertical,
        isHighlighted && styles.sliderHighlighted,
        hideAgency && isHighlighted && styles.sliderHighlightedMap,
        isCompact && styles.sliderCompact,
        isMobileListing && styles.sliderMobileListing,
        isShowCase && styles.sliderShowCase,
      ]}>
        {renderSliderButtons()}
        {isSmall && !isMobileListing ? (
          <Image variant={'skeleton'} source={listing?.media[0]?.file}
            style={[styles.image, isShowCase && styles.imageShowCase, isMobileListing && styles.imageFullBorder]}/>

        ):
          <SliderComponent
            ref={slider}
            dots={false}
            swipeToSlide={false}
            slideWidth={isSmall && 350}
            infinite
          >
            {listing?.media.map((media, index) => {
              return <Image variant={'skeleton'} key={index} source={media?.file} style={styles.image}/>
            })}
          </SliderComponent>
        }

      </View>
      <Touchable {... (hideAgency ? { to: `/listing/${listing.id}/view` } : {})}>
        <View variant={'flex column'} style={[styles.details,
          isVertical && styles.detailsVertical,
          isCompact && styles.detailsCompact,
        ]}>
          {!isCompact && <Text variant={'p2 lightGrey clamp uppercase'} text={`${listing.location?.address}`}/>}
          <Text variant={`${hideAgency ? 'h4' : 'h3'} clamp black`} text={listing.title} style={styles.resetLineHeight}/>
          <ListingFeaturesRow data={listing} hideAgency={hideAgency} isCompact={isCompact}/>
          {!isCompact && !hideAgency && renderAgencyRow()}
          <View>
            <View variant={`flex justifySpaceBetween ${isVertical && 'column'}`}>
              <ListingPrice abbreviation={Boolean(hideAgency)} variant={`${hideAgency ? 'h4' : 'h3'} black clamp`} style={styles.resetLineHeight} listing={listing}/>
            </View>
          </View>
        </View>
      </Touchable>
    </Touchable>
  )
}

const LISTING_CARD_MOBILE_HEIGHT = 380
const IMAGE_WIDTH = 300
const IMAGE_WIDTH_SMALL = 180
const SEARCH_CARD_HEIGHT = 200
const SEARCH_CARD_HEIGHT_SMALL = 140
const ARROW_CIRCLE_SIZE = 30
const ARROW_ICON_SIZE = 22
const ARROW_SPACING = Theme.spacing(1)
const TRANSITION = '300ms'

// const VERTICAL_CARD_DETAILS_HEIGHT = 240

const styles = {
  priceContainer: {
    flex: 'unset',
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: `${IMAGE_WIDTH}px auto`,
    // height: '100%',
    // height: 200,
    maxHeight: SEARCH_CARD_HEIGHT,
    backgroundColor: Theme.colors.white,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: Theme.colors.light,
    // marginBottom: Theme.spacing(1),
    // paddingBottom: Theme.spacing(3),
    transition: TRANSITION,
    ...Theme.paddingVertical(3),

    [Theme.media.down('mid')]: {
      gridTemplateColumns: `130px 200px`,
      // height: SEARCH_CARD_HEIGHT_SMALL,
      // height: LISTING_CARD_MOBILE_HEIGHT,
      maxHeight: 'unset',
    },
  },
  wrapperShowCase: {
    height: 500,
    [Theme.media.down('mid')]: {
      height: 450,
    },
    display: 'block',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },

  wrapperMobileListing: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'unset',
    paddingBottom: 0,
    // height: '450px',
  },
  wrapperShowCaseMobile: {
    // margin: '0 0 0 16px',
  },
  resetLineHeight: {
    lineHeight: 'unset !important',
  },
  wrapperVertical: {
    height: 'auto',
    width: '100%',
    maxHeight: 'unset',
    boxSizing: 'border-box',
    gridTemplateColumns: 'auto !important',
    borderRadius: Theme.values.borderRadius,
    marginBottom: Theme.spacing(0),
    ...Theme.paddingVertical(0),
    ...Theme.elevated,

    [Theme.media.down('mid')]: {
      // height: 140,
      // width: '95%',
      marginBottom: Theme.spacing(3),
    },
  },
  wrapperCompact: {
    ...Theme.elevated2x,
    ...Theme.paddingVertical(0),
    marginLeft: Theme.spacing(2),
    marginRight: Theme.spacing(2),
    borderRadius: Theme.values.borderRadius,
    overflow: 'hidden',
    [Theme.media.down('mid')]: {
      height: 140,
    },
  },
  slider: {
    transition: TRANSITION,
    position: 'relative',
    // overflow: 'hidden',
    // height: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Theme.colors.lightest,
    borderRadius: Theme.values.borderRadius,
    maxHeight: 250,
    [Theme.media.down('mid')]: {
      maxHeight: '500',
      // height: 500,
    },
    '.slick-list': {
      borderRadius: Theme.values.borderRadius,
      pointerEvents: 'none',
    },
    '.slick-slide': {
      height: '200px',
    },
    '.slick-slide > div': {
      // height: '100%',
      overflow: 'hidden',
      [Theme.media.down('mid')]: {
        // height: SEARCH_CARD_HEIGHT_SMALL,
      },
    },
    '.slick-slide img': {
      height: '100%',
      objectFit: 'cover',
    },
  },
  sliderMobileListing: {
    height: 'auto',
  },
  sliderShowCase: {
    [Theme.media.down('mid')]: {
      height: '200px',
    },
    // '.slick-list': {
    //   margin: 'unset',
    //   padding: 'unset',
    //   overflow: 'unset',
    // },
  },
  sliderHighlighted: {
    ...Theme.elevated2x,
  },
  sliderHighlightedMap: {
    overflow: 'hidden',
  },
  sliderMap: {
    pointerEvents: 'none',
  },
  sliderVertical: {
    borderWidth: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '.slick-list': {
      borderRadius: Theme.values.borderRadius,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  sliderCompact: {
    borderWidth: 0,
    borderRadius: 0,
    '.slick-list': {
      borderRadius: 0,
    },
  },
  arrow: {
    ...Theme.absolute,
    // top: (SEARCH_CARD_HEIGHT-ARROW_CIRCLE_SIZE)/2,
    top: `calc(50% - ${ARROW_CIRCLE_SIZE/2}px)`,
    backgroundColor: `${Theme.colors.white}dd`,
    ...Theme.circle(ARROW_CIRCLE_SIZE),
    ...Theme.center,
    left: ARROW_SPACING,
    zIndex: 10,
    // [Theme.media.down('mid')]: {
    //   top: 50,
    // },
  },
  arrowRight: {
    left: 'unset',
    right: ARROW_SPACING,
  },
  image: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  imageShowCase: {
    borderTopLeftRadius: Theme.values.borderRadiusSmall,
    borderTopRightRadius: Theme.values.borderRadiusSmall,
  },
  imageFullBorder: {
    borderRadius: Theme.values.borderRadius,
    height: 220,
  },
  details: {
    padding: `${Theme.spacing(0)}px ${Theme.spacing(3)}px`,
    justifyContent: 'space-between',
    gap: Theme.spacing(1.5),

    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(2),
      paddingBottom: Theme.spacing(1),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  detailsVertical: {
    padding: Theme.spacing(3),
    gap: Theme.spacing(1.5),
    justifyContent: 'space-between',

    [Theme.media.down('mid')]: {
      padding: Theme.spacing(2),
    },
  },
  detailsCompact: {
    padding: 0,
    minHeight: 'unset',
    marginLeft: Theme.spacing(1),
    justifyContent: 'space-around',
  },
  logoPlaceholder: {
    width: Theme.spacing(3),
    height: Theme.spacing(3),
    borderRadius: Theme.values.borderRadiusSmall,
    marginRight: Theme.spacing(1),
  },
  logoAgency: {
    width: Theme.spacing(3),
    height: Theme.spacing(3),
    borderRadius: Theme.values.borderRadiusSmall,
  },
  hidden: {
    transition: TRANSITION,
    opacity: 0,
    '&:hover': {
      opacity: 0,
    },
  },
}

const ListingCard = React.forwardRef(ListingCardComponent)

export default React.memo(ListingCard)
