import { React, View, Text, Image, Touchable, useState, useRedux } from 'lib'
import PropTypes from 'prop-types'
import { Theme } from 'app'
import trash from '../../assets/trash.svg'

const DESCRIPTION_PREVIEW_LENGTH = 60
const TITLE_PREVIEW_LENGTH = 35
function AccountListingCard(props) {
  const { listing, style, showStatus = true, viewOnly = false, onDelete } = props
  const profile = useRedux('Session.profile')
  const isAgency = Boolean(profile.agency)

  const listingStatus = listing?.status

  const [hover, onHover] = useState(false)

  const isSmall = Theme.hooks.down('mid')

  const description = Tools.ellipsis(listing?.description, DESCRIPTION_PREVIEW_LENGTH)
  const title = Tools.ellipsis(listing?.title, TITLE_PREVIEW_LENGTH)
  const address = Tools.ellipsis(listing?.location?.address)

  const getStatusMsg = () => {
    switch (listingStatus) {
      case 'AP':
        return 'Approved'
      case 'PD':
        return 'Pending'
      case 'IN':
        return 'Inactive'
      default:
        return null
    }
  }

  const getTagTextColor = () => {
    switch (listingStatus) {
      case 'PD':
        return Theme.colors.orange
      case 'AP':
        return Theme.colors.primary
      case 'IN':
        return Theme.colors.light
      default:
        return Theme.colors.lightGrey
    }
  }

  const handleDelete = (e) => {
    e.preventDefault()
    onDelete(listing)
  }

  const to = `/listing/${listing.id}/${viewOnly && isAgency ? 'view' : 'edit'}`

  const statusMsg = getStatusMsg()

  const statusTagMsg = listingStatus === 'AP'? 'Active' : 'Inactive'
  const listingTypeMsg = listing.to_rent ? 'Rent' : 'Buy'

  return (
    <Touchable style={[styles.wrapper, style]} to={to} onHover={onHover}>
      <Image style={styles.image} source={listing?.media[0]?.file}/>
      <View style={styles.bottomCard}>
        <View>
          <Text variant={`p2 clamp grey uppercase`} text={address}/>
          <Text variant={`flex p1 bold marginTop:2`} text={description}/>
        </View>
        <View style={styles.tagWrapper}>
          <View variant={'marginRight:2'} style={styles.tagItem}>
            <Text variant={`p3 uppercase bold black`} msg={listingTypeMsg}/>
          </View>
          <View style={[styles.tagItem, listingStatus === 'AP' ? styles.tagItemActive : styles.tagItemInactive]}>
            <Text variant={`p3 uppercase bold black`} msg={statusTagMsg}/>
          </View>
          {(statusMsg && showStatus) ? (
            <View variant={'marginLeft:auto'} style={styles.trash}>
              <Touchable onPress={(e) => handleDelete(e)} style={[hover ? styles.trashActive : styles.trashDisable, isSmall && styles.trashActive]}>
                <Image style={styles.trashIcon} source={trash}/>
              </Touchable>
            </View>
          ) : (
            <View style={styles.bottomRow}>
              <Text variant={'bold link'} msg={'See more'}/>
            </View>
          )}
        </View>
      </View>
    </Touchable>
  )
}

const styles = {
  wrapper: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: 370,
    ...Theme.elevated2x,
    borderRadius: Theme.values.borderRadius,
    transition: '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
    display: 'flex',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  trash: {
    marginLeft: 'auto',
  },
  tagWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  bottomCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: Theme.spacing(3),
    flex: 1,
  },
  tagItem: {
    padding: Theme.spacing(1),
    backgroundColor: '#FEE2D1',
    width: '20%',
    borderRadius: Theme.values.borderRadiusSmall,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tagItemActive: {
    backgroundColor: '#C5F8CD',
  },
  tagItemInactive: {
    backgroundColor: '#E1E1E1',
  },
  trashContainer: {
    paddingLeft: Theme.spacing(3),
    paddingRight: Theme.spacing(3),
    paddingBottom: Theme.spacing(3),
    marginBottom: 0,
  },
  trashActive: {
    visibility: 'visible',
    opacity: 1,
  },
  trashDisable: {
    visibility: 'hidden',
    opacity: 0,
  },
  trashIcon: {
    width: Theme.spacing(2.5),
  },
  image: {
    borderRadius: Theme.values.borderRadius,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: '100%',
    height: 180,
    objectFit: 'cover',
    flexGrow: 0,
  },
  bottomRow: {
    ...Theme.flex,
    justifyContent: 'flex-end',
  },
}

AccountListingCard.propTypes = {
  data: PropTypes.object,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  role: PropTypes.oneOf(['developer', 'agency']),
  previewLength: PropTypes.number,
}

AccountListingCard.defaultProps = {
  data: {},
  role: 'agency',
  previewLength: 60,
}

export default AccountListingCard
