import validator from 'validator'
import { Toast } from 'lib'

export function field(input) {
  const isValid = input && input.length >= 3
  return isValid ? null : 'validateField'
}

export function email(input) {
  const isValid = input && validator.isEmail(input)
  return isValid ? null : 'validateEmail'
}

export function username(input) {
  const regex = (/^[a-z0-9-_]+$/)
  const isValid = Boolean(input.match(regex))
  return isValid ? null : 'validateUsername'
}

export function password(input) {
  const isValid = input.length >= 6
  return isValid ? null : 'validatePassword'
}

export function development(obj) {
  const checkLength = ['title', 'property_type', 'features', 'description']
  const dataObject = obj?.data
  for (const l in checkLength) {
    if (!dataObject[checkLength[l]].length) {
      Toast.error(`Please enter ${checkLength[l].replace('_', ' ')}`)
      return false
    }
  }

  if (!dataObject?.location?.address?.length) {
    Toast.error(`Please enter location`)
    return false
  }

  if (dataObject?.features.length < 5 && dataObject?.property_type !== 5) {
    Toast.error(`Please check at least 5 features`)
    return false
  }

  if (!dataObject?.property_type) {
    Toast.error(`Please add one property type`)
    return false
  }

  if (dataObject?.brochures.length < 1) {
    Toast.error(`Please add at least 1 brochure asset to your listing`)
    return false
  }

  if (dataObject?.floorplans.length < 1) {
    Toast.error(`Please add at least 1 floorplan asset to your listing`)
    return false
  }

  if (dataObject?.media.length < 5 && obj.media.length < 5) {
    Toast.error(`Please add at least 5 medias asset to your development`)
    return false
  }

  if (!dataObject?.price) {
    Toast.error(`Please enter Price`)
    return false
  }

  if (!dataObject?.floor_area) {
    Toast.error(`Please enter Floor Area`)
    return false
  }

  if (!dataObject?.bedrooms) {
    Toast.error(`Please enter Beedrooms`)
    return false
  }

  if (!dataObject?.bathrooms) {
    Toast.error(`Please enter Bathrooms`)
    return false
  }

  if (!dataObject?.tc_agreed) {
    Toast.error(`You must agree with the terms and conditions`)
    return false
  }

  return true
}

export function listing(obj) {
  const checkLength = ['title', 'description']
  const dataObject = obj?.data
  for (const l in checkLength) {
    if (!dataObject[checkLength[l]]?.length) {
      Toast.error(`Please enter ${checkLength[l].replace('_', ' ')}`)
      return false
    }
  }

  if (!dataObject?.media || (dataObject?.media?.length < 5 && (obj.media && obj.media?.length < 5))) {
    Toast.error(`Please add at least 5 media assets to your listing`)
    return false
  }

  if (!dataObject?.currency) {
    Toast.error(`Please select a currency`)
    return false
  }

  if (!dataObject?.price) {
    Toast.error(`Please enter Price`)
    return false
  }

  if (dataObject?.floor_area !== null && dataObject?.floor_area <= 0) {
    Toast.error(`Please enter Floor Area`)
    return false
  }

  if (!dataObject?.bedrooms) {
    Toast.error(`Please enter Beedrooms`)
    return false
  }

  if (!dataObject?.bathrooms) {
    Toast.error(`Please enter Bathrooms`)
    return false
  }

  if (dataObject?.living_rooms !== null && dataObject?.living_rooms <= 0) {
    Toast.error(`Please enter Living Room`)
    return false
  }

  if (!dataObject?.property_type) {
    Toast.error(`Please add a property type`)
    return false
  }

  if (!dataObject?.features || (dataObject?.features?.length < 5 && (dataObject?.property_type !== 5))) {
    Toast.error(`Please check at least 5 features`)
    return false
  }

  if (dataObject.location.coords.lat === null && dataObject.location.coords.lng === null) {
    Toast.error(`Please enter location`)
    return false
  }

  if (!dataObject?.tc_agreed) {
    Toast.error(`You must agree with the terms and conditions`)
    return false
  }
  return true
}

export function agency(obj) {
  const checkLength = ['name', 'phone', 'email', 'description']
  const dataObject = obj?.data
  for (const l in checkLength) {
    if (!dataObject[checkLength[l]]?.length) {
      Toast.error(`Please enter agency ${checkLength[l].replace('_', ' ')}`)
      return false
    }
  }

  return true
}

export function profile(obj) {
  const checkLength = ['first_name', 'last_name', 'email', 'contact_number']
  const dataObject = obj?.data

  for (const l in checkLength) {
    if (!dataObject[checkLength[l]]?.length) {
      Toast.error(`Please enter profile ${checkLength[l].replace('_', ' ')}`)
      return false
    }
  }

  if (!dataObject?.currency) {
    Toast.error(`Please select a currency`)
    return false
  }

  // if (!dataObject?.currency) {
  //   Toast.error(`Please select currency`)
  //   return false
  // }

  // if (!dataObject?.location?.address?.length) {
  //   Toast.error(`Please enter location`)
  //   return false
  // }

  // if (!dataObject?.tc_agreed) {
  //   Toast.error(`You must agree with the terms and conditions`)
  //   return false
  // }
  return true
}

export function signUp(dataObj, passwordObj) {
  const checkLength = ['first_name', 'last_name']

  for (const l in checkLength) {
    if (!dataObj[checkLength[l]]?.length) {
      Toast.error(`Please enter ${checkLength[l].replace('_', ' ')}`)
      return false
    }
  }

  if (email(dataObj.email)) {
    Toast.error(`Please enter valid email`)
    return false
  }

  if (!dataObj.contact_number.length) {
    Toast.error(`Please enter contact number`)
    return false
  }

  if (password(passwordObj.password)) {
    Toast.error(`Please enter valid password`)
    return false
  }

  if (passwordObj.password !== passwordObj.confirm_password && passwordObj.password.length >= 6) {
    Toast.error(`Passwords don't match`)
    return false
  }

  if (!dataObj?.checked) {
    Toast.error(`You must agree with the terms and conditions`)
    return false
  }

  return true
}

export default {
  field,
  email,
  username,
  password,
  development,
  listing,
  profile,
  agency,
  signUp,
}
