import { React, useState, View, Touchable, Text, useCallback } from 'lib'
import { Theme } from 'app'
import { chunk, findIndex, takeRight, findLast, dropRight } from 'lodash'
import { AssetLightbox } from 'components'
import ListingAssetGalleryItem from './ListingAssetGalleryItem'
import floorplan from '../../assets/floorplan.png'

function ListingAssetGallery({ data, floorplans, titleMsg, showPreviewPDF }) {
  const md = Theme.hooks.media()
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const [floorplansData, setShowFloorplans] = useState(false)

  const mapImagesData = useCallback(() => {
    const chunkedData = chunk(data, md === 'small' ? 1 : 5)
    const lastDataArray = findLast(chunkedData)

    const getCloneData = (cloneFrom = 1) => {
      const cloneDataArray = chunkedData[chunkedData?.length - 2]
      const newChunkedData = dropRight(chunkedData)

      return [
        ...newChunkedData,
        [...takeRight(cloneDataArray, cloneFrom), ...lastDataArray],
      ]
    }

    if (lastDataArray?.length < 5) {
      switch (lastDataArray?.length) {
        case 1:
          return getCloneData(4)
        case 2:
          return getCloneData(3)
        case 3:
          return getCloneData(2)
        case 4:
          return getCloneData(1)
        default:
          return chunkedData
      }
    }

    return chunkedData
  }, [data])

  const imagesData = mapImagesData()

  const onOpen = (id) => {
    const index = findIndex(data, { id })
    setShowFloorplans(false)
    setSelectedItem(index)

    setOpen(true)
  }

  const onOpenFloorplan = () => {
    setShowFloorplans(true)
    setSelectedItem(0)
    setOpen(true)
  }

  const previewContent = [
    ...imagesData[0]?.map((img, index) => {
      return (
        <ListingAssetGalleryItem
          item={img}
          key={index}
          index={index}
          length={imagesData[0]?.length}
          onOpen={onOpen}
          showPreviewPDF={showPreviewPDF}
        />
      )
    }),
  ]

  const itemsMobile = previewContent.slice(0, previewContent?.length)
  const firstItem = previewContent.slice(0, 1)
  const remainingItems = previewContent.slice(1, previewContent?.length)

  if (md === 'small') {
    return (
      <View style={staticStyles.wrapper}>
        <View style={staticStyles.innerWrapper}>
          <View style={staticStyles.mobileWrapper}>{itemsMobile}</View>
        </View>

        <View style={staticStyles.floatButtons}>
          <Touchable style={[staticStyles.assetMetaWrapper]} onPress={() => onOpen(imagesData[0][0].id)}>
            <Text variant={'p3 black uppercase'} text={`Show all ${data?.length} photos`}/>
          </Touchable>
          {floorplans?.length > 0 &&
            <Touchable style={[staticStyles.assetMetaWrapper]} onPress={() => onOpenFloorplan(floorplans[0].id)} variant={'marginLeft:1'}>
              <img src={floorplan} style={staticStyles.icons}/>
            </Touchable>
          }
        </View>

        <AssetLightbox
          open={open}
          data={floorplansData ? floorplans : data}
          titleMsg={floorplansData ? 'Floorplans' : titleMsg}
          initialSlide={selectedItem}
          onClose={() => setOpen(false)}
        />
      </View>
    )
  }

  return (
    <View style={staticStyles.wrapper} variant={'marginTop:2'}>
      <View style={staticStyles.floatButtons}>
        <Touchable style={[staticStyles.assetMetaWrapper]} onPress={() => onOpen(imagesData[0][0].id)}>
          <Text variant={'p3 black uppercase'} text={`Show all ${data?.length} photos`}/>
        </Touchable>
        {floorplans?.length > 0 &&
          <Touchable style={[staticStyles.assetMetaWrapper]} onPress={() => onOpenFloorplan(floorplans[0].id)} variant={'marginLeft:1'}>
            <img src={floorplan} style={staticStyles.icons}/>
          </Touchable>
        }
      </View>
      <View style={staticStyles.innerWrapper}>
        <View>{firstItem}</View>
        <View style={staticStyles.remainingItems}>
          {remainingItems}
        </View>
      </View>

      <AssetLightbox
        open={open}
        data={floorplansData ? floorplans : data}
        titleMsg={floorplansData ? 'Floorplans' : titleMsg}
        initialSlide={selectedItem}
        onClose={() => setOpen(false)}
      />
    </View>
  )
}

const staticStyles = {
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  floatButtons: {
    position: 'absolute',
    top: Theme.spacing(1),
    right: Theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 90,

    [Theme.media.is('small')]: {
      top: 'auto',
      bottom: 0,
      right: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      justifyContent: 'center',
    },
  },
  assetMetaWrapper: {
    backgroundColor: Theme.colors.white,
    borderRadius: Theme.values.borderRadiusSmall,
    padding: Theme.spacing(1),
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    ...Theme.elevated,
  },
  icons: {
    width: Theme.spacing(2),
    height: Theme.spacing(2),
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 550,
    [Theme.media.down('mid')]: {
      height: 320,
    },
    borderRadius: Theme.values.borderRadius,
    overflow: 'hidden',
  },
  remainingItems: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: Theme.spacing(1),
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: '50%',
    marginLeft: Theme.spacing(1),
    height: '100%',
    width: '100%',
  },
  mobileWrapper: {
    width: '100%',
  },
}

export default ListingAssetGallery
