/* eslint no-restricted-imports: 'off' */
import { Text, View, Button } from 'lib'
import { MdClose } from 'react-icons/md'
import { Theme } from 'app'
import { AppStatus } from 'actions'
import Modal from '@material-ui/core/Modal'

/** @jsx jsx */
import { jsx } from '@emotion/react'

export default function ModalMaterialUi({
  open,
  onClose,
  onSave,
  titleMsg,
  style,
  contentStyle = {},
  children,
  buttonStyle,
  stylesHeader,
  showIconHeader,
  showHeader = true,
  onCloseMsg = 'Cancel',
  hasButtonsFooter = false,
  onClickConfirmButton,
  onClickCancelButton,
  labelConfirmButton,
  labelCancelButton,
}) {

  const close = () => {
    if (onClose) {
      onClose()
    } else {
      AppStatus.dismissModals()
    }
  }

  return (
    <Modal style={{ zIndex: 9999 }} open={open} onClose={close}>
      <View style={[styles.wrapper, style]}>
        <View style={styles.header} variant={'row alignCenter'}>
          {showIconHeader && (
            <View variant={'row'}>
              <Button icon={MdClose} style={styles.cancelWrapper} size={28} onPress={close} color={Theme.colors.black}/>
            </View>
          )}
          {showHeader && (
            <View style={[styles.modalHeader, stylesHeader]} variant={'row flex alignCenter justifySpaceBetween'}>
              {titleMsg && <Text variant={'flex h3 bold black'} msg={titleMsg}></Text>}

              <View variant={'row alignCenter'}>
                <View variant={'row alignCenter margin'}>
                  {onSave && <Button onPress={onSave}
                    style={buttonStyle}
                    variant={'medium'}
                    msg={'Save'}/>}
                </View>

                <View variant={'row alignCenter margin'}>
                  {onClose && (
                    <View variant={'row'}>
                      <Button icon={MdClose} size={28} onPress={close} color={Theme.colors.black}/>
                    </View>
                  )}
                </View>
              </View>
            </View>
          )}
        </View>

        <View style={[styles.content, contentStyle]}>
          {children}
        </View>

        {hasButtonsFooter && (
          <View
            style={styles.footer}
            variant={'row alignCenter justifySpaceBetween'}
          >
            <Button
              onClick={onClickConfirmButton}
              variant={'default orange'}
              msg={labelConfirmButton}
            />
            <Button
              onClick={onClickCancelButton}
              variant={'small underline'}
              msg={labelCancelButton}
            />
          </View>
        )}
      </View>
    </Modal>
  )
}

const styles = {
  wrapper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '55%',
    borderRadius: Theme.values.borderRadius,
    [Theme.media.down('mid')]: {
      borderRadius: 0,
    },
    background: Theme.colors.white,
    // backgroundColor: 'blue',
    outline: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    overflow: 'auto !important',
    padding: Theme.spacing(4),
    height: '100%',
    // height: '70vh',
    // maxHeight: 'calc(100vh - 290px)',
    [Theme.media.down('mid')]: {
      padding: Theme.spacing(2),
      // height: '75vh',
      // maxHeight: 'calc(100vh - 200px) !important',
    },
  },
  cancelWrapper: {
    marginLeft: 'auto',
    position: 'absolute',
    right: 30,
    top: 25,
    width: 'auto',
    [Theme.media.down('mid')]: {
      top: 12,
    },
  },
  header: {
    overflow: 'hidden',
    borderBottomWidth: 1,
    borderBottomColor: Theme.borders.borderColor,
    borderBottomStyle: 'solid',
    padding: Theme.spacing(4),
    [Theme.media.down('mid')]: {
      padding: Theme.spacing(2),
    },
  },
  footer: {
    overflow: 'hidden',
    borderTopWidth: 1,
    borderTopColor: Theme.borders.borderColor,
    borderTopStyle: 'solid',
    padding: Theme.spacing(4),
    backgroundColor: Theme.colors.white,
    [Theme.media.down('mid')]: {
      padding: Theme.spacing(2),
    },
  },
}
