/* eslint no-console: "off", no-restricted-imports: "off"  */
console.log('Loading Logger.js...')

import { Settings } from 'app'
import callsites from 'callsites'
// import { inspect } from 'util' // https://nodejs.org/api/util.html

const IGNORED_WARNINGS = [
  'Require cycle:',
  'Warning: componentWillMount has been renamed, and is not recommended for use.',
]
if (Settings.WARN_INTERNATIONALIZATION == false) {
  IGNORED_WARNINGS.push('[React Intl]')
}

// ignores some warnings to make shit less annoying
if (Settings.IS_DEVELOPMENT) {
  const oldConsoleWarn = console.warn
  const oldConsoleError = console.error
  const newConsole = (args, oldConsole) => {
    // const tmp = args.join(' ')
    // console.log('tmp', tmp)
    if (
      typeof args[0] === 'string' &&
      IGNORED_WARNINGS.some(ignoredWarning => args.join(' ').includes(ignoredWarning))
    ) {
      return
    }
    return oldConsole.apply(console, args)
  }
  console.warn = (...args) => newConsole(args, oldConsoleWarn)
  console.error = (...args) => newConsole(args, oldConsoleError)
}

global.log = (key, value, category) => {
  customLogger(key, value, category, 'log')
}

global.debug = {
  green: (key, value, category) => { customLogger(key, value, category, 'log', 'green') },
  blue: (key, value, category) => { customLogger(key, value, category, 'log', 'blue') },
  red: (key, value, category) => { customLogger(key, value, category, 'log', 'red') },
  yellow: (key, value, category) => { customLogger(key, value, category, 'log', 'yellow') },
  cyan: (key, value, category) => { customLogger(key, value, category, 'log', 'cyan') },
  purple: (key, value, category) => { customLogger(key, value, category, 'log', 'purple') },
}

global.deb = {
  green: (key, value, category) => { customLogger(key, value, category, 'log', 'green') },
  blue: (key, value, category) => { customLogger(key, value, category, 'log', 'blue') },
  red: (key, value, category) => { customLogger(key, value, category, 'log', 'red') },
  yellow: (key, value, category) => { customLogger(key, value, category, 'log', 'yellow') },
  cyan: (key, value, category) => { customLogger(key, value, category, 'log', 'cyan') },
  purple: (key, value, category) => { customLogger(key, value, category, 'log', 'purple') },
}

global.warn = (key, value, category) => {
  customLogger(key, value, category, 'warn')
}

global.info = (key, value, category) => {
  customLogger(key, value, category, 'info')
}

global.error = (key, value, category) => {
  customLogger(key, value, category, 'error')
}

function callFuncWithArgs(logger, ...args) {
  logger(...args)
}

export default function customLogger(key, value, category = null, type, color) {
  if (!value && key) {
    value = key
    if (typeof key == 'object') {
      const keys = Object.keys(key)
      const firstKey = keys[0]
      if (keys.length == 1) {
        value = key[firstKey]
        key = firstKey
      } else {
        key = ''
      }
    } else {
      key = ''
    }
  } else if (!key) {
    value = ''
    key = ''
  }
  // captureBreadcrumb(key, value, type) // WARNING - remove this - debug only
  if (Settings.IS_DEVELOPMENT == true && Settings.IS_BROWSER) {
    // NOTE USE THE CODE BELOW TO CHECK ALL CALLSITES FOR EXTREME DEBUGGING
    // const cs = callsites()
    // for (const c in cs) { console.log('func', cs[c].getFunctionName()) }
    let funcName = ''
    let caller = ''
    try {
      funcName = callsites()[2].getFunctionName() // COMBAK find a way of getting the callsites that works on the device's JS engine
      caller = funcName && !funcName.includes('calle') ? funcName + '() ' : ''
    } catch (err) {
      // log('No callsites available')
    }
    let title
    let titleStyle
    const titleStr = (category ? `(${category}) ` : '') + caller + key
    if (type == 'error' || type == 'warn') {
      // const logger = console[type]
      // const logger = console.warn
      const logger = console.warn
      if (type == 'error') {
        title = '%c[ERROR] ' + titleStr
        titleStyle = `background: #f00`
        callFuncWithArgs(logger, title, titleStyle, value)
      } else if (type == 'warn') {
        title = '%c[WARN] ' + titleStr
        titleStyle = `background: #f90`
        callFuncWithArgs(logger, title, titleStyle, value)
      }
      if (type == 'error') {
        throw title
      }
    } else {
      const logger = console.log
      if (type == 'log' && Settings.LOG_LEVEL != 'warn') {
        title = '%c[LOG] ' + titleStr
        titleStyle = `color: #fdcb6e; background: ${color}`
        callFuncWithArgs(logger, title, titleStyle, value)
      } else if (type == 'info' && Settings.LOG_LEVEL == 'info') {
        title = '%c[INFO] ' + titleStr
        titleStyle = `color: #74b9ff; background: ${color}`
        callFuncWithArgs(logger, title, titleStyle, value)
      }
    }
  }
  if (Settings.ENABLE_SENTRY) {
    captureBreadcrumb(key, value, category, type)
  }
}


function captureBreadcrumb(key, value, category, type) {
  // const level = (type == 'warn' ? 'error' : (type == 'log' ? 'debug' : (type == 'error' ? 'fatal' : type)))

  // const message = key || type

  // if (!category) category = type
  // const data = typeof value == 'string' ? { value } : value
  // const uploadData = {
  //   level,
  //   category: category,
  //   message: message,
  //   data: data,
  // }
  // Sentry.addBreadcrumb(uploadData)

  // if ((value?.message && value?.stack) || type == 'error') {
  //   let errMessage = Tools.stringiparse(message)
  //   try {
  //     if (typeof value == 'string') {
  //       errMessage = value
  //     } else if (typeof value == 'number' || typeof value == 'undefined') {
  //       errMessage = String(value)
  //     } else if (typeof value == 'object') {
  //       errMessage = inspect(value, { showHidden: false, compact: false, depth: 2 })
  //     } else {
  //       errMessage = `Tried to log unsupported object type ${typeof value}`
  //     }
  //   } catch (err) {
  //     errMessage = err
  //   }
  //   const prefix = key || category
  //   Sentry.captureException(`${prefix} -> ${errMessage}`)
  // }
}

// console.log({ severity: Sentry.Severity })
