import { React, Button, Input, View, Validate, useState } from 'lib'
import { Profile, AppStatus } from 'actions'

const PasswordCard = () => {
  const [password, setPassword] = useState('')

  function submit() {
    AppStatus.setActivity('loading')
    Profile.verifyPassword(password)
  }

  function onEnterSubmit(event) {
    const keyCode = event.which || event.keyCode
    if (keyCode === 13 && !event.shiftKey) {
      submit()
    }
  }

  return (
    <View variant={'block'}>
      <View variant={'paddingHorizontal:1 marginTop:5'}>
        <Input
          type={'password'}
          value={password}
          validate={Validate.password}
          labelMsg={'PASSWORD'}
          onChangeText={password => setPassword(password)}
          onKeyDown={onEnterSubmit}
        />
      </View>
      <Button
        variant={'fullWidth marginTop:4'}
        text='SUBMIT' onPress={submit}
      />
    </View>
  )
}

export default PasswordCard
