import { React, View, Text, onUpdate, useState } from 'lib'
import { Theme } from 'app'
import Draggable from 'react-draggable'
import { useScrollbarWidth } from 'react-use'

const HANDLE_HEIGHT = Theme.values.draggableTab.handleHeight
const HANDLE_OVERLAP = Theme.values.draggableTab.handleOverlap
const TAB_TOP_BOUND = Theme.values.draggableTab.tabTopBound
const MAX_DRAGGABLE_SIZE = 480

function DraggableTab(props) {
  const { height } = Theme.hooks.size()
  const { children, loading, resultsCount, filter, listingScreen } = props

  const [topBound, setTopBound] = useState(110)

  onUpdate(() => {
    const topBoundResult = height - MAX_DRAGGABLE_SIZE

    setTopBound(topBoundResult)
  }, [height])

  const scrollBarWidth = useScrollbarWidth()

  const scrollBarHiddenStyles = {
    marginRight: -scrollBarWidth,
    paddingRight: scrollBarWidth,
    maxWidth: `calc(100vw + ${scrollBarWidth}px)`,
  }

  const resultString = `${loading ? 'Loading properties' : `${resultsCount || 'No'} results found `} ${filter?.search ? `for "${filter.search} "` : ''}in ${filter?.description || 'your location'}`

  return (
    <View style={[!listingScreen ? styles.wrapper : styles.wrapperListing]}>
      <Draggable
        axis='y'
        handle='#handle'
        defaultPosition={{ x: 0, y: height - HANDLE_HEIGHT - HANDLE_OVERLAP }}
        bounds={{ top: listingScreen ? topBound : TAB_TOP_BOUND, bottom: height - HANDLE_HEIGHT - HANDLE_OVERLAP }}
        // bounds={{ top: 110, bottom: height - HANDLE_HEIGHT - HANDLE_OVERLAP }}
        // grid={[TAB_TOP_BOUND, height - HANDLE_HEIGHT - HANDLE_OVERLAP]}
      >
        <View style={styles.innerWrapper}>
          <View id={'handle'} down={'mid'} style={[styles.mobileHandle, !listingScreen && styles.mobileHandleHeight]}>
            <View style={styles.mobileHandleContent}>
              <View variant={'flex fullWidth center'}>
                <View style={styles.handleBar}></View>
              </View>
              {!listingScreen && <Text variant={'p2 grey center textCenter'} text={resultString}/>}
            </View>
          </View>
          <View style={[styles.scroll, scrollBarHiddenStyles]} id={'draggableTab'}>
            <View style={[!listingScreen ? styles.content : styles.listingContent]}>
              {children}
            </View>
          </View>
        </View>
      </Draggable>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.absolute,
    pointerEvents: 'none',
    zIndex: 100,
    [Theme.media.down('mid')]: {
      ...Theme.elevated2x,
    },
  },
  wrapperListing: {
    position: 'fixed',
    top: 0,
    left: '-1px',
    pointerEvents: 'none',
    zIndex: 100,
    width: '100vw',
    maxWidth: '100vw',
  },
  innerWrapper: {
    pointerEvents: 'auto',
    borderTopRightRadius: Theme.values.borderRadius,
    borderTopLeftRadius: Theme.values.borderRadius,
    ...Theme.elevated2x,
  },
  mobileHandle: {
    backgroundColor: Theme.colors.white,
    width: '100vw',
    borderTopRightRadius: Theme.values.borderRadius,
    borderTopLeftRadius: Theme.values.borderRadius,
    justifyContent: 'center',
    // display: 'flex',
    // flexDirection: 'column',
  },
  mobileHandleHeight: {
    height: HANDLE_HEIGHT,
  },
  mobileHandleContent: {
    // background: 'red',
    padding: Theme.spacing(3),
    paddingTop: Theme.spacing(2),
    justifyContent: 'center',
    // display: 'flex',
    // flexDirection: 'column',
  },
  scroll: {
    height: `calc(100vh - ${HANDLE_HEIGHT}px - ${TAB_TOP_BOUND}px)`,
    // background: 'cyan',
    overflowY: 'scroll',
    backgroundColor: Theme.colors.white,
  },
  content: {
    padding: Theme.spacing(3),
  },
  listingContent: {
    paddingLeft: Theme.spacing(2),
    paddingRight: Theme.spacing(2),
  },
  handleBar: {
    width: 80,
    height: 5,
    borderRadius: 1000,
    backgroundColor: Theme.colors.lightGrey,
    marginBottom: Theme.spacing(2),
  },
}

export default DraggableTab
