import { React, Button, Input, Link, View, Validate, useState } from 'lib'
import { Profile, AppStatus } from 'actions'
import SocialSignin from './SocialSignin'

const LoginCard = () => {
  const [form, setForm] = React.useState({ email: '', password: '' })
  const [isShowingPassword, setIsShowingPassword] = useState(false)

  const submit = () => {
    log({ form })
    Profile.tryEmailLogin(form)
  }

  const onEnterSubmit = (event) => {
    const keyCode = event.which || event.keyCode
    if (keyCode === 13 && !event.shiftKey) {
      submit()
    }
  }

  const resetPassword = () => {
    log({ form })
    // Profile.tryEmailLogin(form)
  }

  const openSignup = () => {
    AppStatus.dismissModals()
    AppStatus.setSignupModal(true)
  }

  const hideShowPassword = () => {
    if (isShowingPassword) {
      setIsShowingPassword(false)
    } else {
      setIsShowingPassword(true)
    }
  }

  return (
    <View variant={'block'}>
      <View>
        <Input
          value={form.email}
          labelMsg={'Email'}
          variant={'pill'}
          onChangeText={email => setForm({ ...form, email })}
          onKeyDown={onEnterSubmit}
        />
      </View>
      <View variant={'marginTop:5'}>
        <Input
          type={isShowingPassword ? 'text' : 'password'}
          value={form.password}
          labelMsg={'Password'}
          variant={'pill'}
          onChangeText={password => setForm({ ...form, password })}
          onKeyDown={onEnterSubmit}
          hideShowPassword={() => hideShowPassword()}
        />
      </View>
      <View variant={`row marginBottom:4 marginTop:2 marginLeft:1`}>
        <Link variant={'textCenter underline'} msg={'Forgot password?'} onPress={resetPassword}/>
      </View>
      <Button
        disabled={!form.email || !form.password}
        text='SUBMIT'
        onPress={submit}
        variant={'fullWidth'}
      />
      <SocialSignin/>
      <View variant={'row center marginTop:4'}>
        <Link variant={'silent textCenter'} msg={'Not a user? Sign up now.'} onPress={openSignup}/>
      </View>
    </View>
  )
}

export default LoginCard
