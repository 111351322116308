import { React, View } from 'lib'
import { Theme } from 'app'

const BackgroundVideo = (props) => {
  const { source, style } = props
  return (
    <View style={[styles.wrapper, style]}>
      <video playsinline autoPlay={'autoplay'} loop={'loop'} muted style={videoStyles}>
        <source src={source} type={'video/mp4'}/>
        Your browser does not support background videos
      </video>
    </View>
  )
}

const videoStyles= {
  overflow: 'hidden',
  width: '100vw',
  height: '100vh',
  // minHeight: '110vh',
  bottom: 0,
  top: 0,
  right: 0,
  left: 0,
  position: 'absolute',
  objectFit: 'cover',
  // backgroundColor: '#ccc',
}

const styles = {
  wrapper: {
    ...Theme.center,
    height: '100vh',
    width: '100vw',
    // background: 'linear-gradient(45deg, #3841e0, #255ec1)',
    // backgroundColor: 'yellow',
    // color: 'white',
    // display: 'flex',
    overflow: 'hidden',
  },
}

export default BackgroundVideo

