// import { Theme } from '_app'

const image = {
  default: {
    // backgroundColor: '#f3f3f3',
  },
  skeleton: {
    backgroundColor: '#f3f3f3',
  },
}

export default {
  image,
}
