import { React, useRedux, Image, Button, useState, onMount, url, View, Touchable, CenterWrapper, Link, navigate, useSearchQueryParams } from 'lib'

import { Theme } from 'app'
import { Navigation } from 'navigation'
import { FaBars, FaChevronLeft } from 'react-icons/fa'
import { Avatar } from 'components'
import qs from 'query-string'

import HeaderNavBarMobile from './HeaderNavBarMobile'
import logoBryck from '../../assets/black-logo.png'
import logoBryckWhite from '../../assets/white-logo.png'
import logoLetter from '../../assets/bryck.png'
import logoLetterWhite from '../../assets/bryck-white.png'
import user from '../../assets/user.svg'

const HeaderNavBar = ({ shouldHideHeader = false, useTransparency = false, fullHeader, flat = false, removeHeader = false, showArrow = false, hideHeader = false }) => {
  const links = Navigation.getPaths()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const [transparent, setTransparent] = useState(true)
  const [mounted, setMounted] = useState(false)
  const [query, setQuery, getQueryAsString, location] = useSearchQueryParams()

  const isLoggedIn = useRedux('Session.isLoggedIn')
  const profile = useRedux('Session.profile')
  const agency = useRedux('Session.agency')

  const agencyLogo = agency?.logo
  const profileAvatar = profile?.avatar

  const agencyAndLogo = agency && agencyLogo
  const profileAndAvatar = profile & profileAvatar

  const isTransparent = transparent && useTransparency

  const handleOnScroll = () => {
    const windowScroll = window.scrollY

    const isTop = windowScroll >= 100

    Tools.debounce(() => setTransparent(!isTop), 10)
  }

  const onBack = () => {
    navigate('/agency/listings')
  }

  onMount(() => {
    setMounted(true)
    window.addEventListener('scroll', handleOnScroll)
    return () => window.removeEventListener('scroll', handleOnScroll)
  })

  const renderLinks = () => {
    return links.map(link => {
      let linkTo = link?.to
      const { pathname } = url()
      const linkId = link?.id
      const selected = pathname.includes(linkTo)
      const rent = linkTo?.includes('/search/rent')
      const buy = linkTo?.includes('/search/buy')

      if (rent || buy) {
        linkTo = linkTo + '?' + qs.stringify(query)
      }

      const handleOnPress = (e) => {
        if (link.run) {
          e.preventDefault()
          return link.run()
        }

        navigate(linkTo)
      }

      if (link.icon && isLoggedIn) {
        return (
          <Touchable
            key={linkId}
            onPress={handleOnPress}
            style={[styles.flex, selected && styles.userSelected]}
            variant={'alignCenter justifyCenter marginLeft:3'}
          >
            <Avatar variant={'smallCircle pointer'} style={styles.image} profile={{ avatar: agencyAndLogo ? agencyLogo : profileAndAvatar ? profileAvatar : user }}/>
          </Touchable>
        )
      }

      return (
        <View key={linkId} variant={'row alignCenter marginLeft:3'}>
          <Link
            to={linkTo}
            onPress={handleOnPress}
            msg={link.msg}
            style={[
              styles.appNavLink,
              isTransparent ? styles.appNavLinkWhite : styles.appNavLinkBlack,
              selected && styles.appNavLinkSelected,
            ]}
          />
        </View>
      )
    })
  }

  return (
    <>
      <View style={[styles.wrapper]} key={mounted}>
        <View style={[
          styles.appNavWrapper,
          isTransparent ? styles.appNavWrapperBgTransparent : styles.appNavWrapperBgWhite,
          hideHeader && styles.appNavWrapperRemoveed,
          flat && styles.flatWrapper,
          styles.show,
        ]}>
          <CenterWrapper contentContainerStyle={[styles.appNavCenterWrapper, fullHeader && styles.appNavCenterWrapperFull]}>
            <View up={'mid'} variant={'flex'}>
              <Touchable up={'mid'} to={'/'} style={styles.appNavLogoWrapper}>
                <Image
                  source={isTransparent ? logoBryckWhite : logoBryck}
                  alt={'BRYCK'}
                  style={styles.appNavLogo}
                />
              </Touchable>

              <View style={styles.appNavLinksWapper}>
                {renderLinks()}
              </View>
            </View>

            <View down={'mid'} variant={'flex row justifySpaceBetween alignCenter'}>
              <View variant={'flex alignCenter'}>
                {showArrow && (
                  <Button
                    style={styles.buttonBack}
                    icon={FaChevronLeft}
                    size={25}
                    onPress={onBack}
                    color={Theme.colors.primary}
                    variant={'small'}
                  />
                )}
                <Touchable onPress={() => setMobileNavOpen(true)} style={styles.appNavMobileBars}>
                  <FaBars size={25} color={isTransparent ? Theme.colors.white : Theme.colors.primary}/>
                </Touchable>
              </View>

              <Touchable to={'/'} style={styles.appNavLogoWrapper}>
                <Image source={isTransparent ? logoLetterWhite : logoLetter} style={styles.appNavLogoLetter} alt={'BRYCK'}/>
              </Touchable>
            </View>

          </CenterWrapper>
        </View>
        <View down={'mid'}>
          <HeaderNavBarMobile
            isOpen={isMobileNavOpen}
            onClose={() => setMobileNavOpen(false)}
            onOpen={() => setMobileNavOpen(true)}
          />
        </View>
      </View>
    </>
  )
}

const styles = {
  show: {
    marginTop: 0,
    opacity: 1,
    transition: 'all 0.5s ease-in-out',
  },
  wrapper: {
    flex: 1,
    flexGrow: 0,
  },
  appNavWrapper: {
    position: 'fixed',
    display: 'flex',
    height: Theme.values.headerMenuHeight,
    flex: 1,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    transition: 'all 200ms linear',
    borderBottomStyle: 'none',
    borderBottomColor: 'transparent',
    borderBottomWidth: 1,
    overflow: 'hidden',
  },
  appNavWrapperBgWhite: {
    background: Theme.colors.white,
    ...Theme.elevated2x,
  },
  appNavWrapperBgTransparent: {
    background: 'transparent',
    boxShadow: 'unset',
  },
  appNavWrapperRemoveed: {
    display: 'none',
  },
  flatWrapper: {
    boxShadow: 'unset',
    position: 'unset',
  },
  appNavCenterWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    ...Theme.paddingHorizontal(2),
  },
  appNavCenterWrapperFull: {
    maxWidth: '100% !important',
  },
  appNavLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  appNavLogo: {
    width: 120,
    marginTop: 0,
    marginLeft: 'auto',
  },
  appNavLogoLetter: {
    objectFit: 'cover',
    width: 80,
  },
  appNavLinksWapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  appNavLink: {
    textDecorationLine: 'none',
    padding: Theme.spacing(1),
    color: Theme.colors.black,
    transition: '200ms',
    fontWeight: 'normal',
    [Theme.media.is('mid')]: {
      padding: 0,
    },
  },
  appNavLinkBlack: {
    color: Theme.colors.black,
    '&:hover': {
      color: Theme.colors.orange,
      opacity: 0.8,
    },
  },
  appNavLinkWhite: {
    color: Theme.colors.white,
    '&:hover': {
      color: Theme.colors.orange,
      opacity: 1,
    },
  },
  appNavLinkSelected: {
    fontWeight: '700',
  },
  appNavLang: {
    marginLeft: Theme.spacing(3),
  },
  appNavLangFlag: {
    width: 30,
    height: 28,
  },
  flex: {
    display: 'flex',
  },
  buttonBack: {
    padding: '0 !important',
    marginRight: Theme.spacing(2),
  },
  userSelected: {
    border: `2px solid ${Theme.colors.orange}`,
    width: 30,
    height: 30,
    borderRadius: '50%',
  },
  image: {
    width: 28,
    height: 28,
    borderRadius: '50%',
  },
}

export default React.memo(HeaderNavBar)
