/* eslint prefer-const: 'off' */
import { ReduxTools } from 'lib'

export const Faq = (state = [], action) => {
  let newState = Object.assign([], state)
  switch (action.type) {
    case 'RECEIVE_FAQ':
      ReduxTools.merge(newState, action.data)
      return newState
    default:
      return state
  }
}
