/* eslint no-restricted-imports: 'off' */
import { React, Text, View, onMount } from 'lib'
import { Theme, Settings } from 'app'
import { Spinner } from 'components'

/** @jsx jsx */
import { jsx } from '@emotion/react'

/**
 * @exports <View/>
 * Default View component. Use this instead of divs.
 *
 * @prop {string} variant ---> View style variants
 * @prop {object} style ---> View styles object
 * @prop {function} onHover ---> Triggered when hovered, returns bool
 * @prop {string} is ---> Shows View only on media size
 * @prop {string} not ---> Hides View only on media size
 * @prop {string} up ---> Shows View on upwards of media size
 * @prop {string} down ---> Shows View on downwards of media size
 *
 */


function ContentView(rawProps) {
  const {
    children,
    placeholderMsg,
    loading,
    debug,
    ...props
  } = rawProps

  onMount(() => {
    // log({ props, children, placeholderMsg, loading })
  })

  function renderLoading() {
    return (
      <View variant={'flex center'}>
        <Spinner/>
      </View>
    )
  }

  function renderPlaceholder() {
    return (
      <View variant={'flex center'}>
        <Text variant={'h5 grey'} msg={placeholderMsg}></Text>
      </View>
    )
  }

  let showChildren = false
  if (Object.keys(children).length) {
    for (let index = 0; index < children.length; index++) {
      const hasChildrenToShow = children[index]
      if (hasChildrenToShow) {
        showChildren = true
      }
    }
  }

  let content = null
  let viewProps = null
  if (loading) {
    content = renderLoading()
    viewProps = { variant: 'marginVertical:8' }
  } else if (showChildren) {
    content = children
    viewProps = props
  } else {
    content = renderPlaceholder()
    viewProps = { variant: 'marginVertical:8' }
  }

  if (debug) {
    log({ props, children, placeholderMsg, loading, showChildren, content, viewProps })
  }

  return (
    <View {...viewProps}>
      {content}
    </View>
  )
}

ContentView.whyDidYouRender = Settings.WARN_WHY_DID_YOU_RENDER

export default ContentView

