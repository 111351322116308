import { React, useState, useRedux, View, Touchable, Text } from 'lib'
import { Theme } from 'app'
import { Avatar, ContactsInput } from 'components'
import useOnclickOutside from 'react-cool-onclickoutside'
import { FiUser } from 'react-icons/fi'

const AssignDropdown = ({
  dataType = 'contacts',
  member,
  contact,
  onSelectValue = () => null,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedValue, setSelectedValue] = useState(contact?.id)
  const [selectedValueMember, setSelectedValueMember] = useState(member?.id)

  const contacts = useRedux('Contacts')
  const members = useRedux('Members')

  const ref = useOnclickOutside(() => {
    setShowDropdown(false)
  })

  const handleOnSelect = (value) => {
    if (dataType === 'contacts') {
      setSelectedValue(value)
    } else {
      setSelectedValueMember(value)
    }
    onSelectValue(value)
    setShowDropdown(false)
  }

  const selectedContact = contacts.find(c => c.id === selectedValue)
  const selectedMember = members.find(m => m.member.id === selectedValueMember)

  const showGradient = !['safari', 'ios'].includes(Theme.browser)
  const avatarObject = {
    first_name: selectedMember?.member.first_name,
    avatar: selectedMember?.member.avatar,
  }
  const shouldShowAvatar = dataType === 'members'
  const label = dataType === 'contacts' ? selectedContact?.email : member?.name

  return (
    <View ref={ref} style={styles.wrapper}>
      <Touchable
        style={[styles.tag, dataType === 'contacts' && styles.memberTag]}
        onClick={() => setShowDropdown(true)}
      >
        {shouldShowAvatar && (
          <View style={styles.avatar} variant={'row alignCenter'}>
            {avatarObject.avatar || avatarObject.first_name
              ? <Avatar profile={avatarObject} variant={'verySmall'}/>
              : <FiUser size={16} color={Theme.colors.black}/>
            }
          </View>
        )}
        <Text variant={'p2 clamp black'} msg={label || 'Select'} style={styles.userName}/>
        {showGradient && <View style={[styles.gradient, dataType === 'contacts' && styles.greyGradient]}></View>}
      </Touchable>

      {showDropdown && (
        <View style={styles.dropdownWrapper}>
          <ContactsInput
            dataType={dataType}
            members={members}
            style={styles.dropdownInput}
            textStyle={styles.dropdownInputInner}
            placeholder={'Search'}
            selectedValue={selectedContact?.id}
            onSelectValue={handleOnSelect}
            suggestionsAsDropdown={false}
          />
        </View>
      )}
    </View>
  )
}

const styles = {
  wrapper: {
    position: 'relative',
    maxWidth: 140,
    marginRight: Theme.spacing(1),
  },
  userName: {
    marginTop: 1,
  },
  tag: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: Theme.borders.borderRadius / 2,
    backgroundColor: `${Theme.colors.terciary}99`,
    ...Theme.paddingVertical(1),
    ...Theme.paddingHorizontal(1.5),
    minWidth: 70,
  },
  memberTag: {
    minWidth: 47,
    backgroundColor: Theme.colors.light,
  },
  avatar: {
    marginRight: Theme.spacing(1),
  },
  dropdownWrapper: {
    position: 'absolute',
    minWidth: 314,
    top: `calc(100% + ${Theme.spacing(2)}px)`,
    right: `calc(50% - 157px)`,
    boxShadow: '0px 0px 74px rgba(0, 0, 0, 0.21)',
    borderRadius: Theme.borders.borderRadius,
    backgroundColor: Theme.colors.white,
    zIndex: 99,
    ...Theme.paddingVertical(2),

    [Theme.media.is('small')]: {
      right: 0,
    },
  },
  dropdownInput: {
    width: '90%',
    margin: '0 5%',
  },
  dropdownInputInner: {
    margin: 0,
  },
  gradient: {
    position: 'absolute',
    height: '70%',
    width: 8,
    right: Theme.spacing(1),
  },
  greyGradient: {
    background: `linear-gradient(90deg, transparent, ${Theme.colors.light})`,
  },
}

export default AssignDropdown
