import { React, View } from 'lib'
import AppStatusOverlay from './AppStatus.jsx'

function Overlays() {
  return (
    <View style={styles.overlay}>
      <AppStatusOverlay/>
    </View>
  )
}

const styles = {
  overlay: {
    zIndex: 2000000,
  },
}

export default Overlays
