import { React, View, Text, Touchable, getStyles, useState } from 'lib'
import { Theme, AssetGalleryStyles } from 'app'
import { AssetPreview, AssetLightbox } from 'components'
import { AiOutlinePlus } from 'react-icons/ai'
import { findIndex } from 'lodash'

function AddAssetPlaceholder({ onPress, styles }) {
  return (
    <Touchable variant={'growSmall'} onPress={onPress}>
      <View style={[staticStyles.addButtonWrapper, styles]}>
        <AiOutlinePlus size={60} color={Theme.colors.grey}/>
      </View>
    </Touchable>
  )
}

export function AssetGalleryItem({ item, styles, length, onOpen, index, showPreviewPDF }) {
  const [hover, onHover] = useState(false)
  const { caption } = item
  const showCaption = caption && hover

  const previewStyles = !index || index == 0
    ? staticStyles.threeItemsFirstPreview
    : (index == 2
      ? staticStyles.threeItemsSecondPreview
      : index == 4 && staticStyles.threeItemsThirdPreview
    )

  return (
    <Touchable key={index} style={staticStyles.assetWrapper} onPress={() => onOpen(item.id)} onHover={onHover}>
      <AssetPreview
        item={item}
        index={index}
        showPreviewPDF={showPreviewPDF}
      />
      <View style={[staticStyles.assetMetaWrapper, !showCaption && staticStyles.hidden]}>
        <Text variant={'p2 marginRight:1 noWrap'} style={styles.assetMetaText} text={caption}/>
        <Text variant={'p2'} style={styles.assetMetaText} text={index+1}/>
      </View>
    </Touchable>
  )
}

function AssetGallery({ data, onPress, variant, filterActive, titleMsg, showPreviewPDF }) {
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const media = Theme.hooks.media()

  if (filterActive) {
    data = data.filter(i => i.active)
  }

  const onOpen = (id) => {
    const index = findIndex(data, { id })
    setSelectedItem(index)
    setOpen(true)
  }

  const itemHeight = variant == 'small' ?
    AssetGalleryStyles.values.smallItemSize : AssetGalleryStyles.values.largeItemSize
  const firstItemHeight = AssetGalleryStyles.values.getFirstItemSize(itemHeight)
  const assetItemStyles = getStyles(variant, AssetGalleryStyles)

  const content = data?.map((i, k) => {
    return (
      <AssetGalleryItem
        item={i}
        key={k}
        index={k}
        styles={assetItemStyles}
        onOpen={onOpen}
        length={data.length}
        showPreviewPDF={showPreviewPDF}
      />
    )
  })

  if (!data.length && onPress) {
    content.push(<AddAssetPlaceholder onPress={onPress} styles={{ width: firstItemHeight, height: firstItemHeight }}/>)
  }

  const maxItems = variant == 'small' ? 5 : 3
  const firstItem = content.slice(0, 1)
  const remainingItems = content.slice(1, maxItems)

  const renderContent = () => {
    if (media === 'small') {
      if (!data.length && onPress) {
        return (<AddAssetPlaceholder onPress={onPress} styles={{ width: firstItemHeight, height: firstItemHeight }}/>)
      }

      return (
        <View style={[staticStyles.assetWrapper, staticStyles.assetWrapperMobile]}>
          <AssetPreview
            item={data[0]}
            style={staticStyles.assetPreviewMobile}
            showPreviewPDF={showPreviewPDF}
          />
        </View>
      )
    }

    return (
      <View style={staticStyles.innerWrapperSmall}>
        <View style={[staticStyles.firstItemGridWrapper, assetItemStyles.firstGrid]}>
          {firstItem}
        </View>
        {remainingItems.length > 0 &&
          <View style={[
            assetItemStyles.secondGrid,
            variant === 'small' && staticStyles.secondGridWrapperSmall,
          ]}>
            {remainingItems}
          </View>
        }
      </View>
    )
  }

  return (
    <View style={staticStyles.wrapper}>
      <AssetLightbox
        open={open}
        data={data}
        titleMsg={titleMsg}
        initialSlide={selectedItem}
        onClose={() => setOpen(false)}
      />
      {renderContent()}
    </View>
  )
}

const staticStyles = {
  wrapper: {
    width: '100%',
    maxHeight: AssetGalleryStyles.values.getFirstItemSize(AssetGalleryStyles.values.largeItemSize),
  },
  innerWrapperSmall: {
    display: 'grid',
    gridTemplateColumns: '336px auto',

    [Theme.media.down('xlarge')]: {
      gridTemplateColumns: '216px auto',
    },
  },
  firstItemGridWrapper: {
    flex: 1,
  },
  secondGridWrapperSmall: {
    flex: 1,
    display: 'grid',
    gridGap: AssetGalleryStyles.values.gridGap,
    gridTemplateRows: '100% 100%',
    gridTemplateColumns: '50% 50%',
    marginLeft: AssetGalleryStyles.values.gridGap,
  },
  addButtonWrapper: {
    flex: 1,
    display: 'flex',
    ...Theme.center,
    borderRadius: Theme.values.borderRadius,
    backgroundColor: Theme.colors.light,
  },
  assetWrapper: {
    width: '100%',
    height: '100%',
    flex: 1,
    flexGrow: 1,
    display: Theme.browser.includes('safari') ? '-webkit-box' : 'block',
    position: 'relative',
    border: '1px solid #c4c4c4',
    borderRadius: Theme.values.borderRadius,
  },
  assetWrapperMobile: {
    position: 'relative',
    width: '100%',
    height: 378,
  },
  assetMetaWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: '#ffffffaa',
    borderRadius: Theme.spacing(1),
    padding: Theme.spacing(1),
    margin: Theme.spacing(1),
    ...Theme.paddingHorizontal(1.2),
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    transition: '300ms',
    opacity: 1,
  },
  hidden: {
    opacity: 0,
    transition: '300ms',
  },
  assetMetaText: {
    color: '#333',
  },
  assetPreviewMobile: {
    borderRadius: 0,
  },
  seeAllLabel: {
    position: 'absolute',
    bottom: Theme.spacing(2),
    left: 0,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: 5,
    backgroundColor: 'white',
    ...Theme.paddingVertical(1),
    ...Theme.paddingHorizontal(2),
  },
  threeItemsFirstPreview: {
    borderRadius: 0,
    borderTopLeftRadius: Theme.values.borderRadius,
    borderBottomLeftRadius: Theme.values.borderRadius,
  },
  threeItemsSecondPreview: {
    borderRadius: 0,
    borderTopRightRadius: Theme.values.borderRadius,
  },
  threeItemsThirdPreview: {
    borderRadius: 0,
    borderBottomRightRadius: Theme.values.borderRadius,
  },
}

export default AssetGallery
