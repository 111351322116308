import { React, View, Text, Image } from 'lib'
import { Theme } from 'app'

import bedroomIcon from '../../assets/bed.png'
import bathroomIcon from '../../assets/shower.png'
import propertyIcon from '../../assets/propertyType.svg'
import areaIcon from '../../assets/area.png'

function PropertyFeatures(props) {
  const { area, bedroom, bathroom, propertyType } = props

  const getProperty = () => {
    switch (propertyType) {
      case 'HS':
        return 'House'
      case 'FT':
        return 'Flat'
      case 'BG':
        return 'Bungalow'
      case 'AP':
        return 'Apartment'
      case 'LD':
        return 'Land'
      case 'CM':
        return 'Commercial'
      case 'PK':
        return 'Parking'
      default:
        return 'New build'
    }
  }

  return (
    <View style={styles.grid}>
      {propertyType && propertyType[0] !== null &&
        <View style={styles.featureWrapper}>
          <View style={styles.iconWrapper}>
            <Image source={propertyIcon} style={styles.featureImage}/>
          </View>
          <Text variant={'marginLeft:2'} text={`${getProperty()}`}/>
        </View>
      }
      {bedroom &&
        <View style={styles.featureWrapper}>
          <View style={styles.iconWrapper}>
            <Image source={bedroomIcon} style={styles.featureImage}/>
          </View>
          <Text variant={'marginLeft:2'} text={`${bedroom} bedrooms`}/>
        </View>
      }
      {bathroom &&
        <View style={styles.featureWrapper}>
          <View style={styles.iconWrapper}>
            <Image source={bathroomIcon} style={styles.featureImage}/>
          </View>
          <Text variant={'marginLeft:2'} text={`${bathroom} bathrooms`}/>
        </View>
      }
      {area &&
        <View style={styles.featureWrapper}>
          <View style={styles.iconWrapper}>
            <Image source={areaIcon} style={styles.featureImage}/>
          </View>
          <Text variant={'marginLeft:2'} text={`${area} sq ft`}/>
        </View>
      }
    </View>
  )
}


const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridAutoFlow: 'rows',
    gridGap: Theme.spacing(3),
    marginTop: Theme.spacing(3),

    [Theme.media.is('small')]: {
      gridTemplateColumns: 'auto',
    },
  },
  featureWrapper: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'flex',
    ...Theme.center,
  },
  featureImage: {
    width: 28,
    height: 28,
    objectFit: 'contain',
  },
}

export default PropertyFeatures
