import { Theme } from 'app'
import { HorizontalScroll, Page, React, Text, Touchable, url, View, CenterWrapper } from 'lib'
import { Router } from 'navigation'
import MenuIcon from '../../components/profiles/MenuIcon'

function RouterPage(props) {
  const { children, titleMsg, basePath, showAccountMenu = true } = props
  const menuItems = children.map(c => { return { titleMsg: c.props.titleMsg, path: `${basePath}${c.props.path}` } })
  const isSmall = Theme.hooks.is('small')
  const pageProps = { ...props.pageProps, ...(isSmall ? { fullWidth: true } : {}) }

  return (
    <Page titleMsg={titleMsg} {...pageProps}>
      <View style={styles.wrapper}>
        {showAccountMenu && (
          <>
            <View style={styles.sideMenu} not={'small'}>
              <AccountDesktopMenu items={menuItems}/>
            </View>
            <View style={styles.topMenu} is={'small'}>
              <CenterWrapper contentContainerStyle={styles.centerWrapper}
              >
                <AccountMobileMenu items={menuItems}/>
              </CenterWrapper>
            </View>
          </>
        )}
        <Router
          style={styles.content}
          basePath={basePath}
        >
          {children}
        </Router>
      </View>
    </Page>
  )
}

const AccountDesktopMenu = ({ items }) => {
  return items.map((d, i) => <DesktopMenuItem key={i} data={d}/>)
}

const DesktopMenuItem = (props) => {
  const { titleMsg, path } = props.data
  const { pathname } = url()
  const selected = pathname.includes(path)

  return (
    <Touchable
      to={path}
      style={styles.menuItem}
    >
      <MenuIcon type={titleMsg} selected={selected}/>
      <Text variant={'p3'} style={[styles.menuItemText, selected && styles.menuItemTextSelected]} msg={titleMsg}/>
    </Touchable>
  )
}

const AccountMobileMenu = ({ items }) => {
  const tabs = items.map((d, i) => <MobileMenuItem key={i} data={d}/>)
  return (
    <View style={styles.mobileMenu}>
      <HorizontalScroll style={styles.horizontalScroll}>
        {tabs}
      </HorizontalScroll>
    </View>
  )
}

const MobileMenuItem = (props) => {
  const { titleMsg, path } = props.data
  const { pathname } = url()
  const selected = pathname.includes(path)
  return (
    <Touchable
      variant={'plain'}
      to={path}
      style={[styles.menuItemMobile, selected && styles.menuItemSelected]}
    >

      <Text variant={'p3'} style={[styles.menuItemMobileText, selected && styles.menuItemTextSelected]} msg={titleMsg}/>
      <MenuIcon type={titleMsg} selected={selected}/>

    </Touchable>
  )
}

const smallMenuWidth = 90

const styles = {
  wrapper: {
    ...Theme.row,
    marginTop: Theme.spacing(4),
    alignItems: 'flex-start',

    [Theme.media.is('small')]: {
      marginTop: 0,
      flex: 1,
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  },
  mobileMenu: {
    backgroundColor: Theme.colors.white,
    height: 80,
  },
  content: {
    flex: 1,
    width: '100%',
    marginBottom: Theme.spacing(4),
    boxSizing: 'border-box',

    [Theme.media.down('mid')]: {
      flex: 1,
      marginLeft: 0,
      marginBottom: 0,
      padding: Theme.spacing(2),
    },
  },
  sideMenu: {
    borderRadius: Theme.values.borderRadius,
    ...Theme.elevated2x,
    marginRight: Theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: Theme.spacing(11),
    flexShrink: 0,
    flexGrow: 0,
    [Theme.media.down('xlarge')]: {
      marginRight: Theme.spacing(4),
    },
    [Theme.media.down('large')]: {
      marginRight: Theme.spacing(3),
      width: smallMenuWidth,
    },
  },
  topMenu: {
    width: '100%',
  },
  horizontalScroll: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    alignItems: 'center',
    padding: Theme.spacing(2.5),
    flexDirection: 'column',
    borderRadius: Theme.values.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    ...Theme.debug('red'),
    borderColor: 'transparent',
    width: Theme.spacing(5),
    height: Theme.spacing(5),
    margin: 2,
    '&:hover': {
      background: Theme.colors.backgroundOrange,
    },
  },
  menuItemText: {
    fontWeight: '500',
    color: Theme.colors.primary,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  menuItemTextSelected: {
    fontWeight: 'bold',
    color: Theme.colors.orange,
  },
  menuItemMobile: {
    display: 'flex',
    ...Theme.center,
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
  },
  menuItemMobileText: {
    color: Theme.colors.black,
    padding: Theme.spacing(1),
    textTransform: 'uppercase',
  },
}

export default RouterPage
