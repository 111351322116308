/* eslint no-restricted-imports: 'off' */
import { Text, View, Button, useRef, CenterWrapper } from 'lib'
import { MdClose } from 'react-icons/md'
import { Theme } from 'app'
import Modal from '@material-ui/core/Modal'

/** @jsx jsx */
import { jsx } from '@emotion/react'

export default function Overlay(rawProps) {
  const { open, onClose, titleMsg, style, contentContainerStyle, children } = rawProps
  const ref = useRef(null)

  const close = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={close}>
      <View
        style={[styles.wrapper, style]}
        onClick={e => e.stopPropagation()}
        ignoreWarnings={true}
      >
        <CenterWrapper contentContainerStyle={[styles.centerWrapper, contentContainerStyle]}>
          <View style={styles.innerWrapper}>
            {titleMsg &&
              <View style={styles.overlayHeader} variant={'row justifySpaceBetween alignCenter'}>
                <Text variant={'h2 bold'} msg={titleMsg}></Text>
                <Button icon={MdClose} onPress={close} color={Theme.colors.grey}/>
              </View>
            }
            <View style={styles.content} ref={ref}>
              {children}
            </View>
          </View>
        </CenterWrapper>
      </View>
    </Modal>
  )
}

const styles = {
  wrapper: {
    position: 'absolute',
    background: Theme.colors.white,

    ...Theme.whole,
    justifyContent: 'center',
    zIndex: 999,
    overflow: 'scroll',
    transition: '10ms',
  },
  centerWrapper: {
    flex: 'auto',
    boxSizing: 'border-box',
    ...Theme.marginHorizontal(0),
    ...Theme.paddingHorizontal(2),

    [Theme.media.is('small')]: {
      ...Theme.paddingHorizontal(0),
    },
  },
  innerWrapper: {},
  content: {
    ...Theme.marginVertical(6),
    [Theme.media.is('small')]: {
      ...Theme.marginVertical(4),
    },
  },
  overlayHeader: {},
}
