import { toast } from 'react-toastify'


function info(message) {
  log({ message })
  toast.dark(message)
}

function success(message) {
  log({ message })
  toast.success(message)
}

function error(message) {
  warn({ message })
  toast.error(message)
}

export default {
  info,
  success,
  error,
}
