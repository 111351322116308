import { React, Text } from 'lib'

const ListingPrice = (props) => {
  const { listing, variant, style, abbreviation, displayOriginal } = props

  const currency = listing?.display_currency
  const originalCurrency = listing?.currency

  const price = listing?.display_price
  const originalPrice = listing?.price


  const formatPrice = (currencyProp, priceProp) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currencyProp ? currencyProp : 'AED',
      maximumFractionDigits: 0,
    }).format(priceProp)
  }

  const priceToShow = formatPrice(currency, price)
  const originalPriceToShow = formatPrice(originalCurrency, originalPrice)

  return (
    <Text
      text={`${displayOriginal ? originalPriceToShow : priceToShow}${listing.to_rent ? `${abbreviation ? '/mo' : '/month'}` : ''}`}
      variant={variant}
      style={[displayOriginal && styles.originalPrice, style]}
    />
  )
}

const styles = {
  originalPrice: {

  },
}

export default ListingPrice
