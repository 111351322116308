import { AvatarStyles, Theme } from 'app'
import { FileInput, Image, React, Text, Touchable, useRef, useState, View } from 'lib'
import { Avatar } from 'components'

export default function ProfileImageEditor(props) {
  const {
    onChange,
    showCover,
    showAvatar,
    avatarObject,
    image,
    personal,
    agency,
    stylesImageWrapper,
    stylesImage,
    imageChange,
  } = props
  const input = useRef(null)
  const [editImage, setEditImage] = useState(false)

  const isSmall = Theme.hooks.media() === 'small'

  const onPress = () => {
    input.current.click()
  }

  const onFileChange = (data) => {
    onChange(data)
  }

  const onHoverImage = () => {
    setEditImage(!editImage)
  }

  const renderImage = () => {
    if (image) {
      return (
        <View style={[styles.imageWrapper, !isSmall && styles.imageBorderRadius, stylesImageWrapper]}>
          <Image variant={'skeleton'} style={[styles.image, stylesImage, !isSmall && styles.imageBorderRadius]} source={image}/>
        </View>
      )
    }
    return (
      <View style={styles.addImage}>
        <Text msg={'+'} style={styles.add}/>
      </View>
    )
  }

  return (
    <View style={[personal ? styles.personalBox : styles.box, isSmall && showCover && styles.boxFull]}>
      {showCover && (
        <Touchable style={styles.container} onPress={onChange && onPress} onHover={onChange && onHoverImage}>
          {renderImage()}
          {(editImage && image) &&
          <>
            <View style={styles.editImage}>
              <Text msg={'EDIT COVER PHOTO'} style={styles.editImageText} variant={'veryBold underline'}/>
            </View>
            <View style={styles.editing}/>
          </>
          }
          <View style={AvatarStyles.general.fileInput}>
            <FileInput
              style={AvatarStyles.general.fileInput}
              onChange={onFileChange}
              inputRef={input}
            />
          </View>
        </Touchable>
      )}

      {showAvatar &&
        <View style={[!personal ? styles.avatar : styles.personalAvatar, agency && styles.agencyAvatar]}>
          <Avatar profile={avatarObject} onChange={imageChange}/>
        </View>
      }
    </View>
  )
}

const backgroundHeight = 230

const styles = {
  box: {
    position: 'relative',
  },
  boxFull: {
    width: '105vw',
    marginLeft: '-16px',
  },
  container: {
    overflow: 'visible',
    objectFit: 'cover',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
  },
  editing: {
    backgroundColor: '#000',
    width: '100%',
    height: backgroundHeight,
    borderRadius: Theme.values.borderRadius,
    position: 'absolute',
    zIndex: 9,
    opacity: 0.3,
  },
  imageWrapper: {
    height: backgroundHeight,
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imageBorderRadius: {
    borderRadius: Theme.values.borderRadius,
  },
  editImage: {
    position: 'absolute',
    zIndex: 99,
    borderWidth: 4,
  },
  editImageText: {
    color: 'white',
  },
  addImage: {
    height: backgroundHeight,
    width: '100%',
    borderRadius: Theme.values.borderRadius,
    background: Theme.colors.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  add: {
    color: Theme.colors.lightGrey,
  },
  agencyAvatar: {
    right: '10%',
  },
  avatar: {
    position: 'absolute',
    right: 53,
    bottom: -80,
    zIndex: 99,
    borderWidth: 1,
    [Theme.media.is('small')]: {
      alignSelf: 'center',
      left: 30,
      bottom: -40,
    },
  },
  personalBox: {
    [Theme.media.is('small')]: {
      marginTop: Theme.spacing(2),
    },
  },
}
