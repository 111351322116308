import { Theme } from 'app'
import { Input, React, View } from 'lib'

const RoomsFeatures = ({
  placeholder,
  value,
  onChangeText,
  disabled,
  showCheckbox,
}) => {
  const isSmall = Theme.hooks.media() === 'small'

  return (
    <View variant={'row alignCenter'} style={styles.wrapperStyleInput}>
      <Input
        disabled={disabled}
        variant={'pill'}
        placeholder={placeholder}
        style={styles.input}
        value={value}
        onChangeText={e => onChangeText(e)}
        type={'number'}
      />
    </View>
  )
}

const styles = {
  wrapperStyleInput: {
    width: '20%',
    // [Theme.media.is('small')]: {
    //   width: '100%',
    // },
  },
  input: {
    flex: 0,
    textAlign: 'center',
    width: '100%',
  },
}

export default React.memo(RoomsFeatures)
