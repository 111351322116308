import moment from 'moment'
import { Settings } from 'app'
import { connect } from 'react-redux'

function addOrUpdate(state, newObject) {
  newObject.receivedAt = moment()
  let updated = false
  for (const s in state) {
    if (state[s].id == newObject.id) {
      const receivedIndex = state[s].receivedIndex
      newObject.receivedIndex = receivedIndex
      state[s] = newObject
      updated = true
    }
  }
  if (!updated) {
    newObject.receivedIndex = state.length
    state.push(newObject)
  }
  return state
}

function merge(state, arr) {
  if (Array.isArray(arr)) {
    if (arr.length) {
      for (const a in arr) {
        const obj = arr[a]
        if (obj) {
          addOrUpdate(state, arr[a])
        }
      }
      if (state[0] && state[0]?.hasOwnProperty('receivedIndex')) {
        state.sort((a, b) => (b.receivedIndex - a.receivedIndex ? 1 : -1))
      } else if (state[0].id) {
        state.sort((a, b) => (b.id - a.id ? 1 : -1))
      }
    }
  } else {
    const obj = arr
    if (obj) {
      addOrUpdate(state, obj)
    }
  }
  return state
}

function remove(state, id) {
  const newState = []
  for (const s in state) {
    if (state[s].id != id) {
      newState.push(state[s])
    }
  }
  return newState
}

function isExpired(obj) {
  const age = moment().diff(obj.receivedAt, 'seconds')
  if (age > Settings.MAX_OBJECT_AGE) {
    return true
  } else {
    return false
  }
}

export function withRedux(use, component) {
  let mapStateToProps = use
  if (typeof use != 'function') {
    mapStateToProps = (state) => {
      const result = {}
      for (const k in use) {
        const key = use[k]
        result[key] = state[key]
      }
      return result
    }
  }
  return connect(mapStateToProps)(component)
}

export default {
  addOrUpdate,
  merge,
  remove,
  isExpired,
  withRedux,
}
