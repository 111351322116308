/* eslint no-restricted-imports: 'off' */
import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { Settings } from 'app'
import { shallowEqual, useSelector } from 'react-redux'
// import { getGeocode } from 'use-places-autocomplete'
import { omitBy, isArray, includes } from 'lodash'
import { useLocation } from 'react-use'
import qs from 'query-string'

const { DEFAULT_LOCATION } = Settings

/**
 * useRedux()
 * Hook to listen to store changes
 *
 * @prop {string} objectRef ---> Eg. Session.profileId -> Perform automatic queries based on obj string
 * @prop {function} query ---> Use a custom query function to listen to store changes
 */

export const useRedux = (objectRef, query = null) => {
  const arr = objectRef.split('.')
  let storeData = []
  switch (arr.length) {
    case 0:
      warn('No params sent to useRedux')
      return null
    case 1:
      storeData = useSelector(s => s[arr[0]], shallowEqual)
      break
    case 2:
      storeData = useSelector(s => s[arr[0]][arr[1]], shallowEqual)
      break
    case 3:
      storeData = useSelector(s => s[arr[0]][arr[1]][arr[2]], shallowEqual)
      break
    case 4:
      storeData = useSelector(s => s[arr[0]][arr[1]][arr[2]][arr[4]], shallowEqual)
      break
    case 5:
      storeData = useSelector(s => s[arr[0]][arr[1]][arr[2]][arr[4]][arr[5]], shallowEqual)
      break
    default:
      warn('Too many nested objects in useRedux')
  }
  if (typeof query == 'function') {
    return storeData.filter(query)
  } else {
    return extractObjectById(storeData, query)
  }
}

const extractObjectById = (arr, id) => {
  if (arr && id) {
    const objs = arr.filter(i => i.id == id)
    if (!objs.length) {
      return []
    } else if (objs.length == 1) {
      return objs[0]
    } else {
      warn('Multiple objects found in useRedux! Duplicate data may exist.')
      return objs[0]
    }
  } else {
    return arr
  }
}

export const onMount = (func) => {
  useEffect(() => {
    return func()
  }, [])
}

export const onUpdate = (func, listeners = null) => {
  useEffect(() => {
    return func()
  }, listeners)
}

export const useCurrentLocation = () => {
  const [location, setLocation] = useState(null)

  const getCurrentPosition = () => {
    try {
      const onGetPositionSuccess = async ({ coords }) => {
        const lat = coords.latitude
        const lng = coords.longitude
        // const result = await getGeocode({ location: { lat, lng } })
        Tools.getGeocodeName({ location: { lat, lng } }, description => {
          setLocation({ lat, lng, description })
        })
      }

      const onGetPositionError = () => {
        setLocation(DEFAULT_LOCATION)
      }

      navigator.geolocation.getCurrentPosition(onGetPositionSuccess, onGetPositionError)
    } catch (err) {
      console.log('GET CURRENT LOCATION ERROR', err)
    }
  }

  useEffect(() => {
    getCurrentPosition()
  }, [])

  return [location]
}

export const useGoogleMaps = () => {
  return typeof window !== `undefined` ? window.google.maps : {}
}

// Debounce Hook
// https://usehooks.com/useDebounce/

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export const useSearchQueryParams = () => {
  const location = useLocation()

  const [tempQuery, setTempQuery] = useState(qs.parse(location?.search))

  onUpdate(() => {
    setTempQuery(qs.parse(location?.search))
  }, [location])

  function setQuery(args) {
    if (args) {
      window.history.pushState(null, document.title, `?${args}`)
    }
  }

  function getQueryAsString(args = location?.search) {
    return qs.stringify(args)
  }

  return [tempQuery, setQuery, getQueryAsString, location]
}
