/* eslint no-restricted-imports: 'off' */
import { Fetch, store } from 'actions'

const MODULE_NAME = 'crm/'

//
// Actions to dispatch
//
function receiveFaq(data) {
  store.dispatch({ type: 'RECEIVE_FAQ', data })
}

async function fetch(onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + 'faq',
    method: 'GET',
    options: {
      noToken: true,
    },
    successCallback: (res) => {
      receiveFaq(res.results)
      onSuccess(res)
    },
  })
}


export default {
  fetch,
}
