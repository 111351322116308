/* eslint no-console: 'off' */
import { motion } from 'framer-motion'

/** @jsx jsx */
import { jsx } from '@emotion/react'

/**
 * @exports <Animated/>
 * Wrapper for framer-motion library. Use this to add grow or other animations.
 *
 * @prop {string} animate ---> Selected  animation value
 * @prop {object} variants ---> Animation style variants to be selected
 *
 */


export default function Animated(rawProps) {
  const { children, style, ...props } = rawProps
  const AnimatedComponent = motion.div

  return (
    <AnimatedComponent
      transition={transition}
      css={style}
      {...props}
    >
      {children}
    </AnimatedComponent>
  )
}


const transition = {
  duration: 0.25,
}
