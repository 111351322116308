import { React, View, Text, Image, useRedux } from 'lib'
import { Theme } from 'app'
import { Avatar } from 'components'
import emailIcon from '../../assets/email.svg'
import phoneIcon from '../../assets/phone.svg'

const renderContactData = (icon = null, value = '') => {
  return (
    <View variant={'row alignCenter marginTop:2'}>
      <Image style={styles.agencyInfoIcon} source={icon}/>
      <Text variant={'marginLeft:1 clamp'} text={value || ''} style={styles.contactText}/>
    </View>
  )
}

const TeamMember = ({ member, agency }) => {
  const email = member.member?.email || agency?.email
  const phone = member.member?.contact_number || agency?.phone
  return (
    <View style={styles.wrapper}>
      <View variant={'padding:3'}>
        <View variant={'row'}>
          <View style={styles.avatar}>
            <Avatar
              profile={{ avatar: member.member.avatar, first_name: member.member.first_name }}
              variant={'medium'}
              styleAvatar={styles.image}
            />
          </View>
          <View variant={'column justifyCenter'}>
            <Text variant={'uppercase marginBottom:1 cardAddress'} style={styles.agent}>Agent</Text>
            <Text variant={'h3'}>{member.member.first_name}  {member.member.last_name}</Text>
          </View>
        </View>
        <View variant={'row flex justifySpaceBetween'}>
          <View variant={'column'}>
            {renderContactData(emailIcon, email)}
            {renderContactData(phoneIcon, phone)}
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    border: 'solid 1px #E1E1E1',
    borderRadius: Theme.values.borderRadius,
  },
  agent: {
    letterSpacing: '0.1ch',
  },
  avatar: {
    borderRadius: Theme.values.borderRadius,
    width: '30%',
    height: 90,
    objectFit: 'cover',
    flexGrow: 0,
    display: 'flex',
    marginRight: Theme.spacing(2),
  },
  image: {
    width: '100%',
    height: '100%',

    [Theme.media.is('small')]: {
      width: '100%',
      height: '100%',
    },
  },
  agencyInfoIcon: {
    width: Theme.spacing(2),
    height: Theme.spacing(2),
    margin: '0px 8px',
  },
  contactText: {
    wordBreak: 'break-all',
  },
}

export default TeamMember
