import { React, View, Text, Input, Checkbox, RadioButton, Link, Select, useState, Image, onMount } from 'lib'
import { Theme } from 'app'

import { Tag, PropertyType, Currency } from 'actions'
import { TitledSection, RoomsFeatures, AssetEditor, EditorLocation, EditorStickyBanner, ToggleSelection } from 'components'
import faker from 'faker'
import EditorPrice from '../editor/EditorPrice'
import { uniq } from 'lodash'
import bedroomIcon from '../../assets/bed.png'
import bathroomIcon from '../../assets/shower.png'
import livingroomIcon from '../../assets/living.png'

const fake1 = faker.lorem.sentence()
const fake2 = faker.lorem.paragraphs(1)
const tc = faker.lorem.paragraphs(4)

const statusList = [
  { value: 'IN', label: 'Inactive' },
  { value: 'AP', label: 'Published' },
]

function ListingEditor(props) {
  const {
    data,
    setData,
    media,
    setMedia,
    floorplans,
    setFloorplans,
    brochures,
    setBrochures,
    showPreviewPDF,
  } = props
  const isSmall = Theme.hooks.media() === 'small'

  const [rightSideHeight, setRightSideHeight] = useState(null)
  const [visibility, setVisibility] = useState([])
  const [propertyTagOptions, setPropertyTagOptions] = useState([])
  const [propertyTypes, setPropertyTypes] = useState([])
  const [currencies, setCurrencies] = useState([])

  const statusValue = statusList.find(o => o.value === data?.status)
  const currencyOptions = currencies.map((currency) => { return { value: currency.name, label: `${currency.name} - ${currency.description}` } })
  const currencyValue = currencyOptions.find(o => o.value === data?.currency)

  const categories = Tools.unique(propertyTagOptions, 'category')

  onMount(() => {
    const onSuccessPropType = (res) => {
      setPropertyTypes(res)
    }

    const onSuccessTag = (res) => {
      setPropertyTagOptions(res)
    }

    Tag.fetch('*', (res) => onSuccessTag(res))
    PropertyType.fetch('', (res) => onSuccessPropType(res))
    Currency.fetch(setCurrencies)
  })

  const handleOpenTerms = () => {
    window.open('/info/terms', '_blank')
  }

  const selectStatus = status => {
    setData(prevData => { return { ...prevData, status: status.value } })
  }

  const onHeaderLayout = (size) => {
    setRightSideHeight(size)
  }

  const onChangeVisibility = ({ index, ...rest }) => {
    setVisibility(prev => { return { ...prev, [index]: { index, ...rest } } })
  }

  const handleToggleHideRoomFeature = (val) => {
    if (data[val] === null) {
      setData({ ...data, [val]: 0 })
    } else {
      setData({ ...data, [val]: null })
    }
  }

  const handleToggleSelections = (feature) => {
    setData(prevData => { return { ...prevData, [feature]: !data[feature] } })
  }

  const handlePropertyType = (item) => {
    setData(prevData => { return { ...prevData, property_type: item.id } })
  }

  const rightSideStyles = {
    maxHeight: `calc(100% - ${rightSideHeight}px - ${Theme.spacing(8)}px)`,
  }

  const renderFeature = (feature) => {
    const isSelected = data?.features.includes(feature.id)

    const onPress = () => {
      let newFeatures = uniq([...data?.features, feature.id])
      if (data?.features.includes(feature.id)) {
        newFeatures = data?.features.filter(currentValue => currentValue !== feature.id)
      }
      setData(prevData => { return { ...prevData, features: newFeatures } })
    }
    return (
      <View key={feature.value} variant={'row alignCenter'}>
        <Checkbox
          onPress={onPress}
          value={isSelected}
        />
        <View variant={'marginLeft:2'} style={styles.iconWrapper}>
          <Image source={feature.icon} style={styles.icon}/>
        </View>
        <Text variant={'marginLeft:2'} text={feature.name}/>
      </View>
    )
  }

  const renderTitledSection = (title, children, description) => {
    const formatedText = title.replace(/ /g, '')
    return (
      <TitledSection
        titleMsg={title}
        descriptionMsg={`${description ? `listing${formatedText}Description` : ''}`}
        stickyBoxTitleMsg={`listing${formatedText}StickyBoxTitle`}
        stickyBoxBodyMsg={`listing${formatedText}StickyBoxBody`}
        onChangeVisibility={onChangeVisibility}
        index={idx++}
      >
        {children}
      </TitledSection>
    )
  }

  const renderCategory = (category) => {
    const items = propertyTagOptions.filter(i => i.category == category)
    return (
      renderTitledSection(category,
        <View style={styles.grid} variant={'marginTop:2'}>
          {items.map(renderFeature)}
        </View>,
      )
    )
  }

  const renderInput = (title, dataName, placeholder, multiline) => {
    return (
      renderTitledSection(title,
        <Input
          value={data[dataName]}
          onChangeText={(val) => setData(prevData => { return { ...prevData, [dataName]: val } })}
          multiline={multiline}
          placeholder={placeholder}
          variant={'pill'}
        />,
      )
    )
  }

  const renderToggleSelection = (val, left, right) => {
    return (
      <ToggleSelection
        isEditor
        onPress={() => handleToggleSelections(val)}
        textLeft={left}
        textRight={right}
        isLeftSelected={data[val]}
      />
    )
  }

  const renderAssetEditor = (title, dataAsset, setDataAsset, type, showPdf, variant) => {
    return (
      renderTitledSection(title,
        <AssetEditor
          titleMsg={title}
          data={dataAsset}
          setData={setDataAsset}
          type={type}
          showPreviewPDF={showPdf}
          variant={variant}
        />, true)
    )
  }

  const renderRoomFeatured = (icon, title, dataProp, dataName, showCheckbox, onSelectInfo, valueSelect) => {
    return (
      <View style={styles.roomsFeaturesContainer}>
        {!isSmall && (
          <RoomsFeatures
            value={dataProp || ''}
            onChangeText={(value) => setData({ ...data, [dataName]: Number(value) })}
            disabled={data[dataName] === null}
          />
        )}
        {isSmall && (
          <View variant={'flex'}>
            <RoomsFeatures
              value={dataProp || ''}
              onChangeText={(value) => setData({ ...data, [dataName]: Number(value) })}
              disabled={data[dataName] === null}
            />
            {showCheckbox && isSmall && (
              <View variant={`marginLeft:2 flex alignCenter`}>
                <Checkbox
                  onPress={onSelectInfo}
                  value={valueSelect}
                />
                <Text variant={'p2 lightGrey marginLeft:1'} msg={`Hide`}/>
              </View>
            )}
          </View>
        )}
        <View variant={`row alignCenter ${!isSmall && 'marginLeft:2'} ${isSmall && 'marginBottom:2'}`}>
          <Image source={icon} style={styles.featureImage}/>
          <Text variant={'p2 marginLeft:1'}>{title}</Text>
        </View>
        {showCheckbox && !isSmall && (
          <View variant={`marginLeft:2 row alignCenter`}>
            <Checkbox
              onPress={onSelectInfo}
              value={valueSelect}
            />
            <Text variant={'p2 lightGrey marginLeft:1'} msg={`Hide`}/>
          </View>
        )}
      </View>
    )
  }

  let idx = 0

  return (
    <View style={styles.wrapper} variant={'paddingTop:6'}>
      <View style={styles.contentGrid}>
        <View>
          {renderTitledSection('Status',
            <Select
              style={styles.select}
              options={statusList}
              onChange={value => selectStatus(value)}
              value={statusValue}
              isSearchable={false}
            />, true)}
          {renderInput('Title', 'title', fake1, false)}
          {renderInput('Description', 'description', fake2, true)}
          {renderInput('WhatsApp Number', 'whatsapp_number', '1199999999999', false)}
          {renderAssetEditor('Select photos', media, setMedia, 'media', 'small')}
          {renderTitledSection('Price',
            <View>
              {renderToggleSelection('to_rent', 'RENT', 'BUY')}
              <Select
                style={styles.select}
                options={currencyOptions}
                value={currencyValue}
                onChange={(type) => setData(prevData => { return { ...prevData, currency: type.value } })}
                isSearchable={true}
              />

              <EditorPrice
                hasDevelopment={false}
                currency={currencyValue?.label}
                defaultValue={parseInt(data.price) || ''}
                onChange={value => setData({ ...data, price: Number(value) })}
              />
            </View>,
          )}
          {renderTitledSection('Build type',
            <View variant={'marginTop:2'}>
              {renderToggleSelection('off_plan', 'OFFPLAN', 'RESALE')}
            </View>)}
          {renderTitledSection('Floor area',
            <View variant={'marginTop:2 row'}>
              <Input
                disabled={data.floor_area === null}
                variant={'pill'}
                wrapperStyle={styles.floorAreaInputWrapper}
                style={styles.input}
                value={parseInt(data.floor_area) || ''}
                onChangeText={(value) => setData({ ...data, floor_area: Number(value) })}
                type={'number'}
              />
              <View variant={`marginLeft:2 row alignCenter`}>
                <Checkbox
                  onPress={() => { handleToggleHideRoomFeature('floor_area') }}
                  value={data.floor_area === null}
                />
                <Text variant={'p2 lightGrey marginLeft:1'} msg={`Hide`}/>
              </View>
            </View>)}
          {renderTitledSection('Rooms',
            <View style={isSmall && styles.grid} variant={`column`}>
              {renderRoomFeatured(bedroomIcon, 'Bedrooms', data.bedrooms, 'bedrooms')}
              {renderRoomFeatured(bathroomIcon, 'Bathrooms', data.bathrooms, 'bathrooms')}
              {renderRoomFeatured(livingroomIcon, 'Living rooms', data.living_rooms, 'living_rooms', true, () => { handleToggleHideRoomFeature('living_rooms') }, data.living_rooms === null)}
            </View>)}
          {renderTitledSection('Property type',
            <View style={styles.grid} variant={'marginTop:2'}>
              {propertyTypes?.map((item) => {
                return (
                  <View variant={'row alignCenter'} key={item.id}>
                    <RadioButton
                      checked='checked'
                      onPress={() => handlePropertyType(item)}
                      value={item.id === data.property_type}
                    />
                    <Text variant={'marginLeft:2'} text={item?.description}/>
                  </View>
                )
              })}
            </View>)}
          {categories.map(renderCategory)}
          {renderAssetEditor('Select floorplans', floorplans, setFloorplans, 'floorplan', showPreviewPDF)}
          {renderAssetEditor('Select brochures', brochures, setBrochures, 'brochure', showPreviewPDF)}
          {renderTitledSection('Location',
            <EditorLocation
              data={data}
              setData={setData}
              isListingEditor
              defaultValue={data?.location?.address}
            />)}
          {renderInput('Calendar Link', 'calendly_link', 'Enter Link', false)}
          {renderTitledSection('Terms and Conditions',
            <>
              <Text variant={'marginBottom:2'} text={tc}/>
              <Text variant={''} text={fake2}/>
              <View variant={`marginVertical:2 row alignCenter`}>
                <Checkbox
                  onPress={() => setData(prevData => { return { ...prevData, tc_agreed: !data.tc_agreed } })}
                  value={data.tc_agreed}
                />
                <Link
                  variant={'silent marginLeft:1'}
                  msg={'I agree...'}
                  onPress={handleOpenTerms}
                />
              </View>
            </>, true)}
        </View>
        <View not={'small'} style={rightSideStyles} id={'stickyContainer'}>
          <EditorStickyBanner data={data} visibility={visibility} onLayout={onHeaderLayout}/>
        </View>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
  },
  select: {
    maxWidth: '60%',
    [Theme.media.is('small')]: {
      maxWidth: '100%',
      marginTop: Theme.spacing(2),
    },
  },
  featureImage: {
    width: Theme.spacing(3),
    height: Theme.spacing(3),
    objectFit: 'contain',
  },
  roomContainer: {
    width: '30%',
    // marginRight: Theme.spacing(2),
    display: 'flex',
  },
  input: {
    flex: 0,
    textAlign: 'center',
    width: '100%',
  },
  floorAreaInputWrapper: {
    width: '30%',
  },
  contentGrid: {
    display: 'grid',
    gridTemplateColumns: `60% calc(40% - ${Theme.spacing(12)}px)`,
    gridColumnGap: Theme.spacing(12),
    [Theme.media.is('small')]: {
      display: 'block',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: Theme.spacing(3),

    [Theme.media.is('small')]: {
      gridTemplateColumns: '1fr',
    },
  },
  iconWrapper: {
    display: 'flex',
    ...Theme.center,
  },
  icon: {
    width: Theme.spacing(3),
    height: Theme.spacing(3),
    objectFit: 'contain',
  },
  roomsFeaturesContainer: {
    display: 'flex',
    marginBottom: Theme.spacing(2),
    flexDirection: 'row',
    [Theme.media.is('small')]: {
      flexDirection: 'column-reverse',
    },
    featuredRoomsGap: {
      gap: Theme.spacing(3),
    },
  },
}

export default ListingEditor
