import { React, url, View, Image, Touchable, Link } from 'lib'
import { Theme } from 'app'
import { Navigation } from 'navigation'
import { MdClose } from 'react-icons/md'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import LogoLetter from '../../assets/bryck.png'

const HeaderNavBarMobile = ({ isOpen = false, removeHeader = false, onOpen = () => null, onClose = () => null }) => {
  const links = Navigation.getPaths()

  const renderLinks = () => {
    return links.filter(link => link.id !== 'login').map(link => {
      const { pathname } = url()
      const selected = pathname.includes(link.to)

      const handleCustomClick = () => {
        onClose()
        link.run()
      }

      return (
        <Link
          key={link.id}
          to={link.to}
          onPress={() => (link.run ? handleCustomClick() : null)}
          msg={link.msg.toLowerCase()}
          style={[
            styles.appNavLink,
            selected && styles.appNavLinkSelected,
          ]}
        />
      )
    })
  }

  return (
    <SwipeableDrawer
      anchor='left'
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      style={styles.wrapper}
    >
      <View style={styles.contentWrapper}>
        <View variant={'row justifySpaceBetween'}>
          <View variant={'paddingVertical:3 paddingHorizontal:2'}>
            <Touchable variant={'marginBottom:3 paddingHorizontal:2'} to={'/'}>
              <Image
                source={LogoLetter}
                style={styles.appNavLogoLetter}
                alt={'BRYCK'}
              />
            </Touchable>
            <View style={styles.appNavLinksWapper}>
              {renderLinks()}
            </View>
          </View>
          <Touchable onClick={onClose} variant={'padding:2'}>
            <MdClose size={36} color={Theme.colors.primary}/>
          </Touchable>
        </View>

        <View variant={'row paddingHorizontal:4 flexEnd'}>
          <View variant={'flex'}>
            {links.filter(link => link.id === 'login').map(link => {
              const handleOnPress = () => {
                onClose()
                link.run()
              }
              return (
                <Link
                  key={link.id}
                  to={link.to}
                  onPress={handleOnPress}
                  msg={link.msg.toLowerCase()}
                  style={styles.appNavLink}
                />
              )
            })}
          </View>
        </View>
      </View>
    </SwipeableDrawer>
  )
}

const styles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    zIndex: 9999999999999,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
  },
  appNavLogoLetter: {
    objectFit: 'cover',
    width: 100,
  },
  appNavLinksWapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: Theme.spacing(2),
    paddingRight: Theme.spacing(2),
  },
  appNavLink: {
    textTransform: 'capitalize',
    textDecorationLine: 'none',
    marginBottom: Theme.spacing(3),
    color: Theme.colors.black,
    transition: '200ms',
  },
  appNavLinkSelected: {
    fontWeight: 'bold',
    color: Theme.colors.primary,
  },
  appNavLang: {},
  appNavLangFlag: {
    width: 30,
    height: 28,
  },
}

export default React.memo(HeaderNavBarMobile)
