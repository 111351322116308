import { React, View, Text, Image } from 'lib'
import { Theme } from 'app'

const UnderConstructionPlaceholder = (props) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.innerWrapper}>
        <Text variant={`h2 marginVertical:8 alignCenter`} style={styles.textCenter} text={`This page is under construction`}/>
        <Image style={styles.image} source={'https://source.unsplash.com/ymf4_9Y9S_A/1600x900'}/>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
    minHeight: '80vh',
  },
  textCenter: {
    textAlign: 'center',
  },
  innerWrapper: {
    ...Theme.center,
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '50%',
    ...Theme.borders,
  },
}

export default UnderConstructionPlaceholder
