/* eslint no-restricted-imports: 'off' */
import { ImageStyles } from 'app'
import { graphql, StaticQuery } from 'gatsby'
import { default as GatsbyImage } from 'gatsby-image/withIEPolyfill'
import { getStyles, React } from 'lib'

/**
 * @exports <Image/>
 * Simple image component that uses gatsby sourcery internally
 *
 * @prop {string} src or source ---> Selected  animation value
 * @prop {object} style ---> Image style\
 *
 */

const dynamicAssetPrefixes = ['http', 'https', 'blob']

const Image = (props) => {
  const source = Tools.parseSourceUrl(props)
  const isStatic = !dynamicAssetPrefixes.filter(i => source?.startsWith(i)).length
  const styles = getStyles(props.variant, ImageStyles)
  if (isStatic && (source?.startsWith('https') || source?.startsWith('assets'))) {
    return (
      <StaticQuery
        query={staticFluidQuery}
        render={(data) => {
          const image = data.images.edges.find(n => n.node.relativePath.includes(source))
          if (!image) return null
          return (
            <GatsbyImage
              {...props}
              alt={props.alt}
              // fadeIn={false}
              objectFit={props.objectFit || props.styles?.objectFit || 'contain'}
              // style={styles}
              style={null}
              css={[styles.image, props.style]}
              sizes={image.node.childImageSharp.sizes}
              fluid={image.node.childImageSharp.fluid}
            />
          )
        }}
      />
    )
  } else {
    return (
      <img src={source} style={null} css={[styles.image, props.style]}/>
    )
  }
}

const staticFluidQuery = graphql`
query {
  images: allFile {
    edges {
      node {
        relativePath
        name
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`

export default Image
