import { React } from 'lib'
import Slider from 'react-slick'

const SlickSlider = (props, ref) => {
  const {
    children,
    ...otherProps
  } = props
  return (
    <Slider
      ref={ref}
      // initialSlide={0}
      slidesToScroll={1}
      arrows={false}
      {...otherProps}
    >

      {children}
    </Slider>
  )
}

const SliderComponent = React.forwardRef(SlickSlider)

export default SliderComponent
