/* eslint-disable react/jsx-closing-tag-location */
import { React, View, Text, onMount, onUpdate, Touchable, useState } from 'lib'
import { Theme } from 'app'
import VisibilitySensor from 'react-visibility-sensor'
import Arrow from '../../assets/arrow_down.svg'

function TitledSection(props) {
  const {
    children,
    titleMsg,
    descriptionMsg,
    index,
    onChangeVisibility = () => null,
    hasAccordion = false,
    opened = false,
  } = props

  const [open, setOpen] = useState(false)
  const [hover, onHover] = useState(false)
  const [openedFirst, setOpenedFirst] = useState(opened)

  onMount(() => {
    onChangeVisibility({ isVisible: index <= 1, ...props })
  })

  onUpdate(() => {
    onChangeVisibility({ isHovered: hover, ...props })
  }, [hover])

  const onChange = (isVisible) => {
    onChangeVisibility({ isVisible, ...props })
  }

  const openItem = () => {
    if (openedFirst) {
      setOpen(false)
      setOpenedFirst(false)
    } else {
      setOpen(!open)
    }
  }

  const renderHeader = () => {
    if (hasAccordion) {
      return (
        <Touchable onPress={() => openItem()} variant={'row alignCenter justifySpaceBetween'}>
          <View style={styles.titles}>
            <Text variant={`h4`} msg={titleMsg}/>
            {descriptionMsg && <Text variant={`p1 marginTop:1`} msg={descriptionMsg} style={styles.descriptionMsg}/>}
          </View>
          <img style={open || openedFirst ? styles.arrowUp : styles.arrowDown} src={Arrow}/>
        </Touchable>
      )
    }

    return (
      <View style={styles.titles} variant={'marginBottom:3'}>
        <Text variant={`h3`} msg={titleMsg}/>
        {descriptionMsg && <Text variant={`p1 marginTop:2`} msg={descriptionMsg} style={styles.descriptionMsg}/>}
      </View>
    )
  }

  return (
    <View onHover={onHover} style={[styles.wrapper, hasAccordion && styles.separator]}>
      {renderHeader()}
      <VisibilitySensor minTopValue={60} onChange={onChange}>
        <View style={hasAccordion ? open || openedFirst ? styles.tabContentOpen : styles.tabContent : {}}>
          {children}
        </View>
      </VisibilitySensor>
    </View>
  )
}

const styles = {
  wrapper: {
    paddingBottom: Theme.spacing(4),
    paddingTop: Theme.spacing(4),
  },
  titles: {
    paddingRight: Theme.spacing(10),
    [Theme.media.is('small')]: {
      paddingRight: Theme.spacing(0),
    },
  },
  descriptionMsg: {
    [Theme.media.is('small')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  separator: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: Theme.colors.light,
    paddingTop: Theme.spacing(4),
    marginBottom: Theme.spacing(0),
  },
  tabContent: {
    maxHeight: 0,
    transition: 'all .35s',
    opacity: 0,
    overflow: 'hidden',
  },
  tabContentOpen: {
    maxHeight: '100vh',
    transition: 'all .35s',
    opacity: 1,
    marginTop: Theme.spacing(2),
    paddingBottom: Theme.spacing(4),
  },
  arrowUp: {
    transition: 'all .35s',
    transform: 'rotate(180deg)',
    width: Theme.spacing(3),
  },
  arrowDown: {
    transition: 'all .35s',
    transform: 'rotate(0deg)',
    width: Theme.spacing(3),
  },
}

export default TitledSection
