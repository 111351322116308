import { React } from 'lib'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
      }
      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <link rel='icon' type='image/png' href='/images/favicon.png'/>
            <link rel='preconnect' href='https://fonts.gstatic.com'/>
            <link href='https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap' rel='stylesheet'/>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap' rel='stylesheet'/>
            <link href='https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap' rel='stylesheet'/>
            <meta name='description' content={seo.description}/>
            <meta name='image' content={seo.image}/>
            <meta property='og:image' content={seo.image}/>
            {/* {seo.url && <meta property='og:url' content={seo.url}/>} */}
            {(article ? true : null) && (
              <meta property='og:type' content='article'/>
            )}
            {seo.title && <meta property='og:title' content={seo.title}/>}
            {seo.description && (
              <meta property='og:description' content={seo.description}/>
            )}
            {seo.image && <meta property='og:image' content={seo.image}/>}
            <meta name='twitter:card' content='summary_large_image'/>
            {twitterUsername && (
              <meta name='twitter:creator' content={twitterUsername}/>
            )}
            {seo.title && <meta name='twitter:title' content={seo.title}/>}
            {seo.description && (
              <meta name='twitter:description' content={seo.description}/>
            )}
            {seo.image && <meta name='twitter:image' content={seo.image}/>}
            <meta property='og:type' content={'website'}/>
            <meta property='fb:app_id' content='2228731867237494'/>
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
