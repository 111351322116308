import { React } from 'lib'
import { Theme } from 'app'
import './SpinnerStyles.css'

function Spinner() {
  return (
    <div className='lds-default' style={{ '--spinner-color': Theme.colors.primary }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  )
}

export default Spinner
