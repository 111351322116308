/* eslint-disable react/jsx-closing-tag-location */
import { React, View, Text, Misc } from 'lib'
import { Theme } from 'app'

function PriceRangeView(props) {
  const { data, currencyListing, currencyMe, minPriceConverted, maxPriceConverted } = props
  const isRange = Boolean(data[1])

  if (isRange) {
    const min = Misc.formatNumber(data[0], currencyListing)
    const max = Misc.formatNumber(data[1], currencyListing)
    return (
      <View style={styles.wrapper}>
        {currencyListing !== currencyMe && currencyMe
          ? <View>
            <Text
              variant={priceTextVariants}
              text={`${min} to ${max}`}
            />
            <Text
              variant={'p2'}
              text={`Original Price: ${Misc.formatNumber(minPriceConverted, currencyMe)} to ${Misc.formatNumber(maxPriceConverted, currencyMe)}`}
            />
          </View>
          : <Text
            variant={priceTextVariants}
            text={`${min} to ${max}`}
          />
        }
      </View>
    )
  } else {
    const value = Misc.formatNumber(data[0], currencyListing)
    return (
      <View style={styles.wrapper}>
        {currencyListing !== currencyMe && currencyMe
          ? <View>
            <Text variant={priceTextVariants} text={Misc.formatNumber(minPriceConverted, currencyMe)}/>
            <Text variant={'p2'} text={`Original Price: ${value}`}/>
          </View>
          : <Text variant={priceTextVariants} text={value}/>
        }
      </View>
    )
  }
}

const priceTextVariants = `h4`

const styles = {
  wrapper: {
    ...Theme.marginVertical(2),
  },
}

export default PriceRangeView
