import Cookies from 'js-cookie'

function get(key) {
  return Cookies.get(key)
}

function set(key, value) {
  return Cookies.set(key, value, { expires: 365 })
}

function remove(key) {
  return Cookies.remove(key)
}

export default {
  get,
  set,
  remove,
}
