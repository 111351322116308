import { Theme } from 'app'

const image = {
  default: {
    backgroundColor: Theme.colors.light,
    ...Theme.center,
    ...Theme.semiCircle(140),
    overflow: 'visible',
    objectFit: 'cover',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [Theme.media.is('small')]: {
      ...Theme.semiCircle(70),
    },
  },
  large: {
    ...Theme.semiCircle(200),
    [Theme.media.is('small')]: {
      ...Theme.semiCircle(80),
    },
  },
  medium: {
    ...Theme.semiCircle(100),
    [Theme.media.is('small')]: {
      ...Theme.semiCircle(60),
    },
  },
  banner: {
    ...Theme.semiCircle(60),
    [Theme.media.is('small')]: {
      ...Theme.semiCircle(40),
    },
  },
  small: {
    ...Theme.semiCircle(40),
    [Theme.media.is('small')]: {
      ...Theme.semiCircle(30),
    },
  },
  verySmall: {
    ...Theme.semiCircle(16),
    [Theme.media.is('small')]: {
      ...Theme.semiCircle(14),
    },
  },

  //circle
  largeCircle: {
    ...Theme.circle(100),
    [Theme.media.is('small')]: {
      ...Theme.circle(60),
    },
  },
  midCircle: {
    ...Theme.circle(40),
    [Theme.media.is('small')]: {
      ...Theme.circle(30),
    },
  },
  smallCircle: {
    ...Theme.circle(30),
    [Theme.media.is('small')]: {
      ...Theme.circle(30),
    },
  },
  borders: {
    ...Theme.borders,
  },
}

// NOTE: Keep all available properties here even if empty
const text = {
  default: {
    fontSize: 26,
  },
  large: {
    fontSize: 150,
    lineHeight: 160,
  },
  medium: {
    fontSize: 60,
    lineHeight: 50,
  },
  banner: {
    fontSize: 24,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  small: {
    fontSize: 20,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  verySmall: {
    fontSize: 11,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}

// General styles not related to classes
const general = {
  wrapper: {
    overflow: 'visible',
    position: 'relative',
  },
  editImageBubble: {
    overflow: 'visible',
    position: 'absolute',
    ...Theme.center,
    display: 'flex',
    zIndex: 99,
  },
  editImageIcon: {
    ...Theme.center,
    width: 150,
    height: 150,
  },
  fileInput: {
    display: 'none',
  },
  editing: {
    backgroundColor: Theme.colors.black,
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadiusSmall,
    position: 'absolute',
    zIndex: 9,
    opacity: 0.3,
  },
  disabled: {
    pointerEvents: 'none',
  },
}

export default {
  image,
  text,
  general,
}
