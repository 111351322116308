import { React, View, Text, onMount, navigate } from 'lib'
import { Theme } from 'app'

function Redirect() {
  onMount(() => {
    navigate('/404')
  })

  return (
    <View style={styles.wrapper}>
      <View style={styles.innerWrapper}>
        <Text variant={`h3`} text={`Redirecting...`}/>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
  },
  innerWrapper: {
    marginVertical: Theme.spacing(4),
  },
}

export default Redirect
