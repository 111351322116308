import { Fetch } from 'actions'
import { Settings } from 'app'

const MODULE_NAME = 'https://openexchangerates.org/api/'

async function getExchangeRate({ base, target, onSuccess = () => null, onFailure = () => null }) {
  onFailure()
  return null
  const url = `${MODULE_NAME}latest.json?app_id=${Settings.OXR_API_KEY}&symbols=${base},${target}`
  await Fetch({
    url,
    method: 'GET',
    headers: null,
    options: {
      full_url: true,
      json: true,
    },
    successCallback: (res) => {
      const value = res.rates[target] / res.rates[base]
      onSuccess(value)
    },
    failureCallback: (err) => {
      warn({ err })
      onFailure(err)
    },
  })
}

export default {
  getExchangeRate,
}
