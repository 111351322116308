import { Theme, ViewStyles } from '_app'

const DEFAULT_COLOR = '#555'
const HEADER_COLOR = '#000'
const DEFAULT_TRANSITION = '300ms'

const text = {
  ...ViewStyles,
  // Typography
  get default() {
    return {
      ...Theme.row,
      ...this.p1,
      color: DEFAULT_COLOR,
      fontFamily: Theme.fontFamily,
      transition: DEFAULT_TRANSITION,
    }
  },
  h1: {
    fontSize: 'max(38px, min(20px + 2.80vw, 96px))',
    lineHeight: 'max(38px, min(20px + 2.80vw, 96px))',
    color: HEADER_COLOR,
    fontWeight: '900',
    fontFamily: Theme.boldFontFamily,
    letterSpacing: -0.2,
  },
  h2: {
    color: HEADER_COLOR,
    fontSize: 'max(28px, min(calc(20px + 1.40vw), 68px))',
    lineHeight: 'max(28px, min(calc(20px + 1.40vw), 68px))',
    fontWeight: '800',
    fontFamily: Theme.boldFontFamily,
    letterSpacing: -0.2,
  },
  h3: {
    color: HEADER_COLOR,
    fontSize: 'max(22px, min(calc(16px + 0.45vw), 26px))',
    lineHeight: 'max(22px, min(calc(16px + 0.45vw), 26px))',
    fontWeight: '700',
    fontFamily: Theme.boldFontFamily,
    letterSpacing: -0.2,
  },
  h4: {
    color: HEADER_COLOR,
    fontSize: 'max(16px, min(calc(12px + 0.40vw), 18px))',
    // lineHeight: 'max(18px, min(calc(14px + 0.40vw), 20px))',
    fontWeight: '700',
    fontFamily: Theme.boldFontFamily,
    letterSpacing: -0.5,
  },
  h5: {
    color: HEADER_COLOR,
    fontSize: 'max(14px, min(calc(10px + 0.40vw), 16px))',
    // lineHeight: 'max(14px, min(calc(10px + 0.40vw), 16px))',
    fontWeight: '600',
    fontFamily: Theme.boldFontFamily,
    letterSpacing: -0.5,
  },
  h6: {
    color: HEADER_COLOR,
    fontSize: 'max(12px, min(calc(8px + 0.40vw), 14px))',
    // lineHeight: 'max(12px, min(calc(8px + 0.40vw), 14px))',
    fontFamily: Theme.boldFontFamily,
    letterSpacing: -0.2,
  },
  p1: {
    // fontSize: 'max(16px, min(calc(10px + 1vw), 18px))',
    fontSize: 'max(16px, min(calc(8px + 0.3vw), 18px))',
    lineHeight: 1.5,
  },
  p2: {
    // fontSize: 'max(14px, min(calc(8px + 1vw), 16px))',
    fontSize: 'max(14px, min(calc(7px + 0.3vw), 16px))',
    lineHeight: 1.5,
  },
  p3: {
    // fontSize: 'max(12px, min(calc(6px + 1vw), 12px))',
    fontSize: 'max(11px, min(calc(7px + 0.35vw), 12px))',
    lineHeight: 1.5,
  },

  // Aditional styles
  inherit: {
    fontWeight: 'inherit',
    fontSize: 'inherit',
  },
  veryBold: {
    fontWeight: '700',
    color: '#222',
  },
  bold: {
    fontWeight: 'bold',
    color: '#333',
  },
  regular: {
    fontWeight: '400',
  },
  thin: {
    fontWeight: '300',
  },
  veryThin: {
    fontWeight: '100',
  },
  italic: {
    fontStyle: 'italic',
  },
  link: {
    color: '#333',
    textDecorationStyle: 'solid',
    textDecorationColor: 'black',
    textDecorationLine: 'underline',
    '&:hover': {
      color: Theme.colors.primary,
      textDecorationColor: Theme.colors.primary,
    },
  },
  noDecoration: {
    textDecorationLine: 'none',
  },
  noWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    'text-overflow': 'ellipsis',
  },
  underline: {
    textDecorationLine: 'underline',
    textDecorationColor: Theme.colors.lightGrey,
  },
  hoverDecoration: {
    textDecorationLine: 'none',
    '&:hover': {
      textDecorationLine: 'underline',
      textDecorationColor: Theme.colors.lightGrey,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  clamp: {
    ...Theme.clamp,
  },
  block: {
    ...Theme.block,
  },
  inline: {
    ...Theme.inline,
  },
  clamp2x: {
    ...Theme.clamp2x,
  },
  get silent() {
    return {
      fontSize: this.p2.fontSize,
      color: '#777',
      textDecorationLine: 'none',
      '&:hover': {
        color: '#333',
        textDecorationStyle: 'solid',
        textDecorationColor: 'black',
        textDecorationLine: 'underline',
      },
    }
  },
  space: {
    paddingLeft: 4,
  },

  // Colors
  white: {
    color: 'white',
    textDecorationColor: 'white',
  },
  shadow: {
    textShadow: '2px 2px 20px #00000033',
  },
  darkGrey: {
    color: Theme.colors.darkGrey,
  },
  grey: {
    color: Theme.colors.grey,
  },
  lightGrey: {
    color: Theme.colors.lightGrey,
  },
  darkest: {
    color: Theme.colors.darkest,
  },
  black: {
    color: Theme.colors.black,
  },
  lightOrange: {
    color: Theme.colors.lightOrange,
  },
  orange: {
    color: Theme.colors.orange,
  },
  darkOrange: {
    color: Theme.colors.darkOrange,
  },
  primary: {
    color: Theme.colors.primary,
  },
  danger: {
    color: Theme.colors.lightRed,
  },
  debug: {
    ...Theme.debug('rgba(50, 100, 255, 0.5)'),
  },
}

const compatibility = {
  ...text,
  // Typography
  default: {
  },
  h1: {
    fontSize: '4rem',
  },
  h2: {
    fontSize: '2.6rem',
  },
  h3: {
    fontSize: '2.0rem',
  },
  h4: {
    fontSize: '1.6rem',
  },
  h5: {
    fontSize: '1.4rem',
  },
  h6: {
    fontSize: '1.2rem',
  },
  p1: {
    fontSize: '16px',
  },
  p2: {
    fontSize: '14px',
  },
  p3: {
    fontSize: '13px',
  },
}

export default {
  text,
  compatibility,
}
