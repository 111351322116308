import { React, View, Text } from 'lib'
import { Theme } from 'app'
import { Spinner } from 'components'

function Loading() {
  return (
    <View style={styles.wrapper}>
      <View style={styles.innerWrapper}>
        <View style={styles.spinnerWrapper}>
          <Spinner/>
        </View>
        <Text variant={`h5 grey`} text={`Loading...`}/>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
    minHeight: 500,
  },
  innerWrapper: {
    flex: 1,
    ...Theme.center,
  },
  spinnerWrapper: {
    padding: Theme.spacing(2),
  },
}

export default Loading
