/* eslint no-restricted-imports: 'off' */
import { View, Text, useState, Touchable, useRef } from 'lib'
import { InputStyles } from '_app'
import TextareaAutosize from 'react-autosize-textarea'
import { injectIntl } from 'gatsby-plugin-intl'
import showPasswordImage from '../../assets/show_password.png'

/** @jsx jsx */
import { jsx } from '@emotion/react'

/**
 * <Input/>
 *
 * [Required props]
 * @prop {string} value ---> Current field value
 * @prop {function} onChange ---> When text updates
 *
 * [Optional props]
 * @prop {string} label ---> Display label text
 * @prop {object} style ---> Styles to be applied to wrapper
 * @prop {object} textStyle ---> Styles to be applied to text
 * @prop {string} required ---> Whether the field is required
 * @prop {function} validate ---> Validate function - return error message if invalid or null if valid
 */

function Input(rawProps) {
  // NOTE Start by declaring what the component is mostly dependent on, and which variables
  // Best to start defining what's going to affect the state of the components and describe the main props being used

  const {
    style,
    textStyle,
    wrapperStyle,
    value,
    label,
    labelMsg,
    labelVariant,
    onChange,
    onChangeText,
    validate,
    variant,
    required,
    type,
    multiline,
    intl,
    debug,
    inputRef,
    onFocus,
    onBlur,
    onKeyDown,
    errorStyle,
    hideShowPassword,
    ...props
  } = rawProps

  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [edited, setEdited] = useState(false)

  const input = useRef(null)

  // NOTE Then define the functions
  const handleChange = event => {
    const text = event.target.value
    if (onChange) onChange(event)
    if (onChangeText) onChangeText(text)
  }

  const handlePress = () => {
    if (inputRef) {
      inputRef.current.focus()
    } else {
      input.current.focus()
    }
  }

  function handleWrapperClick(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleBlur = () => {
    if (!edited && value) setEdited(true)
    setFocus(false)

    if (onBlur) {
      onBlur()
    }
  }

  const handleFocus = () => {
    setFocus(true)
    if (onFocus) {
      onFocus()
    }
  }

  // NOTE After functions define the variables to be used in components
  // It's best to be near components so they can be visualised at the same time
  const validationMsg = rawProps.hasOwnProperty('validate') ? (typeof validate == 'function' ? validate(value) : validate) : null
  const validateError = validationMsg ? intl.formatMessage({ id: validationMsg }) : null

  let animate = 'default'
  if (value) animate = 'populated'
  if (hover) animate = 'hover'
  if (focus) animate = 'focus'
  if (validateError && edited) animate = 'invalid'

  // NOTE If there are any sub render functions, these should be declared right before the final render method

  const InputElement = multiline ? TextareaAutosize : 'input'

  if (debug) {
    log({ props, rawProps, animate, validate, validateError, validationMsg })
  }

  const inputVariantValues = Object.keys(InputStyles.wrapper)
  // const intersect = variant && inputVariantValues.filter(v => variant.split(' ').includes(v))
  const intersect = variant && Tools.getIntersection(inputVariantValues, variant.split(' '))
  const animationVariant = intersect?.length ? intersect[0] : 'default'

  const wrapperVariants = InputStyles.wrapper[animationVariant]
  const labelVariants = InputStyles.label[animationVariant]
  const staticStyles = InputStyles.staticStyles[animationVariant]

  const labelText = !labelMsg && `${label}${required ? '*' : ''}`
  const hasLabel = labelMsg ||label

  const wrapperAnimation = wrapperVariants && wrapperVariants[animate]
  const labelAnimation = labelVariants && labelVariants[animate]

  return (
    <View onClick={handleWrapperClick} style={[staticStyles.wrapper, wrapperStyle, multiline && staticStyles.multiline]} variant={variant} debug={debug} ignoreWarnings={true}>
      {hasLabel && (
        <View style={labelAnimation}>
          <Text style={staticStyles.label}
            msg={labelMsg} colortext={labelText}/>
        </View>
      )}
      <Touchable
        style={[staticStyles.innerWrapper, hasLabel && staticStyles.innerLabel, wrapperAnimation, style]}
        onHover={setHover}
        onPress={() => handlePress()}
        pointerEvents={'auto'}
      >
        <InputElement
          ref={inputRef || input}
          type={type || 'text'}
          css={[staticStyles.input, textStyle]}
          onChange={text => handleChange(text)}
          value={value}
          onFocus={() => handleFocus()}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          rows={6}
          {...props}
        />
        {hideShowPassword &&
          <Touchable onPress={hideShowPassword}>
            <img src={showPasswordImage} style={staticStyles.showPasswordImage}/>
          </Touchable>
        }
      </Touchable>
      {animate == 'invalid' && <Text style={[staticStyles.validatorText, errorStyle]} text={validateError}></Text>}
    </View>
  )
}

export default injectIntl(Input)
