/* eslint no-restricted-imports: 'off' */
import { useRedux, Toast, Tools, ReduxTools, navigate } from 'lib'
import { AppStatus, Fetch, Profile, store } from 'actions'

const MODULE_NAME = 'agency/'


//
// Actions to dispatch
//

function receiveMyAgency(data) {
  store.dispatch({ type: 'RECEIVE_MY_AGENCY', data })
}

function receiveMembers(data) {
  store.dispatch({ type: 'RECEIVE_MEMBERS', data })
}

//
// Object manipulation
//

async function fetch(agency, onSuccess = () => null) {
  let agencyId = agency.id
  if (!agencyId || agency == '*') agencyId = ''
  await Fetch({
    url: MODULE_NAME + agencyId,
    method: 'GET',
    options: {
      noToken: true,
    },
    successCallback: (data) => {
      const result = data.hasOwnProperty('results') ? data.results : data
      onSuccess(result)
    },
  })
}

async function fetchMine(onSuccess = () => null) {
  const myAgencyId = store.getState().Session.profile.agency
  await Fetch({
    url: MODULE_NAME + `${myAgencyId}`,
    method: 'GET',
    successCallback: (data) => {
      const result = data.hasOwnProperty('results') ? data.results : data
      receiveMyAgency(result)
      onSuccess(result)
    },
  })
}

async function create({ data, file }, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  let sendFile = 'sendNull'
  if (file) {
    sendFile = file
  }
  const uploadData = Tools.getMultipartFileUploadFormData(data, sendFile)
  await Fetch({
    url: MODULE_NAME,
    method: 'POST',
    data: uploadData,
    options: {
      multipart: true,
      json: true,
    },
    successCallback: (agency) => {
      AppStatus.setActivity('done')
      receiveMyAgency(agency)
      onSuccess(agency)
    },
  })
}

async function save({ data, file, background }, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  const uploadData = Tools.getMultipartFileUploadFormData(data, file, background)
  await Fetch({
    url: MODULE_NAME + data.id,
    method: 'PATCH',
    data: uploadData,
    options: {
      multipart: true,
      json: true,
    },
    successCallback: (agency) => {
      receiveMyAgency(agency)
      AppStatus.setActivity('done')
      onSuccess(agency)
    },
  })
}

async function searchAgency(name, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + `?name=${name}`,
    method: 'GET',
    successCallback: (res) => {
      onSuccess(res)
    },
  })
}

async function getAgents(id, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + `?development_id=${id}`,
    method: 'GET',
    successCallback: (res) => {
      onSuccess(res)
    },
  })
}

async function fetchMembers(onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + `member/`,
    method: 'GET',
    successCallback: (data) => {
      if (data?.results) {
        onSuccess(data.results)
      }
    },
  })
}

async function memberByAgency(id, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + `member/?agency=${id}`,
    method: 'GET',
    options: {
      noToken: true,
    },
    successCallback: (data) => {
      if (data?.results) {
        receiveMembers(data.results)
        onSuccess(data.results)
      }
    },
  })
}

async function removeMember(profileId, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  await Fetch({
    url: MODULE_NAME + `member/${profileId}/`,
    method: 'DELETE',
    successCallback: () => {
      Toast.success('Member removed')
      AppStatus.setActivity(null)
      onSuccess()
    },
    failureCallback: () => {
      AppStatus.setActivity(null)
    },
  })
}

async function fetchInvitations(onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + `invite/`,
    method: 'GET',
    successCallback: (data) => {
      if (data) {
        onSuccess(data)
      }
    },
  })
}

async function createInvitation(data, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  await Fetch({
    url: MODULE_NAME + `invite/`,
    method: 'POST',
    data,
    options: {
      json: true,
    },
    successCallback: () => {
      Toast.success(`Invitation sent to ${data.email}`)
      AppStatus.setActivity(null)
      onSuccess()
    },
    failureCallback: () => {
      AppStatus.setActivity(null)
    },
  })
}

async function acceptInvitation(inviteId) {
  AppStatus.setActivity('loading')
  await Fetch({
    url: MODULE_NAME + `member/`,
    method: 'POST',
    data: { invite: inviteId },
    options: {
      json: true,
    },
    successCallback: () => {
      Profile.getOwnProfile()
      setTimeout(() => {
        fetchMine()
        Toast.success('Agency invite accepted')
        AppStatus.setActivity(null)
        navigate('/agency')
      }, 1000)
    },
    failureCallback: (res) => {
      if (res.status === 401 || res.status === 400) {
        Toast.info('This invitation is not valid for the current email address')
        navigate('/')
      }
      AppStatus.setActivity(null)
    },
  })
}

async function declineInvitation(inviteId, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  await Fetch({
    url: MODULE_NAME + `invite/${inviteId}/`,
    method: 'DELETE',
    successCallback: () => {
      AppStatus.setActivity(null)
      onSuccess()
    },
    failureCallback: () => {
      AppStatus.setActivity(null)
    },
  })
}

async function checkInvitation(inviteId, onSuccess = () => null, onFailure = () => null) {
  AppStatus.setActivity('loading')
  await Fetch({
    url: MODULE_NAME + `invite/${inviteId}/`,
    method: 'GET',
    successCallback: (res) => {
      onSuccess(res)
      AppStatus.setActivity(null)
    },
    failureCallback: (res) => {
      onFailure(res)
      AppStatus.setActivity(null)
    },
  })
}

export default {
  fetch,
  fetchMine,
  create,
  save,
  searchAgency,
  getAgents,
  fetchMembers,
  removeMember,
  memberByAgency,
  fetchInvitations,
  createInvitation,
  acceptInvitation,
  declineInvitation,
  checkInvitation,
}
