import { React, Component, View } from 'lib'
import posed from 'react-pose'
import { IoIosArrowDown } from 'react-icons/io'
import Fade from 'react-reveal/Fade'

const BOUNCE_INTERVAL = 1500

class ScrollDownIndicator extends Component {
  constructor(props) {
    super(props)
    this.timer = null
    this.state = {
      up: true,
      visible: false,
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ visible: true }), 2000)
    this.timer = setInterval(() => {
      this.setState({ up: !this.state.up })
    }, BOUNCE_INTERVAL)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const color = this.props.hasOwnProperty('color') ? this.props.color : 'white'
    return (
      <Bounce pose={this.state.up ? 'init' : 'down'}>
        <a href={this.props.href}>
          <View className={styles.innerWrapper} pointerEvents={'none'}>
            <Fade delay={2000}>
              <IoIosArrowDown
                color={this.state.visible ? color : 'transparent'}
                size={32}
              />
            </Fade>
          </View>
        </a>
      </Bounce>
    )
  }
}

const styles = {
  innerWrapper: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
}

const Bounce = posed.div({
  init: {
    marginBottom: 70,
    transition: {
      duration: BOUNCE_INTERVAL,
      ease: 'easeInOut',
    },
  },
  down: {
    marginBottom: 50,
    transition: {
      duration: BOUNCE_INTERVAL,
      ease: 'easeInOut',
    },
  },
})

export default ScrollDownIndicator
