import { React, View } from 'lib'
import { Theme } from 'app'
import { Document, Page } from 'react-pdf'
import { Spinner } from 'components'


function PDFPreview(props) {
  const { source, style } = props
  const file = source.preview || Tools.parseSourceUrl(source.file)

  return (
    <View style={[styles.wrapper, style]}>
      <Document
        file={file}
        loading={Spinner}
      >
        <Page
          pageNumber={1}
          width={props.width || 300}
        />
      </Document>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
    display: 'flex',
  },
}

export default PDFPreview
