import { Theme } from 'app'
import { React, Touchable, View, Text } from 'lib'

const RadioButton = ({
  value = null,
  onPress = () => null,
  labelMsg,
  children,
  variant = '',
}) => {
  return (
    <View variant={`row alignCenter ${variant}`}>
      <Touchable style={[styles.wrapper, value && styles.wrapperSelected]} onPress={onPress}>
        {value && <View style={styles.circle}/>}
      </Touchable>
      {labelMsg && <Text variant={'marginLeft:2 black'} msg={labelMsg}/>}
      {children}
    </View>
  )
}

const CIRCLE_SIZE = 24

const styles = {
  wrapper: {
    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE,
    ...Theme.center,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Theme.colors.light,
    borderRadius: '50%',
    transition: 'all .1s ease',
    '&:hover': {
      borderColor: Theme.colors.black,
    },
  },
  wrapperSelected: {
    borderColor: Theme.colors.black,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: Theme.colors.black,
  },
}

RadioButton.defaultProps = {}

export default RadioButton
