import { React } from 'lib'

function UL(props) {
  const renderItem = (item, key) => <li key={key}>{item}</li>
  const content = Array.isArray(props.children) ? props.children.map(renderItem) : renderItem(props.children)
  return (
    <ul>
      {content}
    </ul>
  )
}

export default UL
