import { React, View, Text, Touchable, Input, Checkbox } from 'lib'
import { Theme } from 'app'
import { AssetPreview } from 'components'
import { useDrag, useDrop } from 'react-dnd'

export const AssetItemTypes = { DROP_ITEM: 'asset_item' }

const AssetItem = ({
  data,
  index,
  onUpdate,
  onMove,
  onRemove,
  role = 'development',
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: AssetItemTypes.DROP_ITEM, id: data.id, originalIndex: index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        onMove(droppedId, originalIndex)
      }
    },
  })

  const [, drop] = useDrop({
    accept: AssetItemTypes.DROP_ITEM,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== data.id) {
        onMove(draggedId, index)
      }
    },
  })

  const opacity = isDragging ? 0.5 : 1

  if (!data || data?.remove) return null

  const { active, caption, original_caption } = data

  function handleChange(args) {
    onUpdate({ ...data, ...args, changed: true }, index)
  }

  function removeItem() {
    onRemove(index, { ...data, remove: true })
  }

  function renderInfoBox({ titleMsg, content }) {
    return (
      <View style={styles.originalInfoWrapper}>
        <View style={styles.originalInfoBox}>
          <Text variant={`p1 bold`} msg={titleMsg}/>
          <Text variant={`p1 marginLeft:2`} text={content}/>
        </View>
      </View>
    )
  }

  const media = Theme.hooks.media()
  const isProtected = data.is_protected
  const valueCaption = caption !== 'Add a caption' && caption !== ''
    ? caption
    : original_caption !== 'Add a caption' && original_caption !== ''
      ? original_caption : ''
  return (
    <View ref={(node) => drag(drop(node))} style={{ ...styles.itemWrapper, opacity }}>
      {role !== 'development' && (
        <View style={styles.checkboxWrapper}>
          {<Checkbox onPress={() => handleChange({ active: !active })} value={active}/>}
        </View>
      )}
      <View style={styles.items}>
        <AssetPreview item={data} style={styles.preview}/>
        <View style={styles.itemInnerWrapper}>
          <Input
            variant={'pill'}
            labelMsg={'CAPTION'}
            value={valueCaption}
            onChangeText={text => handleChange({ caption: text })}
            placeholder={'Describe your asset'}
          />
          {role !== 'development' && renderInfoBox({ titleMsg: 'Original caption:', content: original_caption })}
          {role === 'development' && (
            <View variant={'row marginTop:2'}>
              <Touchable onPress={removeItem} variant={'row center'} disabled={isProtected}>
                <Text variant={'p2 lightGrey underline'} msg={'DELETE PHOTO'}></Text>
              </Touchable>
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

const styles = {
  itemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...Theme.debug,
    marginTop: Theme.spacing(4),
    cursor: 'move',
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

    [Theme.media.is('small')]: {
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  originalInfoWrapper: {
    flex: 1,
    display: 'flex',
  },
  originalInfoBox: {
    display: 'flex',
    backgroundColor: Theme.colors.darkBrown,
    borderRadius: Theme.values.borderRadius,
    padding: Theme.spacing(2),
    marginTop: Theme.spacing(3),
    alignItems: 'center',
  },
  preview: {
    maxWidth: 170,
    height: 150,
    objectFit: 'cover',
    borderRadius: Theme.values.borderRadius,
    [Theme.media.is('small')]: {
      maxWidth: '100%',
      height: 250,
    },
  },
  itemInnerWrapper: {
    flex: 1,
    marginLeft: Theme.spacing(4),

    [Theme.media.is('small')]: {
      marginLeft: Theme.spacing(0),
      marginTop: Theme.spacing(2),
      marginBottom: Theme.spacing(2),
      width: '100%',
    },
  },
  checkboxWrapper: {
    minWidth: 30,
    ...Theme.center,
    ...Theme.marginHorizontal(4),
  },
}

export default AssetItem
