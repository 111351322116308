/* eslint no-restricted-imports: 'off' */
import { useRedux, Toast, Tools, ReduxTools, Cookies } from 'lib'
import { Fetch, store, AppStatus, Agency } from 'actions'
import { Settings } from 'app'
import firebase from 'gatsby-plugin-firebase'

const MODULE_NAME = 'profiles/'

//
// Listeners
//
if (Settings.IS_BROWSER) {
  firebase.auth().onAuthStateChanged(function(user) {
    const { isLoggedIn, profile, agency } = store.getState().Session
    if (user && profile && agency && isLoggedIn) {
      log('Already logged in.')
      AppStatus.setActivity(null)
    } else if (user?.email) {
      const onSuccess = p => {
        loginSuccess(p)
        if (p.agency) {
          Agency.fetchMine()
        }
      }
      getOwnProfile(onSuccess)
    } else {
      logout()
      log('No user is signed in.')
      AppStatus.setActivity(null)
    }
  })
}


//
// Actions to dispatch
//

function receiveContacts(data) {
  store.dispatch({ type: 'RECEIVE_CONTACTS', data })
}

async function logout(silent = false) {
  await firebase.auth().signOut()
  store.dispatch({ type: 'LOGOUT' })
  Cookies.remove('Session.isLoggedIn')
}

function loginSuccess(data) {
  log('Login success')
  store.dispatch({ type: 'LOGIN_SUCCESS', data })
  // Toast.success('Logged in')
  Cookies.set('Session.isLoggedIn', 'true')
  AppStatus.setActivity(null)
  AppStatus.dismissModals()
  // navigate('/seller')
}

//
// Authentication
//
async function signupWithEmail(data, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  try {
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(data.email, data.password)
    info({ userCredential })
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult()
    const uid = idTokenResult.claims.user_id
    onSuccess(uid)
  } catch (err) {
    warn({ err })
    const msg = err.message || 'A signup error has occured. Please contact support.'
    Toast.error(msg)
    AppStatus.setActivity(null)
  }
}

async function tryEmailLogin(data) {
  AppStatus.setActivity('loading')
  const result = await firebase.auth().signInWithEmailAndPassword(data.email, data.password).catch(reason => {
    warn('Email login failed', reason)
    Toast.error('Could not login. Please check your credentials.')
  })
  info({ result })
  AppStatus.setActivity(null)
  if (result && result.user) {
    getOwnProfile(p => loginSuccess(p))
  }
}

async function tryGoogleLogin() {
  AppStatus.setActivity('loading')

  try {
    const provider = new firebase.auth.GoogleAuthProvider()
    const result = await firebase.auth().signInWithPopup(provider)
      .catch(reason => {
        warn('Google login failed', reason)
        Toast.error('Could not login. Please check your credentials.')
      })

    if (result && result.user) {
      await trySocialLogin(result.user)
    }
  } catch (err) {
    Toast.error('Could not login. Please check your credentials.')
  } finally {
    AppStatus.setActivity(null)
  }
}

async function tryFacebookLogin() {
  AppStatus.setActivity('loading')

  try {
    const provider = new firebase.auth.FacebookAuthProvider()
    const result = await firebase.auth().signInWithPopup(provider)
      .catch(reason => {
        warn('Facebook login failed', reason)

        if (reason.code.includes('auth/account-exists-with-different-credential')) {
          Toast.error('An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.')
        } else {
          Toast.error('Could not login. Please check your credentials.')
        }
      })

    if (result && result.user) {
      await trySocialLogin(result.user)
    }
  } catch (err) {
    log(err)
    Toast.error('Could not login. Please check your credentials.')
  } finally {
    AppStatus.setActivity(null)
  }
}

async function trySocialLogin(data) {
  const profileNames = data.displayName.split(' ')
  const profileData = {
    id: data.uid,
    email: data.email,
    first_name: profileNames[0],
    last_name: profileNames[1],
    active: true,
  }
  const uploadData = Tools.getMultipartFileUploadFormData(profileData, 'sendNull')
  const onSuccess = profile => {
    loginSuccess(profile)
  }
  const onError = () => {
    createProfileBackend(uploadData)
  }
  await getOwnProfile(onSuccess, onError)
}

function create({ data, file }, onSuccess = () => null) {
  let sendFile = 'sendNull'
  if (file) {
    sendFile = file
  }
  const uploadData = Tools.getMultipartFileUploadFormData(data, sendFile)
  const user = firebase.auth().currentUser
  if (user?.email != data.email) {
    updateEmail(data.email, () => createProfileBackend(uploadData, onSuccess))
  } else {
    createProfileBackend(uploadData, onSuccess)
  }
}

function createExternal(data, onSuccess = () => null) {
  const uploadData = Tools.getMultipartFileUploadFormData(data, 'sendNull')
  createProfileExternal(uploadData, onSuccess)
}

async function createProfileExternal(uploadData, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + 'create/',
    method: 'POST',
    data: uploadData,
    options: {
      multipart: true,
      json: true,
      noToken: true,
    },
    successCallback: (res) => {
      onSuccess(res)
    },
    failureCallback: () => {
      error('Error creating profile')
    },
  })
}

async function createProfileBackend(uploadData, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + 'create/',
    method: 'POST',
    data: uploadData,
    options: {
      multipart: true,
      json: true,
      noToken: true,
    },
    successCallback: (res) => {
      sendVerificationEmail()
      loginSuccess(res)
      onSuccess(res)
      AppStatus.setActivity(null)
    },
    failureCallback: () => {
      error('Error creating profile')
    },
  })
}

let userInfo = {}
function save({ data, file }, onSuccess = () => null) {
  AppStatus.setActivity('loading')

  const user = firebase.auth().currentUser
  data = Tools.copy(data)
  data.id = store.getState().Session.profileId
  let sendFile = 'sendNull'
  if (file) {
    sendFile = file
  }
  userInfo = { data, sendFile }
  const uploadData = Tools.getMultipartFileUploadFormData(data, sendFile)

  info({ user, data, uploadData })

  async function saveProfileBackend() {
    await Fetch({
      url: `${MODULE_NAME}${data.id}`,
      method: 'PATCH',
      data: uploadData,
      options: {
        multipart: true,
        json: true,
      },
      successCallback: (res) => {
        loginSuccess(res)
        onSuccess(res)
      },
      failureCallback: () => {
        AppStatus.setActivity(null)
        Toast.error('Error saving profile')
      },
    })
  }

  if (user.email != data.email) {
    updateEmail(data.email, () => saveProfileBackend(uploadData, onSuccess))
  } else {
    saveProfileBackend(uploadData, onSuccess)
  }
}


export async function checkAvailability(email) {
  const signinMethods = await firebase.auth().fetchSignInMethodsForEmail(email)
  const isAvailable = !signinMethods.includes('email')
  if (!isAvailable) {
    Toast.error('This email address is already taken.')
  }
  return isAvailable
}

let emailUpdated = ''
export async function verifyPassword(password) {
  const user = firebase.auth().currentUser
  const result = await firebase.auth().signInWithEmailAndPassword(user.email, password).catch(reason => {
    Toast.error('Could not login. Please check your credentials.')
    AppStatus.setActivity(null)
  })

  if (result && result.user) {
    AppStatus.setPasswordModal(false)
    user.updateEmail(emailUpdated).then(async () => {
      const uploadData = Tools.getMultipartFileUploadFormData(userInfo.data, userInfo.sendFile)
      await Fetch({
        url: `${MODULE_NAME}${userInfo.data.id}`,
        method: 'PATCH',
        data: uploadData,
        options: {
          multipart: true,
          json: true,
        },
        successCallback: (res) => {
          loginSuccess(res)
          AppStatus.setActivity('done')
        },
      })
    }).catch((error) => {
      AppStatus.setActivity(null)
      Toast.error(error.message)
    })
  }
}

function updateEmail(email) {
  emailUpdated = email
  AppStatus.setActivity(null)
  AppStatus.setPasswordModal(true)
}

function sendVerificationEmail() {
  const user = firebase.auth().currentUser
  user.sendEmailVerification().then(() => {
    info('Verification email sent')
  }).catch((error) => {
    Toast.error(error.message)
  })
}

function sendResetPasswordEmail(email = null, onSuccess = () => null) {
  const auth = firebase.auth()
  const user = auth.currentUser
  if (!email && user) {
    email = user._user.email
  }
  auth.sendPasswordResetEmail(email).then(function() {
    info('Reset password email sent')
    onSuccess()
  }).catch(function(error) {
    Toast.error(error.message)
  })
}

//
// Object manipulation
//
function getOwnProfile(onSuccess = () => null, onFailure = () => null) {
  return Fetch({
    url: MODULE_NAME + 'i',
    method: 'GET',
    options: {
      silent: true,
    },
    successCallback: (data) => {
      loginSuccess(data)
      onSuccess(data)
    },
    failureCallback: (res) => {
      onFailure(res)
    },
  })
}

async function getContacts(onSuccess = () => null, onFailure = () => null) {
  await Fetch({
    url: MODULE_NAME + '?is_contact=true',
    method: 'GET',
    options: {
      silent: true,
    },
    successCallback: (data) => {
      receiveContacts(data.results)
      onSuccess(data)
    },
    failureCallback: (res) => {
      onFailure(res)
    },
  })
}

async function fetch(id, onSuccess = () => null) {
  if (!id) {
    warn('No ID provided to profile fetch. Fetching all. This should not happen in production.')
    id = ''
    return
  }
  await Fetch({
    url: MODULE_NAME + id,
    method: 'GET',
    successCallback: (data) => {
      onSuccess(data)
    },
  })
}

async function fetchContacts(id, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + '?is_contact=true',
    method: 'GET',
    successCallback: (data) => {
      onSuccess(data)
    },
  })
}

export default {
  tryEmailLogin,
  tryGoogleLogin,
  tryFacebookLogin,
  getOwnProfile,
  getContacts,
  fetch,
  fetchContacts,
  create,
  createExternal,
  logout,
  save,
  signupWithEmail,
  sendResetPasswordEmail,
  verifyPassword,
}
