/* eslint global-require: 'off', @typescript-eslint/no-var-requires: 'off', no-restricted-imports: 'off' */
import 'firebase/auth'
import { React } from 'react'

import wrapWithProvider from './rootWrapperProvider'
import { Settings } from 'app'
import * as Sentry from '@sentry/browser'
import { store } from 'actions/reducers'

export const wrapRootElement = wrapWithProvider

function resize() {
  store.dispatch({ type: 'RESIZE_SCREEN' })
}

export const onClientEntry = () => {
  log('Gatsby launch')

  window.addEventListener('resize', () => resize())

  if (Settings.IS_DEVELOPMENT) {
    log('Development mode')
    if (Settings.WARN_WHY_DID_YOU_RENDER) {
      const whyDidYouRender = require('@welldone-software/why-did-you-render')
      whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
      })
    }
  } else {
    if (Settings.SENTRY_DSN) {
      log('Initializing Sentry with DSN ', Settings.SENTRY_DSN)
      Sentry.init({ dsn: Settings.SENTRY_DSN })
    }
    log('Production build')
  }
}
