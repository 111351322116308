import { React, View, Overlay, Button, Text } from 'lib'
import { Theme } from 'app'
import { MdClose } from 'react-icons/md'
import AssetSlider from './AssetSlider'

function AssetsLightbox({ data = [], titleMsg, open = false, onClose, initialSlide = 0 }) {
  return (
    <Overlay
      style={styles.wrapper}
      contentContainerStyle={styles.contentContainerStyle}
      open={open}
    >
      <View style={styles.closeButton} variant={'row alignCenter marginBottom:4 justifySpaceBetween marginTop:6'}>
        <View variant={'row flex alignCenter'}>
          <Text variant={'h4 bold black'} style={styles.title} msg={titleMsg || 'Photos'}/>
        </View>
        <Button
          key={'close'}
          icon={MdClose}
          onPress={onClose}
          variant={'small'}
          color={Theme.colors.primary}
          size={28}
        />
      </View>

      <AssetSlider data={data} initialSlide={initialSlide}/>
    </Overlay>
  )
}

const styles = {
  wrapper: {
    overflow: 'hidden',
    zIndex: 100,
  },
  contentContainerStyle: {
    height: '100vh',
    width: '100%',
    maxWidth: '100%',
    overflowY: 'auto',

    [Theme.media.down('xlarge')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  title: {
    flex: 0,
    marginLeft: Theme.spacing(2),

    [Theme.media.down('mid')]: {
      marginLeft: Theme.spacing(1),
    },
  },
}

export default AssetsLightbox
