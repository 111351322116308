
import { Theme } from 'app'

const values = {
  gridGap: 12,
  largeItemSize: 160,
  smallItemSize: 160,
  verySmallItemSize: 100,
  getFirstItemSize: function (size) {
    return (size * 2) + this.gridGap
  },
}

const firstGrid = {
  default: {
    height: 332,
    maxHeight: 158,
  },
  get small() {
    return {
      maxHeight: values.getFirstItemSize(values.smallItemSize),

      [Theme.media.down('xlarge')]: {
        maxHeight: values.getFirstItemSize(values.verySmallItemSize),
      },
    }
  },
}

const secondGrid = {
  default: {
    gridGap: values.gridGap,
    height: values.getFirstItemSize(values.largeItemSize),
    maxHeight: values.getFirstItemSize(values.largeItemSize),
  },
  get small() {
    return {
      ...this.default,
      maxHeight: values.smallItemSize,

      [Theme.media.down('xlarge')]: {
        maxHeight: values.verySmallItemSize,
      },

    }
  },
}
export default {
  firstGrid,
  secondGrid,
  values,
}
