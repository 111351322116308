/* eslint no-restricted-imports: 'off' */
import { store } from 'actions'

//
// Actions to dispatch
//

function setActivity(data) {
  store.dispatch({ type: 'SET_APP_ACTIVITY', data })
}

function setSignupModal(data) {
  store.dispatch({ type: 'SET_SIGNUP_MODAL', data })
}

function setLoginModal(data) {
  store.dispatch({ type: 'SET_LOGIN_MODAL', data })
}

function setPasswordModal(data) {
  store.dispatch({ type: 'SET_PASSWORD_MODAL', data })
}

function dismissModals() {
  store.dispatch({ type: 'DISMISS_ALL_MODALS' })
}

export default {
  setActivity,
  setSignupModal,
  setLoginModal,
  setPasswordModal,
  dismissModals,
}
