import { React, View } from 'lib'
import LoginSignupModal from './LoginSignup'

function Modals() {
  return (
    <View style={styles.modals}>
      <LoginSignupModal/>
    </View>
  )
}

const styles = {
  modals: {
    zIndex: 100000,
  },
}

export default Modals
