import { Theme } from 'app'
import { CenterWrapper, Link, React, Text, View, Image } from 'lib'
import facebook from '../../assets/facebook.png'
import instagram from '../../assets/instagram.png'
import linkedin from '../../assets/linkedin.png'
import logoPrimaryBlack from '../../assets/logo_primary_black.png'

const Primary = () => {
  const renderLink = (to, msg) => {
    return (
      <Link style={styles.link} to={to} msg={msg}/>
    )
  }

  const renderSocialLink = (link, source) => {
    return (
      <Link to={link}>
        <Image src={source} style={styles.socialMediaLink}/>
      </Link>
    )
  }

  return (
    <View style={styles.sections}>
      <View>
        <Image src={logoPrimaryBlack} style={styles.appNavLogoBlack} alt={'BRYCK'}/>
      </View>
      <View variant={'flex row justifySpaceBetween alignEnd marginTop:2'}>
        <View variant={'row'}>
          <View variant={'column marginRight:5 selfStart'}>
            {renderLink('/search/buy', 'For Sale')}
            {renderLink('/search/rent', 'To Rent')}
            {renderLink('/construction/find_agent', 'Find An Agent')}
          </View>
          <View variant={'column'}>
            {renderLink('/#', 'About Us')}
            {renderLink('/join/as_agency', 'For Agents')}
            {renderLink('/info/terms/', 'Terms And Conditions')}
          </View>
        </View>
        <View variant={'column alignEnd'}>
          <Text variant={'marginTop:2 black'} msg={'bryck@gmail.co.uk'}/>
          <Text variant={'marginTop:2 black'} msg={'+00 (0) 000 000 0000'}/>
          <Text variant={'marginTop:2 black'} msg={'© 2021 bryck. All rights reserved.'}/>
        </View>
      </View>
      <View variant={'flex row justifySpaceBetween alignCenter marginTop:5 paddingTop:3'} style={styles.border}>
        <Text variant={'black'} msg={'© 2021 bryck. All rights reserved.'}/>
        <View variant={'row alignCenter'}>
          <View variant={'row'}>
            {renderSocialLink('https://www.instagram.com/bryck.uk/', instagram)}
            {renderSocialLink('https://www.facebook.com/bryck-102937384924936/', facebook)}
            {renderSocialLink('https://www.linkedin.com/company/68854778/', linkedin)}
          </View>
        </View>
      </View>
    </View>
  )
}

const Mobile = () => {
  const isSmall = Theme.hooks.down('mid')

  const renderLink = (to, msg, variant) => {
    return (
      <Link style={styles.link} to={to} msg={msg} variant={variant}/>
    )
  }

  const renderSocialLink = (link, source) => {
    return (
      <Link to={link}>
        <Image src={source} style={styles.socialMediaLinkMobile}/>
      </Link>
    )
  }

  return (
    <View style={styles.mobile}>
      <View variant={`${isSmall && 'paddingVertical:4'} flex column alignCenter`}>
        <Image src={logoPrimaryBlack} style={styles.appNavLogoBlack} alt={'BRYCK'}/>
      </View>
      <View variant={'row marginTop:3'}>
        <View variant={'column'} style={styles.links}>
          {renderLink('/search/buy', 'For Sale')}
          {renderLink('/search/rent', 'To Rent')}
          {renderLink('/construction/find_agent', 'Find An Agent')}
        </View>
        <View variant={'column'} style={styles.links}>
          {renderLink('/#', 'About Us', 'p1')}
          {renderLink('/join/as_agency', 'For Agents', 'p1')}
          {renderLink('/info/terms/', 'Terms And Conditions', 'p1')}
        </View>
      </View>
      <View variant={'flex column alignCenter marginTop:3 paddingTop:3'}>
        <Text variant={'black'} msg={'bryck@gmail.co.uk'}/>
        <Text variant={'marginTop:2 black'} msg={'+00 (0) 000 000 0000'}/>
      </View>
      <View variant={'row alignCenter justifyCenter marginTop:3 marginBottom:3'}>
        {renderSocialLink('https://www.instagram.com/bryck.uk/', instagram)}
        {renderSocialLink('https://www.facebook.com/bryck-102937384924936/', facebook)}
        {renderSocialLink('https://www.linkedin.com/company/68854778/', linkedin)}
      </View>
      <View variant={'flex column alignCenter'}>
        <Text variant={'p2'} msg={'© 2021 bryck. All rights reserved.'}/>
      </View>
    </View>
  )
}

export default function Footer() {

  return (
    <>
      <View style={styles.separator}/>
      <View style={styles.wrapper}>
        <CenterWrapper contentContainerStyle={styles.centerWrapperMobile}>
          {!Theme.hooks.is('small')
            ? (<Primary/>)
            : (<Mobile/>)
          }
        </CenterWrapper>
      </View>
    </>
  )
}

const SOCIAL_MEDIA_LINK_SIZE = 20
const SOCIAL_MEDIA_LINK_SIZE_MOBILE = 18

const styles = {
  separator: {
    borderTopStyle: 'solid',
    borderTopColor: `${Theme.colors.light}77`,
    borderTopWidth: '1px',
    margin: '0 auto',
    width: '100%',

    [Theme.media.is('small')]: {
      display: 'none',
    },
    [Theme.media.is('mid')]: {
      width: `calc(100% - 32px)`,
    },
  },
  border: {
    borderTopStyle: 'solid',
    borderTopColor: `${Theme.colors.light}77`,
    borderTopWidth: '1px',
  },
  sections: {
    alignItems: 'flex-end',
  },
  appNavLogoBlack: {
    width: 156,

    [Theme.media.is('mid')]: {
      width: 124,
    },
  },
  socialMediaLink: {
    width: SOCIAL_MEDIA_LINK_SIZE,
    marginRight: Theme.spacing(2),
  },
  socialMediaLinkMobile: {
    margin: '0 4px',
    width: SOCIAL_MEDIA_LINK_SIZE_MOBILE,
  },
  wrapper: {
    bottom: 0,
    display: 'flex',
    color: 'white',
    paddingTop: Theme.spacing(8),
    paddingBottom: Theme.spacing(8),
    position: 'relative',
    backgroundColor: Theme.colors.offwhite,

    [Theme.media.is('small')]: {
      paddingTop: Theme.spacing(2),
      marginTop: Theme.spacing(0),
    },
  },
  appsWrapper: {
    '.footer-appStore': {
      height: 30,
      marginRight: '4px',
    },

    '.footer-googlePlay': {
      height: 33,
    },

    [Theme.media.is('small')]: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: Theme.spacing(2),
      marginBottom: Theme.spacing(2),
    },
    [Theme.media.is('mid')]: {
      '.footer-appStore': {
        height: 26,
      },

      '.footer-googlePlay': {
        height: 29,
      },
    },
  },
  link: {
    color: Theme.colors.black,
    textDecoration: 'none',
    marginTop: Theme.spacing(2),

    [Theme.media.is('small')]: {
      marginBottom: Theme.spacing(2),
      marginTop: 0,
      textAlign: 'center',
    },

  },
  links: {
    width: '50%',

    [Theme.media.is('small')]: {
      alignItems: 'center',
    },
  },
  mobile: {
    width: '100%',
  },
  centerWrapperMobile: {
    [Theme.media.is('small')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}
