import { React, useState, View, Touchable, Text } from 'lib'
import { Theme } from 'app'
import useOnclickOutside from 'react-cool-onclickoutside'

const ButtonDropdown = ({
  children,
  text,
  stylesButton,
  selected,
  onDismiss,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const ref = useOnclickOutside(() => {
    if (showDropdown) {
      onDismiss()
      setShowDropdown(false)
    }
  })

  const handleOnClick = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <View style={styles.wrapper}>
      <Touchable
        style={[styles.button, selected && styles.buttonSelected]}
        onPress={handleOnClick}
      >
        <Text msg={text} variant={`p2 ${selected ? 'darkOrange' : 'black'}`}/>
      </Touchable>
      {showDropdown && (
        <View style={[styles.dropdown, stylesButton]} ref={ref}>
          {children}
        </View>
      )}
    </View>
  )
}

const styles = {
  wrapper: {
    display: 'inline-flex',
    position: 'relative',
    [Theme.media.is('small')]: {
      marginRight: null,
    },
  },
  button: {
    padding: Theme.spacing(1),
    userSelect: 'none',
    height: 19,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Theme.colors.grey,
    borderRadius: Theme.values.borderRadiusSmall,
  },
  buttonSelected: {
    borderColor: Theme.colors.darkOrange,
  },
  dropdown: {
    position: 'absolute',
    top: `calc(100% + ${Theme.spacing(1)}px)`,
    minWidth: 240,
    backgroundColor: Theme.colors.white,
    zIndex: 90,
    ...Theme.borders,
    ...Theme.elevated2x,
  },
}

export default ButtonDropdown
