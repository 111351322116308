import { React, View, useState } from 'lib'
import ReactResizeDetector, { withResizeDetector } from 'react-resize-detector'
import { Theme } from 'app'


function TooltipComponent(props) {
  const { children, content } = props

  const [open, setOpen] = useState(false)

  const tooltipWrapperStyles = [styles.tooltipWrapper, !open && styles.hide, { bottom: props.height }]

  return (
    <View onHover={setOpen} style={styles.wrapper}>
      <View style={tooltipWrapperStyles}>
        <View style={styles.tooltip}>
          {content}
        </View>
      </View>
      <ReactResizeDetector>
        {children}
      </ReactResizeDetector>
    </View>
  )
}

const styles = {
  wrapper: {
    // backgroundColor: 'blue',
    display: 'block',
    position: 'relative',
  },
  tooltipWrapper: {
    position: 'absolute',
    bottom: 0,
    zIndex: 999,
    backgroundColor: 'transparent',
  },
  tooltip: {
    ...Theme.borders,
    backgroundColor: 'white',
    marginBottom: Theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}

export default withResizeDetector(TooltipComponent)
