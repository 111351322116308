import callsites from 'callsites'
import { Theme } from 'app'
import { detect } from 'detect-browser'

const getStyles = (variant, styleSheets, debug = false) => {
  variant = variant && variant.trim().split(' ')
  const result = {}
  for (const s in styleSheets) {
    result[s] = [styleSheets[s].default]
    if (debug) log({ result, styleSheets, 'styleSheets[s]': styleSheets[s] })
    for (const v in variant) {
      if (styleSheets[s][variant[v]]) {
        result[s].push(styleSheets[s][variant[v]])
      } else {
        // let componentName = ''
        try {
          // componentName = ` of <${callsites()[1].getFunctionName()}/>`
        } catch (err) {
          warn(err)
        }
        // COMBAK We should at some point make sure no wrong variants are ever sent
        // However, the warning below would be annoying as fuck at the moment
        // info(`Could not find variant "${variant[v]}" in property "${s}"${componentName}`)
      }
    }
  }
  return result
}


const isLowBrowserCompatibility = () => {
  const browser = detect()
  const platform = browser.os.toLowerCase()

  // log({ browser })

  let acceptable = []

  if (platform.includes('ios')) {
    // ios is okay
    acceptable = ['crios', 'fxios', 'ios']
  } else if (platform.includes('android')) {
    // android sucks
    acceptable = ['chrome']
  } else {
    // desktop is best
    acceptable = ['chrome', 'firefox', 'safari', 'opera', 'edge']
  }

  if (acceptable.includes(browser.name)) {
    return false
  } else {
    return true
  }
}

export {
  getStyles,
  isLowBrowserCompatibility,
}
