import { React, Text, Touchable, View, Input, useState, Button } from 'lib'
import { Theme } from 'app'
import { FiChevronLeft, FiX, FiTrash2 } from 'react-icons/fi'
import moment from 'moment'

export default function NoteDetail({
  note,
  backToNotes,
  deleteNote,
  addEditNote,
}) {
  const [noteDescription, setNoteDescription] = useState(note ? note.content : '')

  const backButton = () => {
    if (noteDescription && note?.content !== noteDescription) {
      const data = {
        id: note ? note.id : null,
        content: noteDescription,
      }
      addEditNote(data)
    }
    backToNotes()
  }

  return (
    <View style={styles.notes}>
      <View variant={'flex row alignCenter justifySpaceBetween marginBottom:2'}>
        {note
          ? (
            <Touchable onPress={() => backToNotes()}>
              <FiChevronLeft size={28} color={Theme.colors.black}/>
            </Touchable>
          )
          : (
            <View>
              <Text variant={'p1 bold'}>Add your note</Text>
            </View>
          )
        }
        <View variant={'row alignCenter'}>
          {note
            ? (
              <>
                <View variant={'marginRight:2'}>
                  <Text text={moment(note.created_datetime, 'MM/DD/YYYY-HH:mm:ss').format('DD/MM/YYYY - HH:mm')} variant={'p2 bold black'}/>
                </View>
                <Touchable onPress={() => deleteNote(note)} variant={'marginRight:1'}>
                  <FiTrash2 size={20} color={Theme.colors.black}/>
                </Touchable>
              </>
            )
            : (
              <Touchable onPress={() => backToNotes()} variant={'marginRight:1'}>
                <FiX size={20} color={Theme.colors.black}/>
              </Touchable>
            )
          }
        </View>
      </View>
      <View style={styles.scrollNotes} variant={'marginTop:4'}>
        <Input
          placeholder={'Description...'}
          value={noteDescription}
          onChangeText={text => setNoteDescription(text)}
          style={styles.touchWrite}
          textStyle={styles.write}
          maxLength={250}
          multiline
        />
        <View style={styles.saveNote}>
          <Button
            msg={'Save'}
            variant={'small orange paddingHorizontal:4'}
            onPress={() => backButton()}
          />
        </View>
      </View>
    </View>
  )
}

const styles = {
  saveNote: {
    position: 'absolute',
    bottom: 20,
    right: 40,
  },
  inputSearch: {
    border: 'none',
  },
  touchWrite: {
    borderBottom: 0,
  },
  write: {
    margin: 0,
  },
  notes: {
    position: 'relative',
    background: '#FEEBDF',
    borderRadius: Theme.values.borderRadiusSmall,

    padding: Theme.spacing(2),
    [Theme.media.is('small')]: {
      marginLeft: Theme.spacing(2),
      marginRight: Theme.spacing(2),
      marginTop: Theme.spacing(2),
      marginBottom: Theme.spacing(2),
    },
  },
  scrollNotes: {
    maxHeight: 500,
    minHeight: 450,
    overflowY: 'auto',

    '& textarea': {
      padding: '0px !important',
    },
  },
}
