// gatsby-browser.js
/* eslint no-restricted-imports: 'off' */
// require('react-devtools') // gatsby can't start with this - remove after startup if needed
import React from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-toastify/dist/ReactToastify.css'
import 'app/stylesheets/css/Toast.css'
import 'app/stylesheets/css/Global.css'

import { store, persistor } from 'actions/reducers'


export const wrapWithProvider = ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
        <ToastContainer/>
      </PersistGate>
    </Provider>
  )
}

export default wrapWithProvider
