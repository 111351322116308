import { React, View, Button, useState, useGoogleMaps } from 'lib'
import { Theme } from 'app'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { FaMinus, FaPlus, FaExpand } from 'react-icons/fa'

const ListingMap = ({
  data = { lat: 51.507351, lng: -0.127758 },
  width,
  height = 360,
}) => {
  const googleMaps = useGoogleMaps()
  const [zoom, setZoom] = useState(16)
  const ELEMENT_ID = 'google-maps-mini'

  const handleZoomInOut = (level) => {
    if (level <= 0 || level >= 18) {
      setZoom(zoom)
    } else {
      setZoom(level)
    }
  }

  const handleRequestFullscreen = () => {
    const element = document.getElementById(ELEMENT_ID).firstChild
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen()
    }
  }

  return (
    <View style={{ ...styles.wrapper, width, height }}>
      <View style={styles.controls}>
        <View style={styles.buttons}>
          <Button
            icon={FaPlus}
            size={12}
            color={Theme.colors.black}
            style={styles.control}
            onPress={() => handleZoomInOut(zoom + 1)}
          />
          <Button
            icon={FaMinus}
            size={12}
            color={Theme.colors.black}
            style={styles.control}
            onPress={() => handleZoomInOut(zoom - 1)}
          />
        </View>

        <Button
          icon={FaExpand}
          size={12}
          color={Theme.colors.primary}
          style={[styles.control, styles.controlFullscreen]}
          onPress={handleRequestFullscreen}
        />
      </View>

      <GoogleMap
        id={ELEMENT_ID}
        mapContainerStyle={{ width, height }}
        center={data}
        zoom={zoom}
        options={{
          disableDefaultUI: true,
          styles: [
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'transit',
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
      >
        <Marker
          icon={{
            url: '/map-pin.svg',
            scaledSize: new googleMaps.Size(40, 40),
          }}
          position={data}
        />
      </GoogleMap>
    </View>
  )
}

const styles = {
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: Theme.values.borderRadius,
    width: '100%',
  },
  controls: {
    ...Theme.absolute,
    left: 26,
    top: 29,
    flexDirection: 'column',
    display: 'flex',
    zIndex: 90,
  },
  buttons: {
    backgroundColor: Theme.colors.white,
    borderRadius: Theme.values.borderRadius,
    ...Theme.elevated,
  },
  control: {
    display: 'flex',
    width: 30,
    height: 30,
    borderRadius: Theme.values.borderRadiusSmall,
    padding: 0,
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: Theme.colors.white,
  },
  controlFullscreen: {
    marginTop: Theme.spacing(2),
    ...Theme.elevated,
  },
}

export default React.memo(ListingMap)
