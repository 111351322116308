import { Text, React, Touchable } from 'lib'

/**
 * @exports <Link/>
 * Very simple Text wrapped with Touchable compnent
 *
 * @prop {string} text ---> Actual content
 * @prop {string} variant ---> Touchable style variants
 *
 */


export default function Link(rawProps) {
  const { to, onPress, children, ...props } = rawProps

  const textProps = {
    ...props,
    variant: `link ${props.variant || ''}`,
  }

  const touchableProps = children && props

  const content = props.text || props.msg ? <Text {...textProps}/> : children

  return (
    <Touchable
      {...touchableProps}
      variant={'plain'}
      to={to}
      onPress={onPress}
    >
      {content}
    </Touchable>
  )
}

