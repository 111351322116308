import { Theme } from 'app'
import { AssetPreview } from 'components'
import { React, Text, useState, View, Touchable } from 'lib'

const ListingAssetGalleryItem = ({ index, item, onOpen, showPreviewPDF }) => {
  const [hover, onHover] = useState(false)
  const valueCaption = item.original_caption ? item.original_caption : item.caption
  if (!item || item?.remove || !item.file) return null

  const isFirst = index == 0

  return (
    <Touchable
      style={[
        styles.assetWrapper,
        isFirst && styles.firstItem,
      ]}
      onHover={onHover}
      onPress={() => onOpen(item.id)}
    >
      <AssetPreview
        item={item}
        showPreviewPDF={showPreviewPDF}
      />
      {valueCaption &&
        <View style={[styles.assetMetaWrapper, !hover && styles.hidden]}>
          <Text variant={'p2 marginRight:1 noWrap'} style={styles.assetMetaText} text={valueCaption}/>
        </View>
      }
    </Touchable>
  )
}

const styles = {
  assetWrapper: {
    ...Theme.center,
    display: 'flex',
    backgroundColor: Theme.colors.light,
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  firstItem: {
    width: 550,
    height: '100%',

    [Theme.media.is('small')]: {
      width: '100%',
    },
  },
  fullItem: {
    width: '100%',
    height: '100%',
  },
  assetPreview: {
    borderRadius: 0,
  },
  assetMetaWrapper: {
    borderRadius: Theme.values.borderRadius,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    bottom: 0,
    right: 0,
    left: 0,
    flex: 1,
    opacity: 1,
    padding: Theme.spacing(1),
    margin: Theme.spacing(1),
    transition: '300ms',
    backgroundColor: '#ffffffcc',
    ...Theme.paddingHorizontal(1.2),
  },
  assetMetaText: {
    color: Theme.colors.black,
    fontWeight: 'bold',
  },
  hidden: {
    opacity: 0,
    transition: '300ms',
  },
}

export default ListingAssetGalleryItem
