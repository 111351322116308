/* eslint no-restricted-imports: 'off' */
import { View, React, useState, Text, onMount, getStyles, Touchable, Image, FileInput, useRef } from 'lib'
import { AvatarStyles, Theme } from 'app'

export default function Avatar(props) {
  const input = useRef(null)
  const [editImage, setEditImage] = useState(false)

  onMount(() => {
    if (props.style) {
      warn('<Avatar/> Sending style as a prop is not supported on Avatar. Use options provided or edit custom component.')
    }
  })

  const styles = getStyles(props.variant, AvatarStyles)
  const profile = props.profile

  const getLetterFromName = (pos) => {
    const name = profile.first_name
    const start = pos
    const end = start + 1
    if (name) {
      return name.substring(start, end)
    } else {
      return null
    }
  }

  const renderImage = () => {
    if (!profile.avatar) {
      const initial = getLetterFromName(0)
      return (
        <View style={[styles.image, props.styleAvatar, { backgroundColor: Theme.colors.offwhite }]}>
          <Text text={initial?.toUpperCase()} style={[styles.text, { color: Theme.colors.lightGrey }]}/>
        </View>
      )
    } else {
      const image = profile.avatar
      return (
        <Image variant={'skeleton'} style={[styles.image, props.styleAvatar]} source={image}/>
      )
    }
  }

  const image = renderImage()
  const canEdit = props.hasOwnProperty('onChange')
  const disabled = !canEdit

  const onPress = () => {
    input.current.click()
  }

  const onFileChange = (data) => {
    props.onChange(data)
  }

  const onHoverImage = () => {
    setEditImage(!editImage)
  }

  return (
    <Touchable
      style={[AvatarStyles.general.wrapper,
        styles.image,
        props.styleAvatar,
        disabled && AvatarStyles.general.disabled,
      ]}
      disabled={disabled}
      onPress={onPress}
      onHover={canEdit && onHoverImage}
    >
      {image}
      {editImage &&
        <>
          <Touchable style={AvatarStyles.general.editImageBubble} onPress={onPress}>
            <Text msg={'EDIT'} variant={'veryBold white underline'}/>
          </Touchable>
          <View style={AvatarStyles.general.editing}/>
        </>
      }
      <View style={AvatarStyles.general.fileInput}>
        <FileInput
          style={AvatarStyles.general.fileInput}
          onChange={onFileChange}
          inputRef={input}
        />
      </View>
    </Touchable>
  )
}
