/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-closing-tag-location */
import { React, Text, View, Image } from 'lib'
import { Theme } from 'app'

import bed from '../../assets/bed.svg'
import couch from '../../assets/couch.png'
import shower from '../../assets/shower.svg'
import area from '../../assets/area.svg'

const ListingFeaturesRow = (props) => {
  const {
    data,
    isCompact = false,
    fullWidth = false,
    miniCard = false,
    hideAgency = false,
  } = props

  // const isSmall = Theme.hooks.down('mid')

  const renderFeature = (icon, n, first, suffix = '') => {
    // const num = n?.[0] ? (n[1] ? `${n[0]}${suffix} - ${n[1]}${suffix}` : `${n[0]}${suffix}`) : null
    const num = `${n}${suffix}`

    if (!n) return null
    return (
      <View style={[styles.featureItem, miniCard && styles.miniCard, first && styles.firstItem]}>
        {!first && <View style={styles.separator}/>}
        <Image source={icon} style={[styles.featureImage, miniCard && styles.featureImageSmall]}/>
        <Text variant={`p2 darkGrey`} text={num}/>
      </View>
    )
  }

  return (
    <View style={[styles.featuresWrapper, fullWidth && styles.featuresWrapperFullWidth]}>
      {renderFeature(bed, data.bedrooms, true)}
      {/* {!isSmall && !isCompact && renderFeature(area, data.floor_area, false, ' sq ft')} */}
      {!hideAgency && renderFeature(couch, data.living_rooms)}
      {renderFeature(shower, data.bathrooms)}
      {!isCompact && renderFeature(area, data.floor_area, false, ' sq. ft.')}
    </View>
  )
}

const DOT_SIZE = 4

const styles = {
  featuresWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  featuresWrapperFullWidth: {
    width: '100%',
  },
  featureItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // borderLeftColor: '#ccc',
    // borderLeftWidth: '1px',
    // borderLeftStyle: 'solid',
    // marginRight: Theme.spacing(1),
    // paddingLeft: Theme.spacing(1),
  },
  miniCard: {
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: Theme.spacing(1),
    paddingLeft: Theme.spacing(1),
  },
  // firstItem: {
  //   borderLeftWidth: 0,
  //   marginLeft: 0,
  //   paddingLeft: 0,
  // },
  featureImage: {
    width: 20,
    height: 20,
    objectFit: 'contain',
    marginRight: Theme.spacing(1),
  },
  featureImageSmall: {
    width: 20,
    height: 20,
    marginRight: Theme.spacing(0),
  },
  separator: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE/2,
    backgroundColor: Theme.colors.light,
    marginRight: Theme.spacing(1),
    marginLeft: Theme.spacing(1),
  },
}

export default ListingFeaturesRow
