import { React, View, Text, Input, useRedux } from 'lib'
import { Theme, Currencies, InputStyles } from 'app'
import { isNumber } from 'lodash'

const PriceRange = ({
  value,
  onChange = () => null,
}) => {
  const me = useRedux('Session.profile')

  const currency = me?.preferences?.currency?.value
  const currencySymbol = Currencies.find(o => o.value === currency)?.symbol

  const staticStyles = InputStyles.staticStyles.default

  const initialValue = value || []

  const handleOnChangeMin = (newValue) => {
    const minValue = Number(newValue)
    const maxValue = initialValue[1]
    onChange([minValue, maxValue])
  }

  const handleOnChangeMax = (newValue) => {
    const minValue = initialValue[0]
    const maxValue = Number(newValue)
    onChange([minValue || 0, maxValue])
  }

  const minValue = isNumber(value?.[0]) ? String(value[0]) : ''
  const maxValue = isNumber(value?.[1]) ? String(value[1]) : ''

  return (
    <View>
      <Text style={staticStyles.label} msg={'Price Range'}/>
      <View style={styles.inputsWrapper}>
        <View variant={'row'}>
          <View variant={'flex justifyCenter alignCenter paddingRight:1'}>
            <Text variant={'bold'} text={currencySymbol}/>
          </View>
          <Input
            style={styles.input}
            textStyle={styles.inputInner}
            type={'number'}
            placeholder={'0'}
            value={minValue}
            onChangeText={handleOnChangeMin}
          />
        </View>
        <View style={styles.separator}/>
        <View variant={'row'}>
          <View variant={'flex justifyCenter alignCenter paddingRight:1'}>
            <Text variant={'bold'} text={currencySymbol}/>
          </View>
          <Input
            style={styles.input}
            textStyle={styles.inputInner}
            type={'number'}
            placeholder={'100.000'}
            value={maxValue}
            onChangeText={handleOnChangeMax}
          />
        </View>
      </View>
    </View>
  )
}

const styles = {
  inputsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 30px 1fr',
    alignItems: 'center',
  },
  input: {
    minWidth: 120,
  },
  inputInner: {
    padding: 0,
    margin: 0,
  },
  separator: {
    width: 5,
    height: 2,
    marginLeft: 12,
    backgroundColor: Theme.colors.lightGrey,
  },
}

export default PriceRange
