import {
  React,
  View,
  Text,
  useRedux,
  onMount,
} from 'lib'
import { Theme } from 'app'
import { ListingCard, SliderComponent } from 'components'
import { Listing } from 'actions'

const ListingShowCase = (props) => {
  const listings = useRedux('Listings')

  const isSmall = Theme.hooks.down('mid')

  const { profile, isAgency, agencyListings, text } = props

  const newListings = Tools.shuffle(isAgency ? agencyListings : listings)

  onMount(() => {
    if (!isAgency && listings.length < 3) {
      Listing.fetch()
    }
  })

  const properties = newListings?.slice(0, 3)

  return (
    <View variant={'marginVertical:5'} style={isSmall && styles.showCaseContainer}>
      <Text variant={`h3 marginBottom:3 ${isSmall && 'marginLeft:2'}`} msg={`${text}`}/>
      <View style={[styles.sliderContainer, styles.slider]} variant={'marginTop:3'} down={'mid'}>
        <SliderComponent
          slidesToShow={1.2}
          infinite={false}
          swipeToSlide={true}
          dots={false}
        >
          {properties.map((listing, index) => {
            return (
              <View style={styles.cardWrapper} key={listing.id}>
                <ListingCard
                  listing={listing}
                  isVertical
                  isFirst={index === 0}
                  isShowCase
                  profile={profile}
                />
              </View>
            )
          })}
        </SliderComponent>
      </View>
      <View style={styles.similarPropertiesGrid} up='mid'>
        {properties.map((listing => (
          <ListingCard
            key={listing.id}
            listing={listing}
            isVertical
            isShowCase
          />
        )))}
      </View>
    </View>
  )
}

const styles = {
  slider: {
    [Theme.media.is('small')]: {
      '.slick-track': {
        display: 'flex',
        gridGap: Theme.spacing(2),
      },
      '.slick-list': {
        margin: 'unset !important',
        padding: 'unset !important',
        overflow: 'unset !important',
      },
    },
  },
  similarPropertiesGrid: {
    display: 'grid',
    gridTemplateColumns: '31% 31% 31%',
    gridGap: '3%',

    [Theme.media.is('small')]: {
      gridTemplateColumns: '90%',
    },
  },
}

export default ListingShowCase
