import { Settings } from 'app'
import { onMount, url } from 'lib'
import callsites from 'callsites'
import queryString from 'query-string'
import { includes, find } from 'lodash'
import { distanceTo } from 'geolocation-utils'
import { getGeocode } from 'use-places-autocomplete'


const modules = {
  copy,
  hexToRgb,
  getMultipartFileUploadFormData,
  scrollToAnchor,
  flatten,
  rejectProps,
  setQueryString,
  getQueryString,
  getIntersection,
  getAssetFiletype,
  ellipsis,
  parseSourceUrl,
  shouldReloadOnMount,
  shouldReload,
  shuffle,
  getAddressFromGeocode,
  getGeocodeName,
  throttle,
  debounce,
  unique,
  getDistance,
}

function copy(string) {
  return JSON.parse(JSON.stringify(string))
}

function ellipsis(string, length = 60) {
  return string?.length > length ? `${string?.substr(0, length)}...` : string
}

function hexToRgb(hex) {
  const result = (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i).exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

function getMultipartFileUploadFormData(data, file, background) {
  info({ data, file })
  const uploadData = {
    data: JSON.stringify({
      ...data,
      type: 'application/json',
    }),
  }

  if (file) {
    uploadData.file = file
  }

  if (file === 'sendNull') {
    uploadData.file = null
  }

  if (background) {
    uploadData.background = background
  }

  const formData = new FormData()
  for (const key in uploadData) {
    formData.append(key, uploadData[key])
  }
  return formData
}

function scrollToAnchor(ref, params = { jump: false, center: false }) {
  const element = document.querySelector('#' + ref)
  log('element', element)
  if (element) {
    let top
    if (params.center) {
      const rect = element.getBoundingClientRect()
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      top = rect.top + (rect.height / 2) - (viewHeight / 2)
    } else {
      top = element.getBoundingClientRect().top
    }
    window.scrollTo({ top: top, behavior: params.jump ? 'auto' : 'smooth' })
  } else {
    warn('No anchor found!')
  }
}

function flatten(object) {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(...Object.keys(o)
        .map(k => {
          if (isNaN(k) || o[k]) {
            return (typeof o[k] === 'object' ?
              _flatten(o[k]) :
              ({ [k]: o[k] }))
          } else {
            // warn('Failed flatten', { k, ok: o[k] })
          }
        },
        ),
      )
    }(object)),
  )
}

function rejectProps(props) {
  let funcName = ''
  try {
    funcName = callsites()[1].getFunctionName()
  } catch (err) {
    // pass
  }
  for (const k in props) {
    if (props[k]) {
      warn(`Prop "${k}" is not supported in <${funcName}/>`)
      // const cs = callsites()
      // for (const c in cs) {
      //   console.log('func', cs[c].getFunctionName())
      // }
    }
  }
}

function setQueryString(query) {
  const a = url()
  const next = queryString.stringifyUrl({ url: a.pathname, query })
  info({ url: a, next })
  window.history.pushState({ path: next }, Settings.WEBSITE_TITLE, next)
}

function getQueryString() {
  try {
    info({ values: queryString.parse(location.search) })
    return queryString.parse(location.search)
  } catch (err) {
    warn(err)
    return {}
  }
}

function getIntersection(arrayA = [], arrayB = []) {
  const intersect = arrayA.filter(item => arrayB.includes(item))
  return intersect
}

function getAssetFiletype(asset) {
  try {
    const imageTypes = ['png', 'jpg', 'jpeg', 'webp', 'image']
    const videoTypes = ['mp4', 'video']
    const pdfTypes = ['pdf']
    let type = ''
    if (asset.file?.type) {
      type = asset.file.type.split('/')
    } else {
      type = [asset.file.split('.').pop()]
    }
    if (getIntersection(type, imageTypes).length) return 'image'
    if (getIntersection(type, videoTypes).length) return 'video'
    if (getIntersection(type, pdfTypes).length) return 'pdf'
  } catch (err) {
    warn(err)
    return null
  }
}

function parseSourceUrl(args) {
  let res = ''
  const address = args.source || args.src || (typeof args == 'string' && args) || null
  if (address && address?.startsWith('/media/')) {
    const tmp = address.substr(1, address.length)
    res = `${Settings.BASE_URL}${tmp}`
  } else if (address) {
    res = address
  } else {
    res = `https://picsum.photos/600?random=${Math.random() * 100}`
  }
  return res
}

function shouldReload() {
  const { reload } = Tools.getQueryString()
  if (reload && reload == 'true') {
    Tools.setQueryString({ reload: undefined })
    location.reload()
  }
}

function shouldReloadOnMount() {
  onMount(() => {
    shouldReload()
  })
}

function shuffle(array) {
  // Makes array random
  let currentIndex = array.length, temporaryValue, randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

const getAddressFromGeocode = (geocodeResults) => {
  const fallbackResponse = { formatted_address: (geocodeResults[0]?.types[0] != 'plus_code' && geocodeResults[0]?.formatted_address) || 'Your Location' }
  try {
    if (geocodeResults.length) {
      const cityFilter = o => includes(o.types, 'administrative_area_level_2') || includes(o.types, 'sublocality')
      const stateFilter = o => includes(o.types, 'administrative_area_level_1')
      const countryFilter = o => includes(o.types, 'country')
      const city = geocodeResults.find(g => g.address_components.find(cityFilter))?.address_components.find(cityFilter)
      const state = geocodeResults.find(g => g.address_components.find(stateFilter))?.address_components.find(stateFilter)
      const country = geocodeResults.find(g => g.address_components.find(countryFilter))?.address_components.find(countryFilter)
      if (city == undefined || state == undefined || country == undefined) return fallbackResponse
      return {
        city: city ? city?.long_name : '',
        state: state.short_name,
        country: country.long_name,
      }
    }
    return fallbackResponse
  } catch (err) {
    return fallbackResponse
  }
}

let throttleTimerId
// Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
function throttle(func, delay) {
  // If setTimeout is already scheduled, no need to do anything
  if (throttleTimerId) {
    return
  }

  // Schedule a setTimeout after delay seconds
  throttleTimerId = setTimeout(function () {
    func()

    // Once setTimeout function execution is finished, throttleTimerId = undefined so that in <br>
    // the next scroll event function execution can be scheduled by the setTimeout
    throttleTimerId = undefined
  }, delay)
}

let debounceTimerId
// debounce function: Input as function which needs to be debounced and delay is the time interval in milliseconds
function debounce(func, delay) {
  // If setTimeout is already scheduled, no need to do anything
  if (debounceTimerId) {
    clearTimeout(debounceTimerId)
  }

  // Schedule a setTimeout after delay seconds
  debounceTimerId = setTimeout(function () {
    func()

    // Once setTimeout function execution is finished, debounceTimerId = undefined so that in <br>
    // the next scroll event function execution can be scheduled by the setTimeout
    debounceTimerId = undefined
  }, delay)
}

function unique(obj, property) {
  const results = []
  for (const o in obj) {
    if (!results.includes(obj[o][property])) {
      results.push(obj[o][property])
    }
  }
  return results
}

function getDistance(start, end) {
  const distance = distanceTo(start, end)
  return distance
}

function getGeocodeName(location, onSuccess = () => null, onFailure = () => null) {
  deb.red('>>> GETTING GEOCODE NAME', { location })
  getGeocode({ location }).then(result => {
    const address = getAddressFromGeocode(result)
    const description = address.formatted_address || `${address.city} ${address.state}, ${address.country}`
    deb.red({ result, address, description })
    onSuccess(description)
  }).catch(err => {
    error({ err })
    onFailure('Your Location')
  })
}

global.Tools = modules

export default modules
