import { React, Text, View, onMount } from 'lib'
import { Theme } from 'app'
import { ListingPrice } from 'components'
import Sticky from 'react-stickynode'

const ListingStickyBanner = (props) => {
  const { currentListing, renderAgencyRow, renderMessageButton } = props

  const isSmall = Theme.hooks.down('mid')

  const currency = currentListing?.display_currency
  const originalCurrency = currentListing?.currency

  const showOriginal = currency !== originalCurrency

  onMount(() => {
    if (!isSmall) {
      props.onLayout(document.getElementById('stickyContent').clientHeight)
    }
  })

  const renderPrices = () => {
    return (
      <View>
        <ListingPrice variant={'h3 black'} listing={currentListing}/>
        {showOriginal && (
          <ListingPrice variant={'p2'} displayOriginal listing={currentListing}/>
        )}
      </View>
    )
  }

  const renderContent = (extraStyles = {}) => {
    return (

      <View
        id={'stickyContent'}
        style={[styles.wrapper, extraStyles, { height: 'auto' }]}
      >
        {renderAgencyRow()}
        <View variant={'flex column justifySpaceBetween'} style={styles.descriptionContainer}>
          <Text variant={'h5'} text={currentListing.title}/>
          <Text variant={'p1 grey'} text={`${currentListing.location?.address}`}/>
          {renderPrices()}
          {renderMessageButton()}
        </View>
      </View>

    )
  }

  return (
    <Sticky
      top={Theme.values.headerMenuHeight}
      bottomBoundary={'#stickyContainer'}
    >
      {status => {
        let extraStyles = {}
        if (status.status != Sticky.STATUS_ORIGINAL) {
          extraStyles = { ...Theme.elevated }
        }
        return (
          <View style={[styles.widthFull, styles.stickyHeaderWrapper]}>
            <View style={[styles.widthFull, styles.stickyHeaderContent]}>
              {renderContent(extraStyles)}
            </View>
          </View>
        )
      }}
    </Sticky>
  )
}


const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: Theme.spacing(3),
    ...Theme.elevated2x,
    borderRadius: Theme.values.borderRadius,
    border: `1px solid ${Theme.colors.light}`,
    overflow: 'hidden',
    transition: '300ms',

    [Theme.media.is('small')]: {
      borderRadius: 0,
      borderTopRightRadius: Theme.values.borderRadius,
      borderTopLeftRadius: Theme.values.borderRadius,
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: 'calc(100% - 32px)',
      background: Theme.colors.white,
      zIndex: 99,
      padding: Theme.spacing(2),
      gridTemplateColumns: 'auto',
      gridTemplateRows: 'auto auto auto auto',
    },
  },
  stickyHeaderWrapper: {
    position: 'absolute',
    right: 0,
    marginTop: Theme.spacing(6),
  },
  widthFull: {
    width: '100%',
  },
  descriptionContainer: {
    gap: Theme.spacing(2),
  },
}

export default ListingStickyBanner
