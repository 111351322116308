/* eslint no-restricted-imports: 'off' */
import { View, useRef } from 'lib'
import { injectIntl } from 'gatsby-plugin-intl'

/** @jsx jsx */
import { jsx } from '@emotion/react'

function FileInput(rawProps) {
  const {
    style,
    value,
    onChange,
    variant,
    type,
    debug,
    inputRef,
    ...props
  } = rawProps

  const input = useRef(null)

  const handleChange = event => {
    if (onChange) {
      const { files } = event.target
      const file = files[0]

      if (file && !props.multiple) {
        const preview = window.URL.createObjectURL(file)
        const result = { file, preview }
        log(result)
        onChange(result)
      } else if (file && props.multiple) {
        const previews = Object.values(files).map(fileObj => window.URL.createObjectURL(fileObj))
        const result = { files: Object.values(files), previews }
        log(result)
        onChange(result)
      }
    }
  }

  const InputElement = 'input'

  if (debug) {
    log({ props, rawProps })
  }

  return (
    <View style={styles.wrapper} variant={variant} debug={debug}>
      <InputElement
        ref={inputRef || input}
        type={type || 'file'}
        css={[styles.input, style]}
        onChange={text => handleChange(text)}
        value={value}
        rows={4}
        {...props}
      />
    </View>
  )
}

const styles = {
  wrapper: {
    display: 'none',
  },
}

export default injectIntl(FileInput)
