import { React, Text } from 'lib'
import { ListingMap } from 'components'

function LocationViewer(props) {
  const { data } = props
  return (
    <>
      <Text variant={`p1 grey marginBottom:2`} text={data.address}/>
      <ListingMap data={data.coords}/>
    </>
  )
}

export default LocationViewer
