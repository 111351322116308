import { Theme } from 'app'
import { Modal, React, View, Input, useState, Validate, Button } from 'lib'

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  contact_number: '',
  is_external: true,
}

const AddContactModal = ({ isOpen, onClose, onSubmit }) => {
  const [state, setState] = useState(initialState)

  let disabled = true
  if (state.first_name !== '' && state.last_name !== '' && state.email !== '' && state.contact_number !== '') {
    disabled = false
  }

  const create = () => {
    onSubmit(state)
  }

  return (
    <Modal
      style={styles.modalWrapper}
      stylesHeader={styles.header}
      titleMsg={'Add contact'}
      open={isOpen}
      onClose={() => onClose()}
    >
      <View>
        <View style={styles.modalContentWrapper}>
          <Input
            labelMsg={'FIRST NAME'}
            value={state.first_name}
            validate={Validate.field}
            onChangeText={first_name => setState({ ...state, first_name })}
          />
          <Input
            labelMsg={'LAST NAME'}
            value={state.last_name}
            validate={Validate.field}
            onChangeText={last_name => setState({ ...state, last_name })}
          />
        </View>
        <View style={styles.modalContentWrapper}>
          <Input
            labelMsg={'EMAIL ADDRESS'}
            value={state.email}
            validate={Validate.email}
            onChangeText={email => setState({ ...state, email })}
          />
          <Input
            labelMsg={'TELEPHONE NUMBER'}
            value={state.contact_number}
            validate={Validate.contact_number}
            onChangeText={contact_number => setState({ ...state, contact_number })}
          />
        </View>
        <Button
          text='SUBMIT'
          onPress={create}
          disabled={disabled}
          variant={'marginTop:6 fullWidth'}
        />
      </View>
    </Modal>
  )
}

const styles = {
  modalWrapper: {
    width: 800,
    overflow: 'hidden',
    [Theme.media.is('small')]: {
      height: '100vh',
      width: '100%',
    },
  },
  modalContentWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '45% 45%',
    rowGap: Theme.spacing(3),
    marginTop: Theme.spacing(8),

    [Theme.media.is('small')]: {
      marginTop: Theme.spacing(2),
      gridTemplateColumns: '100%',
    },
  },
}


export default AddContactModal
