import { React, View, Input } from 'lib'
import { Theme, Settings } from 'app'
import { ListingMap, PlacesSearchInput } from 'components'

function EditorLocation(props) {
  const { data, setData, isListingEditor, defaultValue } = props
  const coords = {
    lat: isListingEditor ? null : Settings.DEFAULT_LOCATION.lat,
    lng: isListingEditor ? null : Settings.DEFAULT_LOCATION.lng,
  }
  return (
    <View>
      <Input
        value={data.location?.title}
        placeholder={'Location name, Ex: Dubai'}
        variant={'pill'}
        onChangeText={(title) => setData(prevData => { return { ...prevData, location: { ...prevData.location, title } } })}
      />
      <PlacesSearchInput
        placeholder={'Address'}
        label={isListingEditor ? '' : 'Search properties'}
        defaultValue={defaultValue}
        variant={'marginTop:4 marginBottom:4 pill'}
        onSelectValue={({ description, lat, lng, postcode }) => {
          setData({
            ...data,
            location: {
              ...data.location,
              coords: {
                lat: lat ? lat : Settings.DEFAULT_LOCATION.lat,
                lng: lng ? lng : Settings.DEFAULT_LOCATION.lng,
              },
              address: description,
              postcode,
            },
          })
        }}
      />
      <View style={styles.map}>
        <ListingMap data={data.location?.coords.lat !== 0 ? data.location?.coords : coords} width={'100%'} height={400}/>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {},
  map: {
    flex: 1,
    height: 400,
    display: 'flex',
    ...Theme.center,
    backgroundColor: Theme.colors.light,
    borderRadius: Theme.values.borderRadius,
  },
}

export default EditorLocation
