import { React, useRef, View, Touchable, Button, Text } from 'lib'
import { Theme } from 'app'
import { AssetPreview, SliderComponent } from 'components'
import { orderBy } from 'lodash'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import './style.css'

const AssetSlider = ({ data = [], initialSlide = 0 }) => {
  const slider = useRef()

  const handleChangeSlide = (index) => {
    slider.current.slickGoTo(index)
  }

  const handleNextSlide = () => {
    slider.current.slickNext()
  }

  const handlePrevSlide = () => {
    slider.current.slickPrev()
  }

  const renderCustomPaging = (index) => (
    <Touchable onClick={() => handleChangeSlide(index)}>
      <AssetPreview
        key={data[index]?.id}
        item={data[index]}
        index={index}
        size={65}
        style={styles.thumbnail}
      />
    </Touchable>
  )

  return (
    <View style={styles.slider}>
      <View style={[styles.arrow, styles.arrowLeft]}>
        <Button
          key={'prev'}
          icon={MdKeyboardArrowLeft}
          onPress={handlePrevSlide}
          size={32}
        />
      </View>

      <View style={[styles.arrow, styles.arrowRight]}>
        <Button
          key={'next'}
          icon={MdKeyboardArrowRight}
          onPress={handleNextSlide}
          size={32}
        />
      </View>

      <SliderComponent
        ref={slider}
        initialSlide={initialSlide}
        customPaging={renderCustomPaging}
        dotsClass={'slick-dots slick-thumb'}
        slidesToShow={1}
        dots
        infinite
      >
        {data.map((item, index) => (
          <View key={item.id}>
            <AssetPreview
              key={item?.id}
              item={item}
              index={index}
              style={styles.imageStyle}
            />
            {item.caption && (
              <View style={styles.captionWrapper}>
                <Text variant={'p2 bold marginRight:1 noWrap'}>{item.caption}</Text>
                <Text variant={'p2 bold marginRight:1 noWrap'}>{`0${index+1}`}</Text>
              </View>
            )}
          </View>
        ))}
      </SliderComponent>
    </View>
  )
}

const VERTICAL_SPACING = 260
const HORIZONTAL_SPACING = 300

const styles = {
  slider: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',

    '.slick-slide > div': {
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      maxHeight: `calc(100vh - ${VERTICAL_SPACING}px)`,

      [Theme.media.down('mid')]: {
        width: '100%',
        height: 'auto',
        maxHeight: 'unset',
      },
    },

    '.slick-slide > div > div': {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      maxWidth: `calc(100vw - ${HORIZONTAL_SPACING}px)`,
      [Theme.media.down('mid')]: {
        maxWidth: `unset`,
      },
    },

    '.slick-slide > div img': {

      [Theme.media.is('small')]: {
        borderRadius: 0,
      },
    },

    '.slick-thumb': {
      position: 'relative',
      display: 'flex !important',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'center',
      bottom: 0,
      marginTop: Theme.spacing(2),
      overflowX: 'auto',
      overflowY: 'hidden',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },

    '.slick-thumb > li': {
      position: 'relative',
      display: 'flex',
      width: 65,
      height: 78,
      alignItems: 'center',
      transition: 'transform 0.2s',
      ...Theme.marginHorizontal(1),

      '&.slick-active': {
        transform: 'scale(1.1)',
        // ...Theme.elevated2x,
      },
    },

    '.slick-thumb li:first-child': {
      marginLeft: Theme.spacing(2),
    },

    '.slick-thumb li:last-child::before': {
      content: `''`,
      position: 'absolute',
      width: Theme.spacing(2),
      height: 1,
      left: '100%',
    },
  },
  captionWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    left: Theme.spacing(5),
    right: Theme.spacing(5),
    bottom: Theme.spacing(2),
    backgroundColor: '#ffffffaa',
    padding: Theme.spacing(1),
    borderRadius: Theme.values.borderRadius,

    [Theme.media.is('small')]: {
      display: 'none',
    },
  },
  thumbnail: {
    width: 65,
    height: 65,
  },
  imageStyle: {
    // width: '100%',
  },
  arrow: {
    ...Theme.absolute,
    top: 0,
    bottom: 94,
    display: 'flex',
    alignItems: 'center',
    zIndex: 99,

    [Theme.media.is('small')]: {
      display: 'none',
    },
  },
  arrowRight: {
    right: 0,
  },
}

export default AssetSlider
