/* eslint no-restricted-imports: 'off' */
import { React, getStyles } from 'lib'
import { TouchableStyles } from '_app'
import { Link as IntlLink } from 'gatsby-plugin-intl'

/** @jsx jsx */
import { jsx } from '@emotion/react'


const Touchable = (props, ref) => {
  const {
    children,
    onPress,
    to,
    disabled,
    style,
    onHover,
    ...otherProps
  } = props
  let { variant } = props

  const handleClick = (event) => {
    if (!disabled) {
      if (onPress) {
        onPress(event)
        try {
          event.stopPropagation()
        } catch {
          log('Failed event.stopPropagation()')
        }
        try {
          event.nativeEvent.stopImmediatePropagation()
        } catch {
          log('Failed event.nativeEvent.stopImmediatePropagation()')
        }
      } else {
        // navigate(to)
        log('Button pressed! No action defined in <Touchable/>')
        // event.stopPropagation()
      }
    } else {
      info('Clicked disabled <Touchable/>')
    }
  }

  const handleHover = (hover) => {
    if (onHover) onHover(hover)
  }

  if (disabled) variant = `${variant} disabled`

  const styles = getStyles(variant, { TouchableStyles })

  const divStyles = [
    styles.TouchableStyles,
    style,
  ]

  const href = to && ['http', 'mail', 'tel:'].includes(to?.substr(0, 4)) ? to : null
  const TouchableElement = href ? 'a' : (to ? IntlLink : 'div')

  return (
    <TouchableElement
      ref={ref}
      to={to}
      href={href}
      css={divStyles}
      onClick={handleClick}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      {...otherProps}
    >
      {children}
    </TouchableElement>
  )
}

const TouchableComponent = React.forwardRef(Touchable)

export default TouchableComponent
