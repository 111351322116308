import { React, Text, Link, View, Touchable } from 'lib'
import { Theme } from 'app'
import { FcGoogle } from 'react-icons/fc'
import { FaFacebook } from 'react-icons/fa'
import { Profile } from 'actions'

const ICON_SIZE = 26

const SocialSigninBar = ({ showMsgSign }) => {
  const openGoogle = () => {
    Profile.tryGoogleLogin()
  }

  const openFacebook = () => {
    Profile.tryFacebookLogin()
  }

  const renderButton = (onPress, isGoogle, text) => {
    return (
      <Touchable
        style={styles.touchable}
        onPress={onPress}
        variant={'row center'}
      >
        {isGoogle ? (
          <FcGoogle size={ICON_SIZE}/>
        ): <FaFacebook color='blue' size={ICON_SIZE}/>}
        <Text variant={`p1 bold ${isGoogle ? 'black' : 'blue'} marginLeft:1`}>{text}</Text>
      </Touchable>
    )
  }

  return (
    <View variant={'block marginTop:4'}>
      <View variant={'row flex alignCenter'}>
        <View style={styles.separator}/>
        <Text variant={'grey'} msg={'OR'}/>
        <View style={styles.separator}/>
      </View>
      <View style={styles.buttons}>
        {renderButton(openGoogle, true, 'Google')}
        {renderButton(openFacebook, false, 'Facebook')}
      </View>
      {showMsgSign &&
        <View variant={`row center marginVertical:2`}>
          <a target='_blank' href='/info/terms'>
            <Link variant={'silent textCenter'} msg={'By signin...'}/>
          </a>
        </View>
      }
    </View>
  )
}

const styles = {
  touchable: {
    flex: 1,
    ...Theme.paddingVertical(1.5),
    borderRadius: Theme.values.borderRadius,
    backgroundColor: 'transparent',
    borderWidth: Theme.values.borderWidth,
    borderStyle: 'solid',
    borderColor: Theme.colors.lightGrey,
    '&:hover': {
      backgroundColor: Theme.colors.white,
    },
    [Theme.media.is('small')]: {
      ...Theme.paddingVertical(1),
    },
  },
  separator: {
    flex: 1,
    height: 1,
    ...Theme.marginHorizontal(4),
    backgroundColor: Theme.colors.light,
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '47% 47%',
    columnGap: '6%',
    marginTop: Theme.spacing(4),
    [Theme.media.is('small')]: {
      gridTemplateColumns: 'auto',
      rowGap: Theme.spacing(2),
      marginTop: Theme.spacing(2),
      ...Theme.marginHorizontal(1),
    },
  },
}

export default SocialSigninBar
