/* eslint no-restricted-imports: 'off' */
const initialState = {
  isLoggedIn: undefined,
  profileId: null,
  profile: {},
  agency: {},
}

export const Session = (state = initialState, action) => {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      newState.isLoggedIn = true
      newState.profileId = action.data.id
      newState.profile = action.data
      return newState
    case 'RECEIVE_MY_AGENCY':
      newState.agency = action.data
      return newState
    case 'LOGOUT':
      newState = initialState
      return newState
    default:
      return state
  }
}
