/* eslint no-restricted-imports: 'off' */
import { AppStatus, Fetch, store } from 'actions'

const MODULE_NAME = 'crm/'

//
// Actions to dispatch
//
function receiveNotes(data) {
  store.dispatch({ type: 'RECEIVE_NOTES', data })
}

async function fetchNotes(params, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + `notes/${params}`,
    method: 'GET',
    successCallback: (res) => {
      receiveNotes(res.result)
      onSuccess(res)
    },
  })
}

async function createNote(data, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + 'notes/',
    method: 'POST',
    data: data,
    successCallback: (res) => {
      AppStatus.setActivity(null)
      receiveNotes(res)
      onSuccess(res)
    },
  })
}

async function updateNote(id, data, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  await Fetch({
    url: MODULE_NAME + `notes/${id}`,
    method: 'PATCH',
    data,
    successCallback: (res) => {
      AppStatus.setActivity(null)
      receiveNotes(res)
      onSuccess(res)
    },
  })
}

async function deleteNote(id, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + `notes/${id}`,
    method: 'DELETE',
    successCallback: (res) => {
      onSuccess(res)
    },
  })
}

export default {
  fetchNotes,
  createNote,
  updateNote,
  deleteNote,
}
