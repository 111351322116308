import { filter, isEmpty } from 'ramda'
import { equals } from 'lib'

function matchLetterToColor(initial) {
  if (!initial) return '#999999'
  switch (initial.toLowerCase()) {
    case 'a':
      return '#7CB9E8'
    case 'b':
      return '#3a9e77'
    case 'c':
      return '#A3C1AD'
    case 'd':
      return '#E1BD27'
    case 'e':
      return '#badc58'
    case 'f':
      return '#db5970'
    case 'g':
      return '#9b8ef1'
    case 'h':
      return '#ffe169'
    case 'i':
      return '#3ea9d1'
    case 'j':
      return '#8aa341'
    case 'k':
      return '#baf2f5'
    case 'l':
      return '#ffa02d'
    case 'm':
      return '#d46830'
    case 'n':
      return '#62ecaa'
    case 'o':
      return '#ffbe50'
    case 'p':
      return '#0078D7'
    case 'q':
      return '#8764B8'
    case 'r':
      return '#52dd64'
    case 's':
      return '#7edce9'
    case 't':
      return '#dadd5d'
    case 'u':
      return '#e9b55d'
    case 'v':
      return '#99d669'
    case 'w':
      return '#a3c83a'
    case 'x':
      return '#f28d67'
    case 'y':
      return '#ea82ec'
    case 'z':
      return '#ff8295'
    default:
      return '#999999'
  }
}

function waitFor(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

function csvJSON(csvString) {
  const lines = filter(n => !isEmpty(n), csvString.split('\n'))
  const result = []

  const headers = lines[0].split(',')

  for (let i = 1; i < lines.length; i++) {
    const obj = {}
    const currentline = lines[i].split(',')

    for (let j=0; j < headers.length; j++) {
      if (currentline[j]) {
        const key = headers[j].trim()
        obj[key] = currentline[j]
      }
    }
    result.push(obj)
  }

  return result
}

function arePropsEqual(prevProps, nextProps, checkProps, debugMode = false) {
  deb.yellow({ prevProps, nextProps, checkProps })
  for (const c in checkProps) {
    const item = checkProps[c]
    const nextItem = nextProps[item]
    const prevItem = prevProps[item]
    if (nextItem?.receivedAt) delete nextItem.receivedAt
    if (prevItem?.receivedAt) delete prevItem.receivedAt
    const res = equals(nextItem, prevItem)
    if (!res) {
      if (debugMode) deb.yellow('Props not equal', { item, nextItem, prevItem, prevProps, nextProps, checkProps })
      return false
    }
  }
  return true
}

function formatNumber(number, curr = 'GBP') {
  return String(new Intl.NumberFormat('en-GB', { style: 'currency', currency: curr, notation: 'standard' }).format(number)).slice(0, -3)
}

function findClosestValue(arr, num) {
  const value = arr.reduce((acc, val) => {
    if (Math.abs(val - num) < Math.abs(acc)) {
      return val - num
    } else {
      return acc
    }
  }, Infinity)

  return Number((value + num).toFixed(2))
}

function canAddAsset(asset, type) {
  if (type === 'media' && (asset.split('/')[0] === 'image' || asset.split('/')[0] === 'video')) {
    return true
  } else if ((type === 'floorplan' || type === 'brochure') && (asset.split('/')[0] === 'image' || asset.split('/')[1] === 'pdf')) {
    return true
  } else {
    return false
  }
}

function nthDate(day) {
  if (day > 3 && day < 21) return 'th'
  switch (day % 10) {
    case 1: return 'st'
    case 2: return 'nd'
    case 3: return 'rd'
    default: return 'th'
  }
}

function asyncCheckLocationPermission() {
  return navigator.permissions.query({ name: 'geolocation' })
}

export default {
  matchLetterToColor,
  waitFor,
  arePropsEqual,
  csvJSON,
  formatNumber,
  findClosestValue,
  canAddAsset,
  nthDate,
  asyncCheckLocationPermission,
}


