import { Theme } from 'app'
import { React, Touchable, View, Text } from 'lib'

const BUTTON_CONTAINER_SIZE = 200
const BUTTON_CONTAINER_HEIGHT = 48

const ToggleSelection = ({
  isEditor,
  isSearch,
  textLeft,
  textRight,
  isLeftSelected,
  stylesOptions,
  ...props
}) => {

  const options = [
    { id: 1, name: textLeft },
    { id: 2, name: textRight },
  ]

  const renderTouchable = (item, index) => {
    const left = index === 0
    return (
      <Touchable
        variant={'row alignCenter justifyCenter'}
        style={[
          styles.option,
          isSearch && styles.borderGrey,
          left ? styles.left : styles.right,
          left && isLeftSelected && styles.selected,
          !left && !isLeftSelected && styles.selected,
          isEditor && styles.borderLight,
          isEditor && left && styles.borderLeftLight,
          isEditor && !left && styles.borderRightLight,
        ]}
        {...props}
      >
        <Text variant={'grey'} style={[
          left && isLeftSelected && styles.textSelected,
          !left && !isLeftSelected && styles.textSelected,
        ]}
        msg={item.name}
        />
      </Touchable>
    )
  }

  return (
    <View variant={`row alignCenter justifyCenter ${isEditor && 'marginBottom:2'}`} style={[styles.options, stylesOptions]}>
      {options.map((item, index) => renderTouchable(item, index))}
    </View>
  )
}

const styles = {
  options: {
    height: BUTTON_CONTAINER_HEIGHT,
    width: BUTTON_CONTAINER_SIZE,
  },
  option: {
    width: '100%',
    height: '100%',
    backgroundColor: Theme.colors.white,
    '&:hover': {
      background: Theme.colors.offwhite,
    },
  },
  borderGrey: {
    border: `1px solid ${Theme.colors.lightGrey}`,
  },
  selected: {
    background: Theme.colors.orange,
    color: Theme.colors.orange,
    borderColor: Theme.colors.orange,
    '&:hover': {
      background: Theme.colors.darkOrange,
    },
  },
  unselected: {
    borderColor: Theme.colors.orange,
  },
  left: {
    borderTopLeftRadius: Theme.values.borderRadiusSmall,
    borderBottomLeftRadius: Theme.values.borderRadiusSmall,
  },
  right: {
    borderTopRightRadius: Theme.values.borderRadiusSmall,
    borderBottomRightRadius: Theme.values.borderRadiusSmall,
  },
  borderLight: {
    borderStyle: 'solid',
    borderTopColor: Theme.colors.light,
    borderBottomColor: Theme.colors.light,
    borderWidth: Theme.values.borderWidth,
  },
  borderLeftLight: {
    borderLeftColor: Theme.colors.light,
    borderRightStyle: 'none',
  },
  borderRightLight: {
    borderRightColor: Theme.colors.light,
    borderLeftStyle: 'none',
  },
  textSelected: {
    color: Theme.colors.white,
  },
}

export default React.memo(ToggleSelection)
