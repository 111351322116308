const fallbackFont = '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif'
const defaultFont = `"Inter",${fallbackFont}`
const altFont = `"Montserrat",${fallbackFont}`
import Media from '../lib/utils/Media.js'

export const Theme = {
  //
  // Static values
  //
  fontFamily: defaultFont,
  boldFontFamily: 'Poppins',
  altFontFamily: altFont,
  breakpoints: {
    small: 0,
    mid: 768,
    large: 1180,
    xlarge: 1366,
  },
  values: {
    spacing: 8,
    headerBannerHeight: 0,
    editorBannerHeight: 68,
    headerModalHeight: 50,
    headerMenuHeight: 78,
    inputHeight: 48,
    buttonHeight: 48,
    maxContentWidth: 1180,
    maxTabletContentWidth: 540,
    borderRadius: 14,
    borderRadiusSmall: 8,
    borderWidth: '1px',
    draggableTab: {
      handleHeight: 80,
      handleOverlap: 60,
      tabTopBound: 110,
    },
    map: {
      controlMargins: 24,
      mobileSearchbarHeight: 50,
    },
  },
  colors: {
    // Shades
    black: '#000000',
    darkest: '#333333',
    darkGrey: '#424242',
    grey: '#878787',
    lightGrey: '#9a9a9a',
    light: '#DEDEDE',
    lightest: '#eaeaea',
    white: '#FFFFFF',
    offwhite: '#F8F8F8',

    // Project colors
    primary: '#2E3C36',
    secondary: '#749888',
    terciary: '#83B39E',
    darkBrown: '#FA904E22',
    green: '#00AC6E',
    greenWhats: '#25D366',
    darkGreen: '#70ff6d33',
    lightBlue: '#e3f7ff',
    lightRed: '#FD6E6E',
    lightOrange: '#FA904E',
    backgroundOrange: '#FA6E1733',
    orange: '#FF7723',
    darkOrange: '#e65800',
  },

  //
  // Methods
  //
  browser: Media.browser,
  media: { ...Media.queries },
  hooks: { ...Media.hooks },
  headerHeight: function () {
    return this.values.headerBannerHeight + this.values.headerMenuHeight
  },
  spacing: function (size) {
    return size * this.values.spacing
  },
  semiCircle: (side) => ({
    width: side,
    height: side,
    borderRadius: Theme.values.borderRadiusSmall,
  }),
  circle: (side) => ({
    width: side,
    height: side,
    borderRadius: '50%',
  }),
  square: (side) => ({
    width: side,
    height: side,
  }),
  paddingHorizontal: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      paddingLeft: size,
      paddingRight: size,
    }
  },
  paddingVertical: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      paddingTop: size,
      paddingBottom: size,
    }
  },
  marginHorizontal: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      marginLeft: size,
      marginRight: size,
    }
  },
  marginVertical: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      marginTop: size,
      marginBottom: size,
    }
  },
  debug: (color = 'red') => ({
    borderColor: color,
    borderWidth: 1,
    borderStyle: 'dotted',
  }),
  borderColor: (color = 'rgba(0, 0, 0, 1)') => ({
    borderTopColor: color,
    borderBottomColor: color,
    borderLeftColor: color,
    borderRightColor: color,
  }),

  //
  // Preset Style Patterns
  //
  // *** Display types ***
  //
  inline: {
    display: 'inline-block',
  },
  block: {
    display: 'block',
  },
  flex: {
    display: 'flex',
    flex: 1,
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  absolute: {
    position: 'absolute',
  },
  hidden: {
    display: 'none',
  },
  relative: {
    position: 'relative',
  },
  //
  // *** Layout ***
  //
  full: {
    width: '100%',
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  fullHeight: {
    width: '100%',
  },
  fullView: {
    width: '100vw',
    height: '100vh',
  },
  fullViewWidth: {
    width: '100vw',
  },
  fullViewHeight: {
    height: '100vh',
  },
  whole: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  centerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  listStyles: {
    overflow: 'auto',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignBaseline: {
    alignItems: 'baseline',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  justifySpaceAround: {
    justifyContent: 'space-around',
  },
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },
  //
  // *** Styles ***
  //
  blur: {
    backdropFilter: 'blur(4px)',
    '-webkit-backdrop-filter': 'blur(4px)',
    transition: '500ms',
  },
  elevated: {
    boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.08)',
  },
  elevated2x: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.16)',
  },
  neumorphism: {
    boxShadow: '10px 10px 20px 0 #AEAEC077, -10px -10px 20px 0 #fff',
  },
  clamp: {
    WebkitLineClamp: '1',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  clamp2x: {
    WebkitLineClamp: '2',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  get borders() {
    return {
      borderWidth: this.values.borderWidth,
      borderRadius: this.values.borderRadius,
      borderColor: this.colors.light,
      borderStyle: 'solid',
    }
  },
}
