import { React, View, Text } from 'lib'
import { Theme } from 'app'

function VideoPreview(props) {
  const { source } = props
  const file = source.preview || Tools.parseSourceUrl(source.file)
  return (
    <View style={styles.wrapper}>
      <video
        playsinline
        src={file}
        autoPlay={true}
        loop={true}
        style={styles.video}
      />
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
    display: 'flex',
  },
  video: {
    flex: 1,
    display: 'flex',
    objectFit: 'cover',
  },
}

export default VideoPreview
