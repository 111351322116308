import { React, View, Modal, useRedux } from 'lib'
import { Theme } from 'app'
import { LoginCard, SignupCard, PasswordCard } from 'components/shared'
import { AppStatus } from 'actions'

function LoginSignupModal() {
  const signup = useRedux(`AppStatus.modal.signup`)
  const login = useRedux(`AppStatus.modal.login`)
  const password = useRedux(`AppStatus.modal.password`)

  return (
    <View style={styles.wrapper}>
      <Modal style={styles.modal} titleMsg={'Log in'} open={login} onClose={() => AppStatus.dismissModals()}>
        <LoginCard/>
      </Modal>
      <Modal style={styles.signup} titleMsg={'Sign up'} open={signup} onClose={() => AppStatus.dismissModals()}>
        <SignupCard/>
      </Modal>
      <Modal style={styles.modal} titleMsg={'Confirm your password'} open={password} onClose={() => AppStatus.dismissModals()}>
        <PasswordCard/>
      </Modal>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
  },
  modal: {
    width: 400,
    maxHeight: '90vh',

    [Theme.media.is('small')]: {
      width: '100%',
      padding: Theme.spacing(1),
      maxHeight: '100vh',
      height: '100vh',
    },
  },
  signup: {
    width: 715,
    maxHeight: '90%',

    [Theme.media.is('small')]: {
      maxWidth: 400,
      width: '90%',
      padding: Theme.spacing(1),
    },
  },
}

export default LoginSignupModal
