import { Fetch } from 'actions'

const MODULE_NAME = 'currency/'

async function fetch(onSuccess = () => null) {
  const url = `${MODULE_NAME}?limit=100`
  await Fetch({
    url,
    method: 'GET',
    successCallback: (res) => {
      onSuccess(res?.results || res)
    },
  })
}

export default {
  fetch,
}
