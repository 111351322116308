import { React, useState, View, Text, FileInput, useRef, Touchable } from 'lib'
import { Theme } from 'app'
import { AiOutlinePlus } from 'react-icons/ai'
import { AssetPreview } from 'components'

export const AddAssetItemPreview = ({ index, addAsset }) => {
  const input = useRef(null)

  function onPress() {
    input.current.click()
  }

  function onFileChange(event) {
    addAsset(event)
  }

  const isFirst = index == 0

  return (
    <Touchable style={styles.asset} variant={'growSmall'} onPress={onPress}>
      <View style={[styles.assetWrapper, isFirst && styles.firstItem]}>
        <FileInput
          style={styles.fileInput}
          onChange={onFileChange}
          inputRef={input}
          multiple
        />
        <AiOutlinePlus size={56} color={Theme.colors.lightGrey}/>
      </View>
    </Touchable>
  )
}

const AssetItemPreview = ({ index, item, showPreviewPDF, openEdit }) => {
  const [hover, onHover] = useState(false)
  const showCaption = ((item.original_caption && item.original_caption !== 'Add a caption') || (item.caption !== 'Add a caption')) && hover
  const valueCaption = item.original_caption ? item.original_caption : item.caption ? item.caption : 'Add a caption'

  if (!item || item?.remove || !item.file) return null

  return (
    <View style={styles.assetWrapperPreview} onHover={onHover}>
      <AssetPreview
        item={item}
        index={index}
        showPreviewPDF={showPreviewPDF}
        style={styles.defaultPreview}
      />
      <Touchable onPress={openEdit} style={[styles.assetMetaWrapper, !showCaption && styles.hidden]}>
        {valueCaption && <Text variant={'p2 marginRight:1 noWrap'} style={styles.assetMetaText} text={valueCaption}/>}
        <Text variant={'p2'} style={styles.assetMetaText} text={index+1}/>
      </Touchable>
    </View>
  )
}

const gridGap = Theme.spacing(2)
const itemSize = 160
const itemSizeSmall = 100
const firstItemSize = (itemSize * 2) + gridGap

const styles = {
  wrapper: {
    ...Theme.center,
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  assetWrapperPreview: {
    width: '100%',
    height: '100%',
    flex: 1,
    flexGrow: 1,
    display: Theme.browser.includes('safari') ? '-webkit-box' : 'block',
    position: 'relative',
    border: `1px solid ${Theme.colors.lightGrey}`,
    borderRadius: Theme.values.borderRadius,
  },
  asset: {
    height: '100%',
  },
  assetWrapper: {
    ...Theme.center,
    display: 'flex',
    backgroundColor: Theme.colors.offwhite,
    border: `1px solid ${Theme.colors.lightGrey}`,
    width: itemSize,
    height: '100%',
    borderRadius: Theme.values.borderRadius,
    position: 'relative',
    overflow: 'hidden',

    [Theme.media.is('small')]: {
      width: itemSizeSmall,
      height: itemSizeSmall,
      marginRight: 8,
      marginTop: 8,
    },
  },
  firstItem: {
    width: firstItemSize,
    height: '100%',

    [Theme.media.is('small')]: {
      width: itemSizeSmall,
      height: itemSizeSmall,
      marginTop: 8,
    },
  },
  assetMetaWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: '#ffffffaa',
    borderRadius: Theme.values.borderRadiusSmall,
    padding: Theme.spacing(1),
    margin: Theme.spacing(1),
    ...Theme.paddingHorizontal(1.2),
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    transition: '300ms',
    opacity: 1,
  },
  removeAssetWrapper: {
    borderBottomLeftRadius: Theme.values.borderRadius,
    backgroundColor: '#ffffffaa',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: Theme.spacing(0.5),
    ...Theme.paddingHorizontal(1.2),
  },
  assetMetaText: {
    color: '#333',
  },
  hidden: {
    opacity: 0,
    transition: '300ms',
  },
  defaultPreview: {
    borderRadius: Theme.values.borderRadius - 1,
  },
}

export default AssetItemPreview
