import { Theme } from 'app'
import { React, View } from 'lib'

const HomeIcon = (props) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 28 26' style={styles.svg} xmlSpace='preserve'>
    <g>
      <g>
        <path fill='#2E3C36' d='M28,13.8c-0.2,0.4-0.5,0.6-0.9,0.6h-2V25c-0.1,0.6-0.6,1-1.1,1h-7c-0.6,0-1-0.4-1-1v-4.8h-3.3V25
          c0,0.6-0.4,1-1,1H4.5c-0.6,0-1-0.4-1-1V14.4H1c-0.4,0-0.8-0.3-0.9-0.6C-0.1,13.4,0,13,0.3,12.7L13.7,0.3c0.4-0.4,1-0.4,1.4,0
          l12.7,12.4C28.1,13,28.2,13.4,28,13.8z'/>
      </g>
    </g>
  </svg>
)

const FavoritesIcon = (props) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 30 30"' style={styles.favorites} xmlSpace='preserve'>
    <path fill='#2E3C36' d='M16.1,6.7c1.5-4,4.7-5.8,7.6-5.4c3.7,0.5,5.8,3.1,6,6.6c0.4,5.4-5.6,13.6-13.6,16.7C7.8,21.8,1.8,13.3,2.3,8
      C2.5,5.3,4.4,1.7,8,1.3C10.8,1,14.9,3,16.1,6.7z'/>
  </svg>
)

const MessagesIcon = (props) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 28 23' style={styles.svg} xmlSpace='preserve'>
    <g>
      <path fillRule='evenodd' clipRule='evenodd' fill='#2E3C36' d='M15.9,0c-6.6,0-12,4.6-12,10.3c0,1.8,0.5,3.4,1.5,4.9c-0.3,0.2-0.5,0.3-0.7,0.4c-0.6,0.4-0.9,0.6-2.9,1.5
      c-0.3,0.1-0.5,0.4-0.6,0.7c-0.1,0.3,0,0.6,0.2,0.9c0.9,1.1,2.2,1.8,3.8,2H6c1.5,0,3-0.5,4.1-1.3c1.7,0.8,3.7,1.3,5.8,1.3
      c6.7,0,12.1-4.6,12.1-10.3C28,4.6,22.6,0,15.9,0z M8.3,8.3c0-0.6,0.4-1,1-1h0.3c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1H9.3
      C8.7,9.3,8.3,8.9,8.3,8.3z M9.8,17.5c0.2,0.1,0.4,0.2,0.5,0.3C10.1,17.7,9.9,17.6,9.8,17.5z M22.5,13.5H9.6c-0.6,0-1-0.4-1-1
      s0.4-1,1-1h12.9c0.6,0,1,0.4,1,1S23.1,13.5,22.5,13.5z M22.5,9.3h-9.6c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h9.6c0.6,0,1,0.4,1,1
      C23.5,8.9,23.1,9.3,22.5,9.3z'/>
      <path fill='none' d='M10.3,17.8c-0.2-0.1-0.4-0.2-0.5-0.3C10,17.6,10.2,17.7,10.3,17.8z'/>
    </g>
  </svg>
)

const SearchIcon = (props) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 28 26' style={styles.svg} xmlSpace='preserve'>
    <g>
      <path fill={props.firstFill ? '#FFFFFF' : '#2E3C36'} d='M25.3,24.6c-0.5,0.6-1.5,0.6-2.2,0L17,18.4c-1.8,1.5-4.1,2.4-6.6,2.4C4.7,20.8,0,16.1,0,10.4
        C0,4.7,4.7,0,10.4,0c5.7,0,10.4,4.7,10.4,10.4c0,2.1-0.6,4.1-1.7,5.8c0,0,0.1,0.1,0.1,0.1l6.2,6.2C26,23,25.9,23.9,25.3,24.6z'/>
    </g>
  </svg>
)

const ListingsIcon = (props) => (
  <svg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7.66406 1H24.9974' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M7.66406 1H24.9974' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M7.66406 9H24.9974' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M7.66406 9H24.9974' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M7.66406 17H24.9974' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M7.66406 17H24.9974' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M1 1H1.01333' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M1 1H1.01333' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M1 9H1.01333' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M1 9H1.01333' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M1 17H1.01333' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M1 17H1.01333' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
)

const DevelopmentsIcon = (props) => (
  <svg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='path-1-inside-1' fill='white'>
      <path d='M15.0769 7.46143C15.0769 6.90914 15.5246 6.46143 16.0769 6.46143H23.7692C24.3215 6.46143 24.7692 6.90914 24.7692 7.46143V28.2691H15.0769V7.46143Z'/>
    </mask>
    <path d='M15.0769 7.46143C15.0769 6.90914 15.5246 6.46143 16.0769 6.46143H23.7692C24.3215 6.46143 24.7692 6.90914 24.7692 7.46143V28.2691H15.0769V7.46143Z' stroke={props.firstFill ? '#FFFFFF' : '#2E3C36'} strokeWidth='4' mask='url(#path-1-inside-1)'/>
    <mask id='path-2-inside-2' fill='white'>
      <path d='M3.23077 1C3.23077 0.447715 3.67849 0 4.23077 0L11.9231 0C12.4754 0 12.9231 0.447715 12.9231 1L12.9231 28L3.23077 28L3.23077 1Z'/>
    </mask>
    <path d='M3.23077 1C3.23077 0.447715 3.67849 0 4.23077 0L11.9231 0C12.4754 0 12.9231 0.447715 12.9231 1L12.9231 28L3.23077 28L3.23077 1Z' stroke={props.firstFill ? '#FFFFFF' : '#2E3C36'} strokeWidth='4' mask='url(#path-2-inside-2)'/>
  </svg>
)

const AgencyIcon = (props) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 26 26' style={styles.svg} xmlSpace='preserve'>
    <g>
      <path fillRule='evenodd' clipRule='evenodd' fill={props.firstFill ? '#FFFFFF' : '#2E3C36'} d='M22.6,0.1L15,0.9c-0.7,0.1-1.3,0.4-1.8,0.9L1.7,13.2c-1.2,1.2-1.2,3.1,0,4.2l6.8,6.8c1.2,1.2,3.1,1.2,4.2,0
        l11.5-11.5c0.5-0.5,0.8-1.1,0.9-1.8l0.8-7.6C26.1,1.5,24.5-0.1,22.6,0.1z M20.7,10.5c-1.4,1.4-3.7,1.4-5.1,0
        c-1.4-1.4-1.4-3.7,0-5.1c1.4-1.4,3.7-1.4,5.1,0C22.1,6.8,22.1,9,20.7,10.5z'/>
      <path fillRule='evenodd' clipRule='evenodd' fill={props.firstFill ? '#FFFFFF' : '#2E3C36'} d='M19.2,9c-0.6,0.6-1.7,0.6-2.3,0s-0.6-1.7,0-2.3c0.6-0.6,1.7-0.6,2.3,0C19.9,7.4,19.9,8.4,19.2,9z'/>
    </g>
  </svg>
)

const DashboardIcon = (props) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
    viewBox='0 0 26 26' style={styles.svg} xmlSpace='preserve'>
    <g>
      <path fill={props.firstFill ? '#FFFFFF' : '#2E3C36'} d='M26,13v11c0,1.1-0.9,2-2,2h-8c-1.1,0-2-0.9-2-2V13c0-1.1,0.9-2,2-2h8C25.1,11,26,11.9,26,13z'/>
      <path fill={props.firstFill ? '#FFFFFF' : '#2E3C36'} d='M12,2v10c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h8C11.1,0,12,0.9,12,2z'/>
      <path fill={props.firstFill ? '#FFFFFF' : '#2E3C36'} d='M26,2v5c0,1.1-0.9,2-2,2h-8c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h8C25.1,0,26,0.9,26,2z'/>
      <path fill={props.firstFill ? '#FFFFFF' : '#2E3C36'} d='M12,18v6c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2v-6c0-1.1,0.9-2,2-2h8C11.1,16,12,16.9,12,18z'/>
    </g>
  </svg>
)

const ContactsIcon = (props) => (
  <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14.6641 27.666C21.8438 27.666 27.6641 21.8457 27.6641 14.666C27.6641 7.48631 21.8438 1.66602 14.6641 1.66602C7.48436 1.66602 1.66406 7.48631 1.66406 14.666C1.66406 21.8457 7.48436 27.666 14.6641 27.666Z' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M14.6641 27.666C21.8438 27.666 27.6641 21.8457 27.6641 14.666C27.6641 7.48631 21.8438 1.66602 14.6641 1.66602C7.48436 1.66602 1.66406 7.48631 1.66406 14.666C1.66406 21.8457 7.48436 27.666 14.6641 27.666Z' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M9.46484 17.2656C9.46484 17.2656 11.4148 19.8656 14.6648 19.8656C17.9148 19.8656 19.8648 17.2656 19.8648 17.2656' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M9.46484 17.2656C9.46484 17.2656 11.4148 19.8656 14.6648 19.8656C17.9148 19.8656 19.8648 17.2656 19.8648 17.2656' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M10.7656 10.7656H10.779' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M10.7656 10.7656H10.779' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M18.5664 10.7656H18.5797' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M18.5664 10.7656H18.5797' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
)

const AccountIcon = (props) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='15' cy='15' r='14' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2'/>
    <circle cx='15' cy='15' r='14' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2'/>
    <path d='M20.9596 21.7031V20.2135C20.9596 19.4234 20.6458 18.6657 20.0871 18.107C19.5284 17.5483 18.7706 17.2344 17.9805 17.2344H12.0221C11.232 17.2344 10.4742 17.5483 9.91555 18.107C9.35684 18.6657 9.04297 19.4234 9.04297 20.2135V21.7031' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M20.9596 21.7031V20.2135C20.9596 19.4234 20.6458 18.6657 20.0871 18.107C19.5284 17.5483 18.7706 17.2344 17.9805 17.2344H12.0221C11.232 17.2344 10.4742 17.5483 9.91555 18.107C9.35684 18.6657 9.04297 19.4234 9.04297 20.2135V21.7031' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M14.9987 14.2552C16.644 14.2552 17.9779 12.9214 17.9779 11.276C17.9779 9.63069 16.644 8.29688 14.9987 8.29688C13.3533 8.29688 12.0195 9.63069 12.0195 11.276C12.0195 12.9214 13.3533 14.2552 14.9987 14.2552Z' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M14.9987 14.2552C16.644 14.2552 17.9779 12.9214 17.9779 11.276C17.9779 9.63069 16.644 8.29688 14.9987 8.29688C13.3533 8.29688 12.0195 9.63069 12.0195 11.276C12.0195 12.9214 13.3533 14.2552 14.9987 14.2552Z' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
)

const MemberIcon = (props) => (
  <svg width='24' height='29' viewBox='0 0 24 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1.33594 18.566C1.33594 18.566 2.63594 17.266 6.53593 17.266C10.4359 17.266 13.0359 19.866 16.9359 19.866C20.8359 19.866 22.1359 18.566 22.1359 18.566V2.96601C22.1359 2.96601 20.8359 4.26601 16.9359 4.26601C13.0359 4.26601 10.4359 1.66602 6.53593 1.66602C2.63594 1.66602 1.33594 2.96601 1.33594 2.96601V18.566Z' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M1.33594 18.566C1.33594 18.566 2.63594 17.266 6.53593 17.266C10.4359 17.266 13.0359 19.866 16.9359 19.866C20.8359 19.866 22.1359 18.566 22.1359 18.566V2.96601C22.1359 2.96601 20.8359 4.26601 16.9359 4.26601C13.0359 4.26601 10.4359 1.66602 6.53593 1.66602C2.63594 1.66602 1.33594 2.96601 1.33594 2.96601V18.566Z' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M1.33594 27.6664V18.5664' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M1.33594 27.6664V18.5664' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
)

const TasksIcon = (props) => (
  <svg width='28' height='26' viewBox='0 0 28 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9 11.6673L13 15.6673L26.3333 2.33398' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M9 11.6673L13 15.6673L26.3333 2.33398' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M25 13V22.3333C25 23.0406 24.719 23.7189 24.219 24.219C23.7189 24.719 23.0406 25 22.3333 25H3.66667C2.95942 25 2.28115 24.719 1.78105 24.219C1.28095 23.7189 1 23.0406 1 22.3333V3.66667C1 2.95942 1.28095 2.28115 1.78105 1.78105C2.28115 1.28095 2.95942 1 3.66667 1H18.3333' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M25 13V22.3333C25 23.0406 24.719 23.7189 24.219 24.219C23.7189 24.719 23.0406 25 22.3333 25H3.66667C2.95942 25 2.28115 24.719 1.78105 24.219C1.28095 23.7189 1 23.0406 1 22.3333V3.66667C1 2.95942 1.28095 2.28115 1.78105 1.78105C2.28115 1.28095 2.95942 1 3.66667 1H18.3333' stroke={props.firstFill ? '#FF7723' : '#5C5C5C'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
)

const MenuIcon = (props) => {

  const returnFirstFill = () => {
    if (props.selected) {
      return '#FFFFFF'
    } else {
      return props.isFill ? '#FFFFFF' : null
    }
  }

  const returnSecondFill = () => {
    if (props.selected) {
      return null
    } else {
      return props.isFill ? null : '#2E3C36'
    }
  }

  switch (props.type) {
    case 'Home':
      return <View onHover={props.onHover}> <HomeIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Favorites':
      return <View onHover={props.onHover}> <FavoritesIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Messages':
      return <View onHover={props.onHover}> <MessagesIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Search':
      return <View onHover={props.onHover}> <SearchIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Listings':
      return <View onHover={props.onHover}> <ListingsIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Developments':
      return <View onHover={props.onHover}> <DevelopmentsIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Agency':
      return <View onHover={props.onHover}> <AgencyIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Dashboard':
      return <View onHover={props.onHover}> <DashboardIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Contacts':
      return <View onHover={props.onHover}> <ContactsIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Team':
      return <View onHover={props.onHover}> <MemberIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    case 'Tasks':
      return <View onHover={props.onHover}><TasksIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/></View>
    default: {
      return <View onHover={props.onHover}> <AccountIcon firstFill={returnFirstFill()} secondFill={returnSecondFill()}/> </View>
    }
  }
}

const styles = {
  svg: {
    height: 25,
    width: 25,
    [Theme.media.is('small')]: {
      height: 20,
      width: 20,
    },
  },
  members: {
    height: 25,
    width: 35,
  },
  contacts: {
    height: 30,
    width: 30,
  },
  favorites: {
    height: 30,
    width: 32,
  },
}

export default MenuIcon
