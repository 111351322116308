import { Theme } from 'app'
import { React, Touchable, View } from 'lib'
import checked from '../../assets/checkedIcon.png'

const Checkbox = ({
  value = null,
  onPress = () => null,
  children,
  disabled = false,
}) => {
  return (
    <View variant={`row`}>
      {value
        ? (
          <Touchable style={styles.touchableActive} onPress={onPress} disabled={disabled}>
            <img src={checked} style={styles.icon}/>
          </Touchable>
        )
        : <Touchable style={styles.touchable} onPress={onPress} disabled={disabled}/>
      }
      {children}
    </View>
  )
}

const styles = {
  touchable: {
    width: Theme.spacing(2.7),
    height: Theme.spacing(2.7),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Theme.colors.light,
    borderRadius: 4,
    transition: 'all .1s ease',
    '&:hover': {
      borderColor: Theme.colors.black,
    },
  },
  touchableActive: {
    width: Theme.spacing(2.7),
    height: Theme.spacing(2.7),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Theme.colors.black,
    borderRadius: 4,
    transition: 'all .1s ease',
    backgroundColor: Theme.colors.black,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '60%',
    objectFit: 'cover',
  },
}

Checkbox.defaultProps = {}

export default Checkbox
