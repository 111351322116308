/* eslint no-restricted-imports: 'off' */
import { getStyles, isLowBrowserCompatibility } from 'lib/utils/StyleTools'
import { Settings, TextStyles } from 'app'
import { injectIntl } from 'gatsby-plugin-intl'

/** @jsx jsx */
import { jsx } from '@emotion/react'


/**
 * @exports <Text/>
 * Standard Text component. Use this for all typography.
 *
 * @prop {string} text ---> Actual content
 * @prop {string} variant ---> Text style variants, h1, p1, etc...
 * @prop {object} style ---> Text styles object
 *
 */


function Text(rawProps) {
  const { onPress, style, text, children, msg, intl, ...props } = rawProps

  Tools.rejectProps({ onPress })

  if (text && Settings.WARN_INTERNATIONALIZATION) {
    warn('Avoid using "text" in the <Text/> component - prefer "msg" for internationalization.', { text })
  }

  const content = msg ? intl.formatMessage({ id: msg }) : (children || text)

  const lowCompatibility = isLowBrowserCompatibility()

  const styles = getStyles(props.variant, TextStyles)

  const textStyles = [
    styles.text,
    lowCompatibility && styles.compatibility,
    style,
  ]

  return (
    <div {...props} style={null} css={textStyles}>
      {content}
    </div>
  )
}

export default injectIntl(Text)
