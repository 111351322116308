/* eslint no-restricted-imports: 'off' */
import { Fetch } from 'actions'

const MODULE_NAME = 'tags/'

//
// Object manipulation
//
async function fetch(tag, onSuccess = () => null) {
  let url = `${MODULE_NAME}?limit=100`
  if (tag && tag != '*') {
    url += `&search=${tag}`
  }
  await Fetch({
    url,
    method: 'GET',
    successCallback: (res) => {
      onSuccess(res?.results || res)
    },
  })
}

export default {
  fetch,
}
