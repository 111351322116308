import { View } from 'lib'
import ReactList from 'react-list'

/** @jsx jsx */
import { jsx } from '@emotion/react'

function List(props) {
  const { renderItem, data, style, variant, ...listProps } = props

  function itemRenderer(index) {
    const itemData = data[index]
    return renderItem(itemData, index)
  }

  const variants = `${variant} listStyles`

  return (
    <View style={style} variant={variants}>
      <ReactList
        itemRenderer={itemRenderer}
        length={data.length}
        {...listProps}
      />
    </View>
  )
}

export default List
