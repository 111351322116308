import { React, url, navigate, onUpdate, useRedux, Cookies } from 'lib'
import { RestrictedPaths, HubspotExcludedPaths } from 'app'
import { Router as ReachRouter } from '@reach/router'
import { some, startsWith, includes } from 'lodash'
import { Helmet } from 'react-helmet'

const loggedInCookie = Cookies.get('Session.isLoggedIn') == 'true'

/** @jsx jsx */
import { jsx } from '@emotion/react'

function Router(props) {
  const profile = useRedux('Session.profile')

  const { children, style, basePath } = props
  const base = `/:language${basePath}`

  const { pathname } = url()
  const defaultPath = Array.isArray(children)
    ? children.find(c => c.props.default)?.props.path
    : children.props.default && children.props.path

  const matchRoleWithPath = () => {
    switch (profile.type) {
      case 'agency_owner':
        return startsWith(pathname.substring(3), '/agency')
      case 'agency':
        return startsWith(pathname.substring(3), '/agency')
      case 'developer':
        return startsWith(pathname.substring(3), '/developer')
      case 'customer':
        return startsWith(pathname.substring(3), '/user')
      default:
        return false
    }
  }

  const isRoot = pathname.endsWith(basePath)
  const hasRestrictions = some(RestrictedPaths, path => startsWith(path, pathname.substring(3)))
  const isRestricted = hasRestrictions && !matchRoleWithPath() && !pathname.includes('listing')

  const shouldHideHubspot = Boolean(HubspotExcludedPaths.find(v => v.includes(`/${pathname.split('/')[2]}`, v)))

  onUpdate(() => {
    if (!loggedInCookie && !profile?.id && isRestricted) {
      navigate('/')
    }
  }, [profile, isRestricted, pathname])

  if (isRoot) {
    navigate(`${pathname.slice(3)}${defaultPath}`)
  }

  return (
    <React.Fragment>
      <Helmet>
        {shouldHideHubspot && (
          <style type='text/css'>
            {`
              #hubspot-messages-iframe-container {
                z-index: -9999 !important
              }
            `}
          </style>
        )}
      </Helmet>
      <ReachRouter css={style} basepath={base}>
        {children}
      </ReachRouter>
    </React.Fragment>
  )
}

export default Router
