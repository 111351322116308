function flatten(props) {
  if (!props) return {}
  const isArray = Array.isArray(props)
  let result = {}
  if (isArray) {
    for (const i in props) {
      result = { ...result, ...props[i] }
    }
  } else {
    result = props
  }
  return result
}

export default {
  flatten,
}
