import { React, View, Select, Text, useState, onMount } from 'lib'
import { Theme } from 'app'
import { Currency } from 'actions'

function ProfilePreferencesComponent({ profile, setProfileData }) {

  const [currencies, setCurrencies] = useState([])

  onMount(() => {
    Currency.fetch(setCurrencies)
  })

  const currencyOptions = currencies.map((currency) => { return { value: currency.name, label: `${currency.name} - ${currency.description}` } })
  const currencyValue = profile?.currency && currencyOptions.find(i => i.value === profile?.currency)

  return (
    <View style={styles.wrapper}>
      <View style={styles.selectWrapper}>
        {/* <Text variant={`h3 marginBottom:2`} msg={`Currency`}/> */}
        <Select
          labelMsg={`Currency`}
          isSearchable={true}
          options={currencyOptions}
          onChange={(val) => setProfileData({ ...profile, currency: val.value })}
          value={currencyValue}
        />
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: Theme.spacing(5),
    [Theme.media.is('small')]: {
      gridTemplateColumns: '100%',
    },
  },
  selectWrapper: {
    [Theme.media.is('small')]: {
      maxWidth: '100%',
    },
  },
}

export default ProfilePreferencesComponent
