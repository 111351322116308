import { default as ReactTilt } from 'react-tilt'

/** @jsx jsx */
import { jsx } from '@emotion/react'

function Tilt(props) {
  const { children } = props
  return (
    <ReactTilt
      options={{ max: 2, scale: 1.03 }}
      {...props}
      style={null}
      css={props.style}
    >
      {children}
    </ReactTilt>
  )
}

export default Tilt
