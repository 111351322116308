/* eslint-disable */
const WEBSITE_TITLE = 'Brick - Worldwide Properties'

const BRYCK = 'bryck'

const IS_DEVELOPMENT = process.env.NODE_ENV !== 'production'
global.__DEV__ = IS_DEVELOPMENT
const IS_BROWSER = typeof window !== 'undefined'

const LOG_LEVEL = 'log' // 'log' (default), 'info' (shows everything) or 'warn' (only shows warnings)
const REMOTE_DEBUGGER = (typeof atob !== 'undefined') // true if remote debugger is available so the logger knows what to do
const WARN_ILLEGAL_STATE_MUTATIONS = false // can have a performance impact, enable only when necessary - automatically disabled in production
const WARN_WHY_DID_YOU_RENDER = false // can have a performance impact, enable only when necessary - automatically disabled in production
const WARN_INTERNATIONALIZATION = false // warn if you use text directly instead of using msg

// Detects local development envs
const LOCAL_DEV_HOSTS = [
  '192.168.1.81', // 23 Oppidans Road
  // 'victors-mbp',
  //'localhost',
]

const hostname = IS_BROWSER && window?.location?.hostname
const port = '8000'
const USE_LOCAL = hostname && LOCAL_DEV_HOSTS.includes(hostname)

// urls
const REMOTE_URL = 'https://dev.bryck.codeleap.co.uk/'
const WSS_URL = 'wss://dev.bryck.codeleap.co.uk/ws/'
const PRIVACY_POLICY_URL = 'https://codeleap.co.uk/dev/policies/?app=QnJ5Y2s='

const BASE_URL = USE_LOCAL ? `http://${hostname}:${port}/` : REMOTE_URL

const CONTACT_EMAIL = 'hello@codeleap.co.uk'
const SUPPORT_EMAIL = 'support@codeleap.co.uk'
const CONTACT_PHONE = '+44 (0) 333 050 9420'

const SENTRY_DSN = 'https://3124adfba7304b2f97993a5b61e157c2@o309198.ingest.sentry.io/5248030'

const GOOGLE_MAPS_API_KEY = 'AIzaSyDUkMfMgXYYRgoSKMJGavOEFtWsgyfrpyM'
const GOOGLE_ANALYTICS_ID = null
const MAX_OBJECT_AGE = 30 // in seconds, will force fetch if object is older

const OXR_API_KEY = '31a2baa6557d43d182bd98cce9d3fb84'

const DEFAULT_LOCATION = {
  description: 'Dubai - United Arab Emirates',
  distance: 17,
  lat: 25.204849299999992,
  lng: 55.270782800000006,
  zoom: 10,
}

const SEARCH_MAP_OPTIONS = {
  disableDefaultUI: true,
  disablePanMomentum: true,
  styles: [
    {
      featureType: 'poi.business',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
  ],
}

export default {
  LOG_LEVEL,
  REMOTE_DEBUGGER,
  WARN_ILLEGAL_STATE_MUTATIONS,
  WARN_WHY_DID_YOU_RENDER,
  CONTACT_EMAIL,
  SUPPORT_EMAIL,
  CONTACT_PHONE,
  BASE_URL,
  WSS_URL,
  SENTRY_DSN,
  IS_DEVELOPMENT,
  GOOGLE_ANALYTICS_ID,
  IS_BROWSER,
  WEBSITE_TITLE,
  WARN_INTERNATIONALIZATION,
  PRIVACY_POLICY_URL,
  MAX_OBJECT_AGE,
  GOOGLE_MAPS_API_KEY,
  OXR_API_KEY,
  BRYCK,
  SEARCH_MAP_OPTIONS,
  DEFAULT_LOCATION
}
