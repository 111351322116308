import { View, CenterWrapper, SEO, React, useState, onMount, Cookies, useRedux } from 'lib'
import { Theme } from 'app'
import { Footer, HeaderNavBar, Modals, Overlays } from 'navigation'
import { Spinner } from 'components'

const loggedInCookie = Cookies.get('Session.isLoggedIn') == 'true'

function Page(props) {
  const { fullWidth, shouldHideHeader, titleMsg, useTransparentHeader = false, fullHeader = false, noFooter = false, flat = false, float = false, noSpacing = false, showArrow = false, ...otherProps } = props
  const Wrapper = fullWidth ? React.Fragment : CenterWrapper
  const [mounted, setMounted] = useState(false)
  const profile = useRedux('Session.profile')

  onMount(() => {
    setMounted(true)
  })

  const isDownLarge = Theme.hooks.down('large')
  const hideHeader = shouldHideHeader && isDownLarge

  const renderLoading = () => {
    return (
      <View variant={'flex center fullView'}>
        <Spinner/>
      </View>
    )
  }

  if (loggedInCookie && !profile?.id) {
    return renderLoading()
  }

  return (
    <View style={styles.wrapper} key={mounted}>
      <SEO/>
      <HeaderNavBar showArrow={showArrow} shouldHideHeader={shouldHideHeader} fullHeader={fullHeader} titleMsg={titleMsg} hideHeader={hideHeader} useTransparency={useTransparentHeader} flat={flat} float={float}/>
      <Wrapper>
        <View
          {...otherProps}
          style={[
            styles.content,
            hideHeader && styles.contentNoHeader,
            useTransparentHeader && styles.contentNoHeader,
            !useTransparentHeader && !flat && !noSpacing && styles.contentSpacing,
            props.style,
          ]}
        >
          {props.children}
        </View>
      </Wrapper>
      {!noFooter && <Footer/>}
      <Modals/>
      <Overlays/>
    </View>
  )
}

// Page.whyDidYouRender = Settings.WARN_WHY_DID_YOU_RENDER

const styles = {
  wrapper: {
    backgroundColor: Theme.colors.white,
    minHeight: '100vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    marginTop: Theme.headerHeight(),
  },
  contentNoHeader: {
    marginTop: 0,
  },
  contentSpacing: {
    marginTop: Theme.headerHeight() + Theme.spacing(4),
  },
}

export default Page
