import { React, View, Input, Validate } from 'lib'
import { Theme } from 'app'

const ProviderEditComponent = ({ provider, setProviderData }) => {

  const isSmall = Theme.hooks.down('mid')

  const renderInput = (label, providerName, validate, multiline) => {
    return (
      <Input
        labelMsg={label}
        variant={`marginTop:1 pill`}
        value={provider[providerName]}
        onChangeText={val => setProviderData({ ...provider, [providerName]: val })}
        validate={Validate[validate]}
        multiline={multiline}
      />
    )
  }

  return (
    <View>
      <View style={styles.inputWrapper}>
        {renderInput('Company Name', 'name', 'field')}
      </View>
      <View style={[styles.wrapper, styles.inputWrapper]}>
        <View>
          {renderInput('Contact Number', 'phone', 'contact_number')}
        </View>
        <View variant={`${isSmall && 'marginTop:2'}`}>
          {renderInput('Email Address', 'email', 'email')}
        </View>
      </View>
      <View style={styles.inputWrapper}>
        {renderInput('Description', 'description', 'field', true)}
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: Theme.spacing(5),

    [Theme.media.is('small')]: {
      gridTemplateColumns: '100%',
    },
  },
  inputWrapper: {
    marginTop: Theme.spacing(5),

    [Theme.media.is('small')]: {
      marginTop: Theme.spacing(3),
    },
  },
}

export default ProviderEditComponent
