/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-closing-tag-location */
import { React, Text, View, onMount, onUpdate, useState } from 'lib'
import { Theme } from 'app'
import Sticky from 'react-stickynode'
import lighting from '../../assets/lighting.svg'

function EditorStickyBanner(props) {
  const { visibility } = props
  const media = Theme.hooks.media()

  const [nearTop, setNearTop] = useState(true)

  onMount(() => {
    props.onLayout(document.getElementById('stickyContent')?.clientHeight)
  })

  const handleOnScroll = () => {
    const { scrollTop } = document.scrollingElement
    setNearTop(scrollTop < 5)
  }

  onMount(() => {
    window.addEventListener('scroll', handleOnScroll)
    return () => {
      window.removeEventListener('scroll', handleOnScroll)
    }
  })

  function findHovered(hovered) {
    return hovered.isHovered === true
  }

  const hoveredIndex = Object.values(visibility).findIndex(findHovered)

  const visible = Object.values(visibility).filter(o => o.isVisible)
  const averageActiveIndex = nearTop ? 0 : Math.round(visible.reduce((a, b) => a + b.index || 0, 0) / visible.length)

  const displayIndex = hoveredIndex > 0 ? hoveredIndex : averageActiveIndex

  const activeContentTitleMsg = visibility[displayIndex]?.stickyBoxTitleMsg
  const activeContentBodyMsg = visibility[displayIndex]?.stickyBoxBodyMsg

  const renderContent = (extraStyles = {}) => {
    return (
      <View style={[styles.wrapper, extraStyles]} id={'stickyContent'}>
        <img style={styles.light} src={lighting}/>
        <Text variant={'veryBold h3 marginBottom:2 marginTop:2 center'} msg={activeContentTitleMsg}/>
        <Text variant={'p1'} msg={activeContentBodyMsg}/>
      </View>
    )
  }

  if (!activeContentTitleMsg && !activeContentBodyMsg) return null

  if (media == 'small') {
    return renderContent()
  } else {
    return (
      <Sticky
        top={Theme.values.headerMenuHeight + Theme.values.editorBannerHeight}
        bottomBoundary={'#stickyContainer'}
      >
        {status => {
          let extraStyles = {}
          if (status.status != Sticky.STATUS_ORIGINAL) {
            extraStyles = { ...Theme.elevated }
          }
          return (
            <View style={styles.stickyHeaderWrapper}>
              <View style={styles.stickyHeaderContent}>
                {renderContent(extraStyles)}
              </View>
            </View>
          )
        }}
      </Sticky>
    )
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: Theme.spacing(4),
    ...Theme.elevated,
    borderRadius: Theme.values.borderRadius,
    transition: '300ms',
    textAlign: 'center',
    alignItems: 'center',

    [Theme.media.is('small')]: {
      padding: Theme.spacing(2),
      gridTemplateColumns: 'auto',
      gridTemplateRows: 'auto auto auto auto',
    },
  },
  light: {
    width: Theme.spacing(4),
  },
  stickyHeaderWrapper: {
    position: 'absolute',
    width: '90%',
    right: 0,
    marginTop: Theme.spacing(6),
  },
  stickyHeaderContent: {
    width: '100%',
  },
}

export default EditorStickyBanner
