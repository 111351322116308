/* eslint no-restricted-imports: 'off' */
import { useRedux, Toast, url as parseUrl } from 'lib'
import { Fetch, store, ReduxTools, AppStatus } from 'actions'
import queryString from 'query-string'
import { isObject, pickBy, identity } from 'lodash'
import { Settings } from 'app'

const MODULE_NAME = 'listing/'

//
// Actions to dispatch
//
function receiveListings(data) {
  store.dispatch({ type: 'RECEIVE_LISTINGS', data })
}

//
// Object manipulation
//
async function fetch(params, onSuccess = () => null) {
  // if (isObject(params)) {
  //   const paramsToQuery = pickBy(params, identity)
  //   params = `?${queryString.stringify(paramsToQuery)}`
  // }
  if (!params || params === '*') params = ''

  const full_url = params?.startsWith('http')
  if (full_url && params?.startsWith('http') && !params?.startsWith('https')) {
    params = params.replace('http', 'https')
  }

  const profile = store.getState().Session?.profile
  const isLoggedIn = store.getState().Session?.isLoggedIn
  const currency = profile?.currency

  const url = full_url ? params : MODULE_NAME + params

  let query = queryString.parse(url)
  query.currency = currency
  if (!query.distance) query.distance = Settings.DEFAULT_LOCATION.distance
  query = queryString.stringify(query)

  log({ query })
  // url = query

  // const url = full_url ? params : MODULE_NAME + params +`${currency ? `?currency=${currency}` : ''}`
  deb.blue('Listing.fetch request', { url, currency, profile, isLoggedIn })
  await Fetch({
    url,
    method: 'GET',
    options: {
      noToken: true,
      full_url,
    },
    successCallback: (data) => {
      const result = data?.results || [data]
      deb.blue('Listing.fetch results', { url, data })
      receiveListings(result)
      onSuccess(data)

      AppStatus.setActivity(null)
    },
  })
}

async function fetchFromAgency(id, onSuccess = () => null) {
  if (!id) warn('Missing id property')
  await fetch(`?agency=${id}`, data => onSuccess(data))
}

async function fetchMine(params, onSuccess = () => null) {
  if (!params || params === '*') params = ''
  const full_url = params?.startsWith('http')
  if (full_url && params?.startsWith('http') && !params?.startsWith('https')) {
    params = params.replace('http', 'https')
  }
  const url = full_url ? params : MODULE_NAME + params
  deb.blue('Listing.fetch request', { url })
  await Fetch({
    url,
    method: 'GET',
    options: {
      full_url,
    },
    successCallback: (data) => {
      const result = data?.results || [data]
      deb.blue('Listing.fetch results', { data })
      receiveListings(result)
      onSuccess(data)
      AppStatus.setActivity(null)
    },
  })
}

async function fetchMinePages(url, params, onSuccess = () => null) {
  if (isObject(params)) {
    const paramsToQuery = pickBy(params, identity)
    params = `${queryString.stringify(paramsToQuery)}`
  }

  if (!params || params === '*') params = ''

  await Fetch({
    url: `${url}` + params,
    method: 'GET',
    successCallback: (data) => {
      const result = data?.results || [data]
      receiveListings(result)
      onSuccess(data)
      AppStatus.setActivity(null)
    },
  })
}

async function create(data, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  await Fetch({
    url: MODULE_NAME,
    method: 'POST',
    data: data,
    successCallback: (res) => {
      receiveListings(res)
      onSuccess(res)
      log(res)
    },
  })
}

async function save(data, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  deb.yellow('Save listing', { data })
  await Fetch({
    url: MODULE_NAME + data.id,
    method: 'PATCH',
    data: data,
    successCallback: (listing) => {
      log(listing)
      receiveListings(listing)
      onSuccess(listing)
    },
  })
}

async function approve(id, onSuccess = () => null) {
  AppStatus.setActivity('loading')
  await Fetch({
    url: `${MODULE_NAME}approve`,
    method: 'POST',
    data: {
      id,
    },
    successCallback: (listing) => {
      receiveListings([listing])
      AppStatus.setActivity('done')
      Toast.success('Listing published')
      onSuccess(listing)
    },
  })
}

async function remove(id, onSuccess = () => null) {
  await Fetch({
    url: MODULE_NAME + id,
    method: 'DELETE',
    successCallback: (res) => {
      onSuccess(res)
    },
  })
}

async function addMedia(input, onSuccess = () => null) {
  await addAsset(input, 'media', onSuccess)
}

async function editMedia(input, onSuccess = () => null) {
  await editAsset(input, 'media', onSuccess)
}

async function removeMedia(input, onSuccess = () => null) {
  await removeAsset(input, 'media', onSuccess)
}

async function addFloorplan(input, onSuccess = () => null) {
  await addAsset(input, 'floorplan', onSuccess)
}

async function editFloorplan(input, onSuccess = () => null) {
  await editAsset(input, 'floorplan', onSuccess)
}

async function removeFloorplan(input, onSuccess = () => null) {
  await removeAsset(input, 'floorplan', onSuccess)
}

async function addBrochure(input, onSuccess = () => null) {
  await addAsset(input, 'brochure', onSuccess)
}

async function editBrochure(input, onSuccess = () => null) {
  await editAsset(input, 'brochure', onSuccess)
}

async function removeBrochure(input, onSuccess = () => null) {
  await removeAsset(input, 'brochure', onSuccess)
}

async function addAsset({ data, file }, name, onSuccess = () => null) {
  let sendFile = 'sendNull'
  if (file) {
    sendFile = file
  }
  const uploadData = Tools.getMultipartFileUploadFormData(data, sendFile)
  info({ data, file, uploadData: JSON.stringify(uploadData) })
  await Fetch({
    url: MODULE_NAME + name,
    method: 'POST',
    data: uploadData,
    options: {
      multipart: true,
      json: true,
    },
    successCallback: (asset) => {
      onSuccess(asset)
    },
  })
}

async function editAsset({ data, file }, name, onSuccess = () => null) {
  let sendFile = 'sendNull'
  if (file) {
    sendFile = file
  }
  const uploadData = Tools.getMultipartFileUploadFormData(data, sendFile)
  info({ data, file, uploadData: JSON.stringify(uploadData) })
  await Fetch({
    url: `${MODULE_NAME}${name}/${data.id}`,
    method: 'PATCH',
    data: uploadData,
    options: {
      multipart: true,
      json: true,
    },
    successCallback: (asset) => {
      onSuccess(asset)
    },
  })
}

async function removeAsset(id, name, onSuccess = () => null) {
  info({ id, name })
  await Fetch({
    url: `${MODULE_NAME}${name}/${id}`,
    method: 'DELETE',
    options: {
      multipart: true,
      json: false,
    },
    successCallback: (res) => {
      onSuccess(res)
    },
  })
}


export default {
  create,
  save,
  fetchFromAgency,
  fetch,
  fetchMine,
  fetchMinePages,
  remove,
  approve,
  addMedia,
  editMedia,
  removeMedia,
  addFloorplan,
  editFloorplan,
  removeFloorplan,
  addBrochure,
  editBrochure,
  removeBrochure,
}
