import { React, View, Input, Validate } from 'lib'
import { Theme } from 'app'

const ProfileEditComponent = ({ profile, setProfileData, setFileProp }) => {

  const isSmall = Theme.hooks.media() === 'small'

  return (
    <View variant={!isSmall && 'marginTop:5'} style={styles.wrapper}>
      <View variant={'marginTop:2'}>
        <Input
          labelMsg={'Email Address'}
          variant={`marginTop:1 pill`}
          value={profile.email}
          onChangeText={email => setProfileData({ ...profile, email })}
          validate={Validate.email}
        />
      </View>
      <View variant={'marginTop:2'}>
        <Input
          labelMsg={'First Name'}
          variant={`marginTop:1 pill`}
          value={profile.first_name}
          onChangeText={first_name => setProfileData({ ...profile, first_name })}
          validate={Validate.field}
        />
      </View>
      <View variant={'marginTop:2'}>
        <Input
          labelMsg={'Last Name'}
          variant={`marginTop:1 pill`}
          value={profile.last_name}
          onChangeText={last_name => setProfileData({ ...profile, last_name })}
          validate={Validate.field}
        />
      </View>
      <View variant={'marginTop:2'}>
        <Input
          labelMsg={'Contact Number'}
          variant={`marginTop:1 pill`}
          value={profile.contact_number}
          onChangeText={contact_number => setProfileData({ ...profile, contact_number })}
          validate={Validate.contact_number}
        />
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: Theme.spacing(5),

    [Theme.media.down('mid')]: {
      gridTemplateColumns: 'auto',
      flexDirection: 'column',
    },
  },
}

export default ProfileEditComponent
