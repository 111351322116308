import { React, useState, Image, Text, View, Touchable, Toast, Misc, onUpdate } from 'lib'
import { Theme } from 'app'
import { Avatar } from 'components'
import { Listing, AppStatus } from 'actions'


export default function ContactInfo({
  contact,
  profile,
  listingParam,
}) {
  const [listing, setListing] = useState(null)

  onUpdate(() => {
    log(contact)
    const listingId = contact?.customer?.interested_in || listingParam
    if (listingId) {
      Listing.fetch(listingId, setListing)
    }
  }, [contact, listingParam])

  const isCustomer = profile?.type === 'customer'

  const avatar = {
    avatar: isCustomer ? contact?.agency?.logo : contact?.customer?.avatar,
    first_name: isCustomer ? contact?.agency?.name : contact?.customer?.first_name,
    email: isCustomer ? contact?.agency?.email : contact?.customer?.email,
  }

  const name = Tools.ellipsis(avatar.first_name, 25)
  const email = Tools.ellipsis(avatar.email, 25)

  const displayName = isCustomer
    ? contact?.agency?.name
    : `${contact?.customer?.first_name} ${contact?.customer?.last_name}`

  const renderPhoneNumber = () => {
    const displayPhoneNumber = isCustomer ? contact?.agency?.phone : contact?.customer?.contact_number

    if (!displayPhoneNumber) return null

    return (
      <View variant={'marginBottom:2'}>
        <Text msg={'Telephone'} variant={'black'}/>
        <Text text={displayPhoneNumber} variant={'p2 veryBold black'}/>
      </View>
    )
  }

  const renderPrice = () => {
    if (!listing.price[1]) {
      return `${Misc.formatNumber(listing.price[0], listing.currency)}`
    }
    return `${Misc.formatNumber(listing.price[0], listing.currency)} to ${Misc.formatNumber(listing.price[1], listing.currency)}`
  }

  return (
    <View style={styles.infoContact}>
      <View variant={'column alignCenter'}>
        <Avatar variant={'largeCircle'} profile={avatar}/>
        <Text text={name} variant={'marginTop:1 veryBold black p1'}/>
      </View>

      <View style={styles.border} variant={'paddingTop:2 paddingBottom:2'}>
        <View variant={'marginTop:2'}>
          {renderPhoneNumber()}
          <View variant={'marginBottom:2'}>
            <Text msg={'E-mail'} variant={'black'}/>
            <Text text={email} variant={'p2 veryBold black'}/>
          </View>
        </View>
      </View>
      {listing && contact?.customer?.interested_in && (
        <View variant={'paddingTop:3 paddingBottom:3'}>
          <Text msg={'LISTING'} variant={'bold p2'}/>
          <Touchable to={`/listing/${listing.id}/view`} variant={'flex row marginTop:2'}>
            <Image
              source={listing.media[0].file}
              style={styles.listingImage}
            />
            <View variant={'marginLeft:2'}>
              <Text
                variant={'p2 veryBold'}
                msg={listing.title}
              />
              <Text
                variant={'p2 veryBold marginTop:1'}
                msg={renderPrice()}
              />
            </View>
          </Touchable>
        </View>
      )}

    </View>
  )
}

const styles = {
  remind: {
    width: 16,
    height: 16,
  },
  infoContact: {
    width: '100%',
    maxHeight: '90%',
    overflowY: 'auto',

    [Theme.media.up('small')]: {
      padding: Theme.spacing(1),
    },
  },
  border: {
    borderBottom: `1px solid ${Theme.colors.lightGrey}`,
  },
  listingImage: {
    width: 75,
    height: 54,
    borderRadius: Theme.values.borderRadius,
  },
}
