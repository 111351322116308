import { React, useState, useGoogleMaps, View, Touchable, Input, onUpdate, Text } from 'lib'
import { Theme } from 'app'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'

const PlacesSearchInput = ({
  label = 'Search properties',
  placeholder = 'Enter city or postcode',
  variant,
  style,
  inputStyle = {},
  labelStyle = {},
  defaultValue = '',
  onSelectValue = () => null,
  showMap,
  isSearch,
  ...props
}) => {
  const googleMaps = useGoogleMaps()
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [textToSearch, setTextToSearch] = useState('')

  const getAutocompleteSession = () => {
    try {
      return new googleMaps.places.AutocompleteSessionToken()
    } catch (err) {
      return null
    }
  }

  const {
    ready,
    value,
    suggestions,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      sessionToken: getAutocompleteSession(),
    },
    debounce: 300,
  })

  const ref = useOnclickOutside(() => {
    clearSuggestions()
    setShowSuggestions(false)
  })

  onUpdate(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const onEnterSubmit = (event) => {
    const keyCode = event.which || event.keyCode
    if (keyCode === 13 && !event.shiftKey) {
      handleOnSelectValue({ description: textToSearch }, true)
    }
  }

  const handleOnSearch = (searchText) => {
    setTextToSearch(searchText)
    setValue(searchText)
  }

  const handleOnSelectValue = async ({ description }, enterKey) => {
    setValue(description, false)
    clearSuggestions()
    setShowSuggestions(false)

    try {
      const geocodeResults = await getGeocode({ address: description })
      const address = geocodeResults[0]
      const postcode = address.address_components.find(obj => obj.types[0] === 'postal_code')
      const { lat, lng } = await getLatLng(geocodeResults[0])
      onSelectValue({
        lat,
        lng,
        description,
        postcode: postcode?.long_name?.replace('-', ''),
        // hasKeyEnter: enterKey,
      })
    } catch (err) {
      error(err)
    }
  }

  const renderSuggestions = () => {
    return suggestions.data.map((suggestion, index) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <Touchable key={id || index} style={styles.suggestionItem} onClick={() => handleOnSelectValue(suggestion, false)}>
          <Text variant={'block'}>
            <strong style={styles.suggestionItemHighlight}>{main_text}</strong>
            {` `}
            {secondary_text}
          </Text>
        </Touchable>
      )
    })
  }

  return (
    <View ref={ref} style={[styles.wrapper, isSearch && styles.searchWrapper, style]} {...props}>
      <Input
        variant={`flex ${variant}`}
        labelMsg={label}
        value={value}
        onChangeText={handleOnSearch}
        placeholder={placeholder}
        onFocus={() => setShowSuggestions(true)}
        disabled={!ready}
        textStyle={[styles.label, labelStyle]}
        style={[inputStyle]}
        onKeyDown={onEnterSubmit}
      />
      {(suggestions.status === 'OK' && showSuggestions) && (
        <View style={[styles.suggestionList, !showMap && isSearch && styles.suggestionListListing]}>{renderSuggestions()}
        </View>
      )}
    </View>
  )
}

const styles = {
  wrapper: {
    position: 'relative',
    width: `100%`,
    cursor: 'text',
    // backgroundColor: 'red',
    // [Theme.media.down('mid')]: {
    //   position: 'unset',
    // },
  },
  searchWrapper: {
    position: 'unset',
  },
  label: {
    marginBottom: 0,
  },
  suggestionList: {
    position: 'absolute',
    top: `calc(100% + ${Theme.spacing(1)}px)`,
    width: `calc(100% - ${Theme.spacing(3)}px)`,
    zIndex: 999,
    border: `1px solid ${Theme.colors.light}`,
    borderRadius: Theme.values.borderRadiusSmall,
    ...Theme.elevated2x,
    overflow: 'hidden',
    backgroundColor: Theme.colors.white,
    // backgroundColor: 'cyan',
    [Theme.media.down('mid')]: {
      border: 0,
      left: 0,
      right: 0,
      width: '100%',
      // width: `calc(100vw - ${Theme.spacing(4)}px)`,
    },
  },

  suggestionListListing: {
    position: 'absolute',
    top: `calc(100% + ${Theme.spacing(1)}px)`,
    zIndex: 999,
    border: `1px solid ${Theme.colors.light}`,
    borderRadius: Theme.values.borderRadiusSmall,
    ...Theme.elevated2x,
    overflow: 'hidden',
    backgroundColor: Theme.colors.white,
    width: '100vw !important',
    left: '-5% !important',
  },
  suggestionItem: {
    display: 'block',
    padding: Theme.spacing(1.5),
    color: Theme.colors.lightGrey,
    '&:hover': {
      backgroundColor: Theme.colors.backgroundOrange,
    },
    '&:last-child': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  suggestionItemHighlight: {
    color: Theme.colors.black,
    fontWeight: 'bold',
    marginRight: Theme.spacing(0.5),
  },
}

export default React.memo(PlacesSearchInput)
