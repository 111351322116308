import { React, View, useState, Link } from 'lib'
import { Theme } from 'app'
import { DragAndDrop, AssetGallery } from 'components'
import AssetEditorModal from './AssetPickerModal'

function AssetEditor(props) {
  const { data, setData, titleMsg, showPreviewPDF } = props
  const [showModal, setShowModal] = useState(false)

  return (
    <DragAndDrop>
      <View style={styles.wrapper}>
        <AssetEditorModal
          titleMsg={titleMsg}
          open={showModal}
          data={data}
          setData={setData}
          onClose={() => setShowModal(false)}
        />
        <AssetGallery showPreviewPDF={showPreviewPDF} data={data} filterActive={true} onPress={() => setShowModal(true)} variant={'small'}/>
        <Link variant={'p2 underline uppercase veryBold black marginTop:2'} msg={'Add or edit'} onPress={() => setShowModal(true)}/>
      </View>
    </DragAndDrop>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
  },
}

export default AssetEditor
