import { React, View } from 'lib'

function HorizontalScroll(props) {
  const { children, style, ...otherProps } = props

  return (
    <View {...otherProps} style={[styles.scroll, style]}>
      {children}
    </View>
  )
}

const styles = {
  scroll: {
    overflow: 'auto',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    whiteSpace: 'nowrap',
  },
}

export default HorizontalScroll
