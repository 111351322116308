import { React, View, Text } from 'lib'
import { Theme, TextStyles, InputStyles } from 'app'
import { default as ReactSelect } from 'react-select'

function Select({ style, labelMsg, ...props }) {
  const staticStyles = InputStyles.staticStyles.default

  return (
    <View style={[styles.wrapper, style]}>
      {labelMsg && (
        <Text style={[staticStyles.label, styles.label]} msg={labelMsg}/>
      )}
      <ReactSelect
        styles={selectStyles}
        theme={selectTheme}
        {...props}
      />
    </View>
  )
}

const styles = {
  wrapper: {},
  label: {
    marginBottom: Theme.spacing(2),
  },
}

const selectTheme = (provided) => {
  return {
    ...provided,
    borderRadius: Theme.values.borderRadiusSmall,
    colors: {
      ...provided.colors,
      primary: Theme.colors.lightOrange,
      primary75: `${Theme.colors.backgroundOrange}`,
      primary50: `${Theme.colors.backgroundOrange}`,
      primary25: `${Theme.colors.backgroundOrange}`,
    },
  }
}

const selectStyles = {
  control: (base) => ({
    ...base,
    border: `1px solid ${Theme.colors.light}`,
    height: 52, // silly correction due to borders
  }),
  container: (provided) => ({
    ...provided,
    fontFamily: Theme.fontFamily,
    ...TextStyles.text.p1,
  }),
  menu: (provided, state) => ({
    ...provided,
    color: Theme.colors.black,
  }),
}

export default Select
