/* eslint func-names: 'off' */
/* eslint global-require: 'off' */
/* eslint @typescript-eslint/no-var-requires: 'off' */

import { combineReducers, createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { Settings } from 'app'

import { AppStatus } from './AppStatus'
import { Session } from './Session'
import { Listings } from './Listings'
import { Contacts } from './Contacts'
import { Members } from './Members'
import { Notes } from './Notes'
import { Faq } from './Faq'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['Session'],
  // whitelist: ['Session'],
}

const reducers = combineReducers({
  AppStatus,
  Session,
  Listings,
  Contacts,
  Members,
  Notes,
  Faq,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const middleware =
__DEV__ && Settings.WARN_ILLEGAL_STATE_MUTATIONS
  ? [require('redux-immutable-state-invariant').default(), thunk]
  : [thunk]

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
)

export const persistor = persistStore(store)
