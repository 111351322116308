import { Theme } from 'app'
import { React, Text, Touchable, View } from 'lib'
import { Avatar } from 'components'

export default function ContactMiniCard({
  contact,
  profile,
  isSelected = false,
  lastMessage,
  handleSelectContact = () => null,
}) {
  const isCustomer = profile?.type === 'customer'

  const avatar = {
    avatar: isCustomer ? contact?.agency?.logo : contact?.customer?.avatar,
    first_name: isCustomer ? contact?.agency?.name : contact?.customer?.first_name,
  }

  const displayName = isCustomer
    ? contact?.agency?.name
    : `${contact?.customer?.first_name} ${contact?.customer?.last_name}`

  return (
    <Touchable
      variant={'marginBottom:1 row alignCenter'}
      onPress={() => handleSelectContact(contact)}
      style={[styles.user, { backgroundColor: isSelected ? Theme.colors.offwhite : 'transparent' }]}
    >
      <View variant={'marginRight:1'}>
        <Avatar variant={'midCircle'} profile={avatar}/>
      </View>
      <View style={styles.infos}>
        <Text text={displayName} variant={'black p2'}/>
      </View>
    </Touchable>
  )
}

const styles = {
  user: {
    maxHeight: 80,
    borderRadius: Theme.values.borderRadiusSmall,
    padding: 10,
  },
  infos: {
    flex: 1,
  },
}
