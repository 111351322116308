/* eslint no-restricted-imports: 'off' */
import { View, useRedux, onUpdate, onMount, useState, Animated } from 'lib'
import { Theme } from 'app'
import { AppStatus } from 'actions'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Spinner } from 'components/shared'
import { IoMdCheckmark } from 'react-icons/io'

/** @jsx jsx */
import { jsx } from '@emotion/react'

const finalTransitionDelay = 500
const doneDelay = 2000

export default function AppStatusComponent() {
  const { activity } = useRedux('AppStatus')
  const [show, setShow] = useState(open)
  const [target, setTarget] = useState(null)

  const open = ['loading', 'done', 'error'].includes(activity)

  onMount(() => {
    setTarget(document.querySelector('#body'))
  })

  onUpdate(() => {
    if (open) {
      if (open != show) {
        setShow(open)
      }
      if (target) {
        disableBodyScroll(target)
      }
      if (activity == 'done') {
        log({ activity })
        const timer = setTimeout(() => {
          AppStatus.setActivity(null)
        }, doneDelay)
        return () => clearTimeout(timer)
      }
    } else if (open != show) {
      clearAllBodyScrollLocks()
      const timer = setTimeout(() => {
        setShow(open)
      }, finalTransitionDelay)
      return () => clearTimeout(timer)
    }
  }, [activity, target])


  function renderContent() {
    switch (activity) {
      case 'loading':
        return <Spinner/>
      case 'done':
        return <IoMdCheckmark color={Theme.colors.primary} size={50}/>
      default:
        break
    }
  }

  const content = renderContent()

  const animate = open && show ? 'show' : 'hide'

  const displayOverlay = open || show

  return displayOverlay ? (
    <View style={styles.wrapper}>
      <Animated
        // style={[styles.wrapper, wrapperAnimations[animate]]}
        style={styles.background}
        onClick={e => e.stopPropagation()}
        animate={animate}
        variants={wrapperAnimations}
        transition={wrapperTransition}
        initial={'hide'}
      />
      <View style={styles.innerWrapper}>
        <Animated
          animate={animate}
          variants={modalAnimations}
          transition={modalTransition}
          initial={'hide'}
          style={styles.modal}
        >
          <View style={styles.content}>
            {content}
          </View>
        </Animated>
      </View>
    </View>
  ) : null
}

const modalSize = '100px'

const styles = {
  wrapper: {
    ...Theme.whole,
    ...Theme.center,
    position: 'fixed',
  },
  background: {
    ...Theme.whole,
    ...Theme.center,
    backgroundColor: `${Theme.colors.black}77`,
    position: 'fixed',
    zIndex: 50,
  },
  innerWrapper: {
    ...Theme.center,
    ...Theme.flex,
    ...Theme.fullView,
  },
  modal: {
    ...Theme.center,
    borderRadius: Theme.values.borderRadius * 2,
    backgroundColor: 'white',
    width: modalSize,
    height: modalSize,
    zIndex: 100,
  },
  content: {
    ...Theme.center,
    display: 'flex',
    height: '100%',
  },
}


const wrapperAnimations = {
  show: {
    opacity: '100%',
  },
  hide: {
    opacity: '0%',
  },
}

const modalAnimations = {
  show: {
    opacity: '100%',
    scale: 1,
  },
  hide: {
    opacity: '0%',
    scale: 0.5,
  },
}

const durations = {
  modal: 200,
  wrapper: 300,
}

const wrapperTransition = {
  duration: durations.wrapper/1000,
  ease: 'linear',
}
const modalTransition = {
  duration: durations.modal/1000,
  ease: 'backInOut',
}
