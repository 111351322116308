/* eslint no-restricted-imports: 'off' */
import { useState } from 'react'
import { useWindowResize } from 'beautiful-react-hooks'
import { Theme } from '../../_app/Theme.js'

import { detect } from 'detect-browser'

let browser = ''
try {
  const detected = detect()
  browser = detected.name.toLowerCase()
} catch (err) {
  warn('No platform detected.')
}

const queries = {
  up: (test) => {
    // Upwards of... (excluding)
    let min = null
    if (typeof test === 'string') {
      const bkpts = Theme.breakpoints
      min = bkpts[test] + 1
    } else {
      min = test
    }
    const mediaString = `@media screen and (min-width:${min}px)`
    return mediaString
  },
  down: (test) => {
    // Downwards of... (excluding)
    let max = null
    if (typeof test === 'string') {
      const bkpts = Theme.breakpoints
      max = bkpts[test]
    } else {
      max = test
    }
    const mediaString = `@media screen and (max-width:${max}px)`
    return mediaString
  },
  is: (test) => {
    // Is media... (exact)
    const bkpts = Theme.breakpoints
    const idx = Object.keys(bkpts).indexOf(test)
    const values = Object.values(bkpts)
    const min = bkpts[test]
    const max = idx < values.length ? values[(idx + 1)] : values[values.length]
    const mediaString = `@media screen and (min-width:${min}px)${max ? ` and (max-width:${max}px)` : ''}`
    return mediaString
  },
  not: (test) => {
    // Is NOT media... (exact)
    const bkpts = Theme.breakpoints
    const idx = Object.keys(bkpts).indexOf(test)
    const values = Object.values(bkpts)
    const min = bkpts[test]
    const max = idx < values.length ? values[(idx + 1)] : values[values.length]
    const mediaString = `@media not screen and (min-width:${min}px)${max ? ` and (max-width:${max}px)` : ''}`
    return mediaString
  },
}


const renderToPlatformQuery = (props) => {
  let mediaString = ''
  if (props?.is) {
    mediaString = queries.not(props.is)
  } else if (props?.not) {
    mediaString = queries.is(props.not)
  } else if (props?.up) {
    mediaString = queries.down(props.up)
  } else if (props?.down) {
    mediaString = queries.up(props.down)
  }
  return mediaString ? {
    [`${mediaString}`]: {
      display: 'none',
    },
  } : {}
}

const shouldRenderToPlatform = (props) => {
  let res = true
  if (props?.is) {
    res = hooks.is(props.is)
  } else if (props?.not) {
    res = hooks.not(props.not)
  } else if (props?.up) {
    res = hooks.up(props.up)
  } else if (props?.down) {
    res = hooks.down(props.down)
  }
  return res
}


function getCurrentMediaSize() {
  const bkpts = Theme.breakpoints
  const keys = Object.keys(bkpts)
  const values = Object.values(bkpts)
  try {
    const width = window.innerWidth - 1
    for (const v in values) {
      if (width < values[v]) {
        return keys[v - 1]
      }
    }
    return keys[keys.length - 1]
  } catch (err) {
    warn({ err })
    return keys[0]
  }
}


function getCurrentSize() {
  try {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  } catch (err) {
    warn({ err })
    return {
      width: 0,
      height: 0,
    }
  }
}


const hooks = {
  size: function () {
    const [size, setSize] = useState(getCurrentSize())
    useWindowResize(() => {
      const currentSize = getCurrentSize()
      // NOTE this attempts to lock the device height in small screen sizes so that expanding/collapsing browser bar
      // doens't mess with parallax
      // log({ size, currentSize })
      if (size.width > Theme.breakpoints.small || size.width != currentSize.width) {
        setSize(currentSize)
        // Toast.info('Window size changed')
      }
    })
    return size
  },
  media: function () {
    const [mediaSize, setMediaSize] = useState(getCurrentMediaSize())
    useWindowResize(() => {
      // Toast.info('Trigger media change')
      const newMedia = getCurrentMediaSize()
      if (newMedia != mediaSize) {
        // Toast.info('Resize media')
        setMediaSize(newMedia)
      }
    })
    return mediaSize
  },
  is: function (test) {
    const value = this.media()
    return value == test
  },
  not: function (test) {
    const value = this.media()
    return value != test
  },
  up: function (test) {
    const { width } = this.size()
    return width > Theme.breakpoints[test]
  },
  down: function (test) {
    const { width } = this.size()
    return width <= Theme.breakpoints[test]
  },
}


export default {
  renderToPlatformQuery,
  shouldRenderToPlatform,
  queries,
  hooks,
  browser,
}
