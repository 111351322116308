import { React, View, Text, CenterWrapper, Button } from 'lib'
import { Theme } from 'app'
import { FaArrowLeft } from 'react-icons/fa'
import { Profile } from 'actions'

function ListingBanner(props) {
  const { onBack } = props

  function handleBack() {
    onBack()
  }

  return (
    <View style={styles.wrapper}>
      <View style={styles.innerWrapper}>
        <CenterWrapper>
          <View variant={'row justifySpaceBetween'}>
            <View>
              <Button icon={FaArrowLeft} color={Theme.colors.grey} size={24} variant={'iconText'} msg={'BACK'} onPress={handleBack}/>
            </View>
          </View>
        </CenterWrapper>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
    right: 0,
    left: 0,
    zIndex: 999,
  },
  innerWrapper: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: Theme.values.headerMenuHeight,
  },
}

export default ListingBanner
