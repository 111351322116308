import { React, View, Touchable, useState } from 'lib'
import { Theme } from 'app'
import { AssetPreview, AssetLightbox } from 'components'
import { findIndex } from 'lodash'
import zoom from '../../assets/zoom.svg'

function AddNewItem({ onPress }) {
  return (
    <Touchable variant={'growSmall'} onPress={onPress}>
      <View style={staticStyles.addButtonWrapper}>
        <img src={zoom}/>
      </View>
    </Touchable>
  )
}

function Furnitures({ data, onPress, variant, filterActive, titleMsg, showPreviewPDF }) {
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)

  if (filterActive) {
    data = data.filter(i => i.active)
  }

  const onOpen = (id) => {
    const index = findIndex(data, { id })
    setSelectedItem(index)
    setOpen(true)
  }

  const content = data.map((item, index) => {
    return (
      <Touchable key={index} style={staticStyles.assetWrapper} onPress={() => onOpen(item.id)}>
        <AssetPreview
          item={item}
          index={index}
          style={staticStyles.furnitureImage}
          showPreviewPDF={showPreviewPDF}
        />
      </Touchable>
    )
  })

  if (!data.length && onPress) {
    content.push(<AddNewItem onPress={onPress}/>)
  }

  const maxItems = variant == 'small' ? 5 : 3
  const items = content.slice(0, maxItems)

  return (
    <View style={staticStyles.wrapper}>
      <AssetLightbox
        open={open}
        data={data}
        titleMsg={titleMsg}
        initialSlide={selectedItem}
        onClose={() => setOpen(false)}
      />
      <View style={staticStyles.innerWrapperSmall}>
        {items}
      </View>
    </View>
  )
}

const staticStyles = {
  wrapper: {
    width: '100%',
  },
  innerWrapperSmall: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: Theme.spacing(1.5),

    [Theme.media.is('small')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  addButtonWrapper: {
    flex: 1,
    display: 'flex',
    ...Theme.center,
    borderRadius: Theme.values.borderRadius,
    backgroundColor: Theme.colors.offwhite,
    border: `1px solid ${Theme.colors.light}`,
  },
  furnitureImage: {
    borderRadius: Theme.values.borderRadius,
    borderWidth: 1,
    borderColor: Theme.colors.light,
    borderStyle: 'solid',
  },
  assetWrapper: {
    width: 150,
    height: 120,
    flex: 1,
    flexGrow: 1,
    display: Theme.browser.includes('safari') ? '-webkit-box' : 'block',
    position: 'relative',
    borderRadius: 6,
  },
}

export default Furnitures
