import { Settings } from 'app'
import firebase from 'gatsby-plugin-firebase'
import { AppStatus } from 'actions'
import { Toast } from 'lib'

/**
 * @exports Fetch(params)
 * Standard Fetch function. Use this for all requests.
 *
 * @prop {object} params ---> Parameters object
 *
 * Required params
 * @param {string} url ---> Url to reach
 * @param {string} successCallback ---> Callback when request is sucessfull. Sends JSON data or response object.
 *
 * Optional params
 * @param {string} failureCallback ---> Callback when request has failed. Defaults to console warning.
 * @param {string} method ---> GET, POST, PATCH, etc... default to GET
 * @param {object} data ---> Data to send
 * @param {string} headers ---> Request headers
 * @param {string} options ---> Request options
 *
 * Options object
 * @option {string} full_url ---> Prevents append BASE_URL to request url param
 * @option {boolean} json ---> Specify whether the response should contain JSON data to process. Defaults to true
 * @option {boolean} multipart ---> Specify if request is of multipart type. Defaults to false
 * @option {string} noToken ---> Attempts fetch without a token
 *
 */


function appendSlash(url) {
  if (!url.includes('?') && url.charAt(url.length - 1) != '/') {
    url += '/'
  }
  return url
}

async function Fetch(params, retrying = 0) {
  // info('Fetch -> Raw request', { url: params.url, params })

  // Gets user token from firebase
  const user = firebase.auth().currentUser
  let idTokenResult = null
  let AUTH_TOKEN = null
  if (user) {
    idTokenResult = await firebase.auth().currentUser.getIdTokenResult()
    AUTH_TOKEN = idTokenResult.token
  } else if (!params?.options?.noToken) {
    const message = 'No token present. Please authenticate before making any requests.'
    warn(message)
    if (retrying < 2) {
      setTimeout(() => Fetch(params, retrying + 1), 1000)
    } else {
      if (params.hasOwnProperty('failureCallback')) {
        params.failureCallback(message)
      }
    }
    return null
  }

  // Process options
  let options = {
    full_url: false,
    json: true,
    multipart: false,
    silent: false,
  }
  if (params.hasOwnProperty('options')) {
    options = { ...options, ...params.options }
  }

  // Process Authentication headers
  const AUTH_HEADER = options.multipart ? {
    Accept: '*/*',
    // 'Content-Type': 'multipart/form-data', // NOTE Somehow it works WITHOUT this on the web
  } : {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (AUTH_TOKEN) AUTH_HEADER.Authorization = AUTH_TOKEN

  // Prepares request data
  const url = options.full_url ? params.url : appendSlash(Settings.BASE_URL + params.url)
  const data = params.data && (options.multipart ? params.data : JSON.stringify(params.data))
  const headers = params.hasOwnProperty('headers') ? params.headers : AUTH_HEADER


  // Attempts actual request
  log('Fetch -> Making request', { url: params.url, params, options, user })
  // info('Fetch -> Making request', { url: params.url, params, options, user })
  try {
    let response
    if (!data && !headers) {
      response = await fetch(url, {
        method: params.method,
      })
    } else {
      response = await fetch(url, {
        method: params.method,
        body: data,
        headers: headers,
      })
    }
    info('response', { url: params.url, params, response })
    if (response && response.ok) {
      // Success response
      if (params.hasOwnProperty('successCallback')) {
        if (options.json) {
          try {
            const json = await response.json()
            params.successCallback(json)
          } catch (err) {
            warn('> > > > > > INTERNAL ERROR < < < < < <', { err })
            params.successCallback()
          }
        } else {
          params.successCallback(response)
        }
      }
      return true

    } else {
      // Server error
      const { status, statusText, _bodyText } = response
      warn('> > > > > > SERVER ERROR < < < < < <', { url, status, params, _bodyText, response, AUTH_HEADER })
      if (params.hasOwnProperty('failureCallback')) {
        params.failureCallback(response)
      } else if (!options.silent) {
        AppStatus.setActivity(null)
        Toast.error(_bodyText || statusText || 'Server error')
      }
      return false
    }
  } catch (err) {
    // Network error
    warn('> > > > > > NETWORK ERROR < < < < < <', { url, err, params, AUTH_HEADER })
    if (params.hasOwnProperty('failureCallback')) {
      params.failureCallback('NETWORK_ERROR')
    } else if (!options.silent) {
      AppStatus.setActivity(null)
      Toast.error(err || 'Network error')
    }
    return false
  }
}

export default Fetch
