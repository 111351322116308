import { View, React } from 'lib'
import { Theme } from 'app'

export default function CenterWrapper(props) {
  const { style, ignoreMobile, contentContainerStyle, children } = props
  return (
    <View style={[styles.wrapper, style]}>
      <View style={[styles.innerWrapper, ignoreMobile && styles.innerWrapperIgnoreMobile, contentContainerStyle]}>
        {children}
      </View>
    </View>
  )
}

const marginsMid = Theme.spacing(3)
const marginsSml = Theme.spacing(2)

const styles = {
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    width: '100%',
    marginHorizontal: 'auto',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: Theme.values.maxContentWidth,
    [Theme.media.down('xlarge')]: {
      maxWidth: `calc(${Theme.values.maxContentWidth}px - ${marginsMid * 2}px)`,
      width: `calc(100vw - ${marginsMid * 2}px)`,
    },
    [Theme.media.down('mid')]: {
      maxWidth: `calc(${Theme.values.maxContentWidth}px - ${marginsSml * 2}px)`,
      width: `calc(100vw - ${marginsSml * 2}px)`,
    },
    [Theme.media.is('small')]: {
      maxWidth: `calc(${Theme.values.maxContentWidth}px - ${marginsSml * 2}px)`,
      width: `calc(100vw - ${marginsSml * 2}px)`,
    },
  },
  innerWrapperIgnoreMobile: {
    [Theme.media.is('small')]: {
      width: '100%',
      maxWidth: Theme.values.maxContentWidth,
    },
  },
}
