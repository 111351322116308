import { React, View, Image, Text, Link, Touchable, useState, useRedux } from 'lib'
import { Theme } from 'app'
import { Avatar } from 'components'
import trash from '../../assets/trash.svg'

const MemberInviteItem = ({ member, onResend = () => null, onRemove = () => null }) => {
  const [hover, onHover] = useState(false)
  const profile = useRedux('Session.profile')

  return (
    <View style={styles.wrapper} onHover={onHover}>
      <View style={styles.avatar}>
        <Avatar
          profile={{ avatar: null, first_name: member.email }}
          variant={'medium'}
          styleAvatar={styles.image}
        />
      </View>
      <View variant={'padding:3'}>
        <Text
          variant={'p1 black bold clamp'}
          text={`${member.name || 'New Member'}`}
        />
        <Text variant={'p2 clamp marginTop:2'}>{member.email}</Text>
        <View variant={'row alignCenter justifySpaceBetween marginTop:2'}>
          {profile.type === 'agency_owner' && (
            <Link
              variant={`p2 underline grey`}
              msg={`Resend link`}
              onPress={() => onResend(member.id, member.email)}
            />
          )}
          {profile.type === 'agency_owner' && hover && (
            <Touchable onPress={() => onRemove(member.id)}>
              <Image style={styles.trash} src={trash}/>
            </Touchable>
          )}
        </View>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    boxShadow: '1px 1px 10px 1px rgb(0 0 0 / 12%)',
    borderRadius: Theme.values.borderRadius,
  },
  avatar: {
    borderTopLeftRadius: Theme.values.borderRadius,
    borderTopRightRadius: Theme.values.borderRadius,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: '100%',
    height: 200,
    objectFit: 'cover',
    flexGrow: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    [Theme.media.is('small')]: {
      width: '100%',
      height: '100%',
    },
  },
  trash: {
    width: Theme.spacing(2.5),
  },
}

export default MemberInviteItem
