import { React, View, useState, Link, Misc, Text, getStyles } from 'lib'
import { Theme, AssetGalleryStyles } from 'app'
import { DragAndDrop } from 'components'
import AssetItemPreview, { AddAssetItemPreview } from './AssetItemPreview'
import AssetEditorModal from './AssetEditorModal'

function AssetEditor(props) {
  const { data, setData, titleMsg, showPreviewPDF } = props
  const [showModal, setShowModal] = useState(false)
  const assetItemStyles = getStyles('small', AssetGalleryStyles)

  const handleAddAsset = filesData => {
    const { files, previews } = filesData
    const canShowAsset = Misc.canAddAsset(files[0].type, props.type)
    if (canShowAsset) {
      const assetsData = files?.map((asset, index) => ({
        file: files[index],
        preview: previews[index],
        caption: '',
      }))
      const newData = [...data, ...assetsData]
      setData(newData)
      log(newData)
    }
  }

  const previewContent = [
    ...data?.map((i, k) => {
      return (
        <AssetItemPreview
          item={i}
          key={k}
          index={k}
          showPreviewPDF={showPreviewPDF}
          openEdit={() => setShowModal(true)}
        />
      )
    }),
    data?.length === 0 && (
      <AddAssetItemPreview
        index={data?.length}
        key={data?.length}
        addAsset={handleAddAsset}
      />
    ),
  ]
  const firstItem = previewContent.slice(0, 1)
  const remainingItems = previewContent.slice(1, 5)

  return (
    <DragAndDrop>
      <View style={styles.wrapper}>
        <AssetEditorModal
          titleMsg={titleMsg}
          open={showModal}
          data={data}
          setData={setData}
          onAddAsset={handleAddAsset}
          onClose={() => setShowModal(false)}
        />
        <View style={styles.innerWrapperSmall} not={'small'}>
          <View style={[styles.firstItemGridWrapper, assetItemStyles.firstGrid]}>
            {firstItem}
          </View>
          <View style={[styles.secondGridWrapperSmall, assetItemStyles.secondGrid]}>
            {remainingItems}
          </View>
        </View>
        <View is={'small'}>
          <View style={styles.firstItemGridWrapper}>
            {firstItem}
          </View>
        </View>

        <View variant={'row alignCenter  marginTop:2'}>
          <Link
            variant={'p2 underline uppercase veryBold lightGrey'}
            msg={'Add or edit'}
            onPress={() => setShowModal(true)}
          />
          <Text
            variant={'p2 marginLeft:2 lightGrey'}
            msg={`(${data?.length} photos)`}
          />
        </View>
      </View>
    </DragAndDrop>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
  },
  innerWrapperSmall: {
    display: 'grid',
    gridTemplateColumns: '336px auto',

    [Theme.media.down('xlarge')]: {
      gridTemplateColumns: '216px auto',
    },
  },
  preview: {
    borderRadius: Theme.values.borderRadiusSmall,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  firstItemGridWrapper: {
    flex: 1,
  },
  secondGridWrapperSmall: {
    flex: 1,
    display: 'grid',
    gridGap: AssetGalleryStyles.values.gridGap,
    gridTemplateRows: '100% 100%',
    gridTemplateColumns: '48% 50%',
    marginLeft: AssetGalleryStyles.values.gridGap,
  },
  assetCounterWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: '#ffffffaa',
    borderTopLeftRadius: Theme.values.borderRadiusSmall,
    padding: Theme.spacing(0.5),
    ...Theme.paddingHorizontal(1.2),
  },
  removeAssetWrapper: {
    borderBottomLeftRadius: Theme.values.borderRadius,
    backgroundColor: '#ffffffaa',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: Theme.spacing(0.5),
    ...Theme.paddingHorizontal(1.2),
  },
  assetCounterText: {
    color: '#333',
  },
}

export default React.memo(AssetEditor)
