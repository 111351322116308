import { Theme } from 'app'
import { React, Image, Text, Touchable, View } from 'lib'
import add from '../../assets/add.svg'
import moment from 'moment-timezone'

export default function Notes({
  contactsList,
  notes,
  showNoteDetail,
  addNote,
}) {
  if (contactsList && contactsList.length === 0) {
    return (
      <View style={styles.notes}>
        <View style={styles.scrollNotes} variant={'flex row center'}>
          <Text msg={'No search results found'} variant={'p2'}/>
        </View>
      </View>
    )
  }

  if (notes && notes.length === 0) {
    return (
      <Touchable onPress={() => addNote()} style={styles.noNotes} variant={'column justifyCenter'}>
        <Text style={styles.textCenter} msg={'You haven`t created notes for this contact yet.'} variant={'p1 grey center'}/>
        <Text msg={'Click anywhere to add your first note :)'} variant={'p2 grey marginTop:2 center'}/>
      </Touchable>
    )
  }

  return (
    <View style={styles.notes}>
      <Touchable onPress={() => addNote()} style={styles.newNote}>
        <Image src={add} style={styles.add} alt={'add'}/>
      </Touchable>
      <View style={styles.scrollNotes}>
        {notes.map(note => {
          const created = moment(note.created_datetime, 'MM/DD/YYYY-HH:mm:ss').format('DD/MM/YYYY')
          const today = moment().format('DD/MM/YYYY')
          let timeNote = moment(note.created_datetime, 'MM/DD/YYYY-HH:mm:ss').format('HH:mm')
          if (today > created) {
            timeNote = moment(note.created_datetime, 'MM/DD/YYYY-HH:mm:ss').format('DD/MM/YYYY')
          }
          return (
            <Touchable key={note.id} style={styles.note} variant={'padding:3 flex alignCenter justifySpaceBetween marginBottom:1'} onPress={() => showNoteDetail(note)}>
              <Text variant={'p2 black marginRight:2 bold'} text={note.content} style={styles.content}/>
              <Text variant={'p2 black'} text={timeNote}/>
            </Touchable>
          )
        })}
      </View>
    </View>
  )
}

const styles = {
  notes: {
    position: 'relative',

    // [Theme.media.up('large')]: {
    //   padding: Theme.spacing(3),
    // },
  },
  textCenter: {
    textAlign: 'center',
  },
  content: {
    WebkitLineClamp: '2',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  newNote: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 20,
    right: 40,
    marginTop: Theme.spacing(1),

  },
  noNotes: {
    width: '100%',
    maxHeight: 500,
    minHeight: 450,

    padding: Theme.spacing(1),

  },
  scrollNotes: {
    maxHeight: 500,
    minHeight: 450,
    overflowY: 'auto',

    // [Theme.media.up('small')]: {
    //   padding: Theme.spacing(1),
    // },
  },
  note: {
    background: '#FEEBDF',
    borderRadius: Theme.values.borderRadiusSmall,
    // height: Theme.spacing(4),
    [Theme.media.is('small')]: {
      marginLeft: Theme.spacing(2),
      marginRight: Theme.spacing(2),
      marginTop: Theme.spacing(2),
      marginBottom: Theme.spacing(2),
    },
  },
  add: {
    width: Theme.spacing(5),
    height: Theme.spacing(5),
  },
}
