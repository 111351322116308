import { React, View, Button, Link } from 'lib'

function ProfileSaveButtonComponent({ save }) {
  return (
    <View variant={'marginVertical:6 alignCenter'}>
      <Button
        msg={`SAVE`}
        onPress={save}
        variant={'orange paddingHorizontal:5'}
      />
      <Link
        variant={`h5 bold hoverDecoration marginLeft:4`}
        msg={`Logout`}
        to={'/logout'}
      />
    </View>
  )
}

export default ProfileSaveButtonComponent
