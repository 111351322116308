import { React, View, Text, useState, Touchable } from 'lib'
import { Theme } from 'app'
import Arrow from '../../assets/arrow_down.svg'

function FAQAccordion({ cards }) {
  const [list, setList] = useState([])

  const openItem = (card) => {
    if (card.open) {
      delete card.open
      setList([])
    } else {
      const clickedCard = cards.filter(fq => fq.id === card.id)
      clickedCard[0].open = true
      const newCard = [...cards, clickedCard[0]]
      const newList = cards.map(obj => newCard.find(o => o.id === obj.id))
      setList(newList)
    }
  }

  const cardsToShow = list.length > 0 ? list : cards

  return (
    <View style={styles.tabs}>
      {cardsToShow.map((item, index) => {
        return (
          <View style={styles.tab} key={item.id}>
            <Touchable onPress={() => openItem(item)} style={[styles.label, index === 0 && styles.removePadding]} variant={'row alignCenter justifySpaceBetween'}>
              <Text variant={'h4'} msg={item.question}/>
              <img style={item.open ? styles.arrowUp : styles.arrowDown} src={Arrow}/>
            </Touchable>
            <View style={item.open ? styles.tabContentOpen : styles.tabContent}>
              <Text msg={item.answer}/>
            </View>
          </View>
        )
      })}
    </View>
  )
}

const styles = {
  tabs: {
    overflow: 'hidden',
  },
  tab: {
    width: '100%',
    overflow: 'hidden',
    borderBottom: '1px solid #ccc',
  },
  tabContent: {
    maxHeight: 0,
    transition: 'all .35s',
  },
  tabContentOpen: {
    maxHeight: '100vh',
    paddingBottom: Theme.spacing(4),
    transition: 'all .35s',
  },
  arrowUp: {
    transition: 'all .35s',
    transform: 'rotate(180deg)',
    width: Theme.spacing(3),
  },
  arrowDown: {
    transition: 'all .35s',
    transform: 'rotate(0deg)',
    width: Theme.spacing(3),
  },
  label: {
    padding: '30px 0px',
  },
  removePadding: {
    paddingTop: 0,
  },
}

export default FAQAccordion
