import { Agency } from 'actions'
import { Theme } from 'app'
import { Input, React, Touchable, useState, View } from 'lib'

const AgenciesInput = ({
  labelMsg,
  placeholder = 'Select an agency',
  onSelectValue = () => null,
  ...props
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [listAgencies, setListAgencies] = useState([])
  const [value, setValue] = useState('')

  const handleOnSelectValue = (agency) => {
    onSelectValue(agency)
    setValue(agency.name)
    setShowSuggestions(false)
  }

  const handleOnFocus = () => {
    setValue('')
    setShowSuggestions(true)
  }

  const handleOnChangeText = (text) => {
    setValue(text)
    const onSuccess = (res) => {
      if (text === '') {
        setListAgencies([])
      } else {
        setListAgencies(res.results)
      }
    }

    Agency.searchAgency(text, res => onSuccess(res))
  }

  const renderSuggestions = () => {
    const filteredSuggestions = listAgencies.filter((agency) => {
      return agency.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    })

    return filteredSuggestions.map((agency, index) => {
      return (
        <Touchable
          key={agency.id || index}
          style={styles.suggestionItem}
          onClick={() => handleOnSelectValue(agency)}
        >
          <View variant={'padding'} style={styles.suggestionItemHighlight}>
            {agency.name}
          </View>
        </Touchable>
      )
    })
  }

  return (
    <View
      style={styles.wrapper}
    >
      <Input
        variant={'flex'}
        labelMsg={labelMsg}
        value={value}
        placeholder={placeholder}
        onChangeText={handleOnChangeText}
        onFocus={handleOnFocus}
        {...props}
      />
      {(Boolean(listAgencies.length) && showSuggestions) && (
        <View style={styles.suggestionList}>{renderSuggestions()}</View>
      )}
    </View>
  )
}

const styles = {
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  suggestionList: {
    position: 'absolute',
    top: '100%',
    width: '100%',
    borderRadius: Theme.borders.borderRadius,
    borderColor: '#c4c4c4',
    borderWidth: 1,
    borderStyle: 'solid',
    zIndex: 999,
    backgroundColor: Theme.colors.white,
    maxHeight: 230,
    overflow: 'hidden',
    overflowY: 'scroll',

    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  suggestionItem: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: Theme.fontFamily,
    ...Theme.paddingVertical(1),
    ...Theme.paddingHorizontal(2),
    color: Theme.colors.black,
    height: 35,
    '&:hover': {
      color: Theme.colors.white,
      backgroundColor: Theme.colors.primary,
    },
    '&:first-child': {
      borderTopLeftRadius: Theme.borders.borderRadius,
      borderTopRightRadius: Theme.borders.borderRadius,
    },
    '&:last-child': {
      borderBottomLeftRadius: Theme.borders.borderRadius,
      borderBottomRightRadius: Theme.borders.borderRadius,
    },
  },
  suggestionItemHighlight: {
    color: Theme.colors.black,
    '&:hover': {
      color: Theme.colors.white,
    },
  },
}

export default React.memo(AgenciesInput)
