import { React, View, Text, Image } from 'lib'
import { Theme } from 'app'

function FeatureGallery(props) {
  const { features } = props

  return (
    <View style={styles.grid}>
      {features.map((feature) => (
        <View key={feature.id} style={styles.featureWrapper}>
          <View style={styles.iconWrapper}>
            <Image source={feature.icon} style={styles.icon}/>
          </View>
          <Text variant={'marginLeft:2'} text={feature.name}/>
        </View>
      ))}
    </View>
  )
}


const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: Theme.spacing(3),
    marginTop: Theme.spacing(3),

    [Theme.media.is('small')]: {
      gridTemplateColumns: '1fr',
    },
  },
  featureWrapper: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'flex',
    ...Theme.center,
  },
  icon: {
    width: 28,
    height: 28,
    objectFit: 'contain',
  },
}

export default FeatureGallery
