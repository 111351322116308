import { React, View, Text, Image, Button, useState } from 'lib'
import { Theme } from 'app'

const DESCRIPTION_PREVIEW_LENGTH = 60

function AddListingCard({
  data,
  canEdit,
}) {
  const md = Theme.hooks.media()
  const to = canEdit ? `/development/${data.id}/edit` : `/development/${data.id}/view`
  const description = Tools.ellipsis(data.description, DESCRIPTION_PREVIEW_LENGTH)
  const [showNewListing, setShowNewListing] = useState(false)

  const controlListing = () => {
    setShowNewListing(!showNewListing)
  }

  return (
    <View style={styles.wrapper} onHover={controlListing} variant={'flex row alignCenter justifySpaceBetween marginBottom:2'}>
      <View variant={'flex row alignCenter'}>
        <Image style={styles.image} source={data.media[0]?.file}/>
        <View variant={'marginLeft:4'}>
          <Text variant={'h5 bold'} text={data.title}/>
          <Text variant={'p1 marginTop:2'} text={description}/>
        </View>
      </View>
      {showNewListing &&
        <View style={styles.buttonMob}>
          <Button msg={'New listing'} to={to}/>
        </View>
      }
    </View>
  )
}

const styles = {
  wrapper: {
    '&:hover': {
      ...Theme.elevated,
    },
    borderRadius: Theme.values.borderRadius,
    padding: Theme.spacing(2),
    border: `1px solid ${Theme.colors.white}`,
    position: 'relative',
  },
  image: {
    borderRadius: Theme.values.borderRadius,
    width: 160,
    height: 96,
    objectFit: 'cover',

    [Theme.media.is('small')]: {
      width: 100,
      height: 76,
    },
  },
  buttonMob: {
    [Theme.media.is('small')]: {
      position: 'absolute',
      right: Theme.spacing(2),
      bottom: Theme.spacing(2),
    },
  },
}

export default AddListingCard
