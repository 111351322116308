import { React, useState, useRedux, onUpdate, View, Touchable, Input } from 'lib'
import { Theme } from 'app'
import { Avatar } from 'components'
import useOnclickOutside from 'react-cool-onclickoutside'

const ContactsInput = ({
  dataType = 'contacts',
  members,
  labelMsg,
  placeholder = 'Contacts',
  selectedValue = '',
  onSelectValue = () => null,
  suggestionsAsDropdown = true,
  ...props
}) => {
  const [showSuggestions, setShowSuggestions] = useState(!suggestionsAsDropdown)
  const [value, setValue] = useState('')
  const [contactId, setContactId] = useState(null)

  const contacts = useRedux('Contacts')
  const primaryProp = dataType === 'contacts' ? 'email' : 'first_name'
  const defaultValue = contacts.find(c => c.id === selectedValue)?.[primaryProp] || ''
  const isValueMatch = Boolean(contacts.find(c => c[primaryProp] === value))

  onUpdate(() => {
    if (suggestionsAsDropdown) {
      setValue(defaultValue)
    }
  }, [contacts])

  const ref = useOnclickOutside(() => {
    if (suggestionsAsDropdown) {
      setShowSuggestions(false)
    }

    if (value === '' || !isValueMatch) {
      setValue(defaultValue)
    }
  })

  const handleOnSelectValue = (contact) => {
    onSelectValue(contact.id)
    setValue(contact[primaryProp])

    if (suggestionsAsDropdown) {
      setShowSuggestions(false)
    }
  }

  const handleOnFocus = () => {
    setValue('')
    setShowSuggestions(true)
  }

  const handleOnChangeText = (text) => {
    setValue(text)
  }

  const hoverContact = (id) => {
    setContactId(id)
  }

  const renderContacts = () => {
    const filteredSuggestions = contacts.filter((contact) => {
      return contact?.[primaryProp].toLowerCase().indexOf(value?.toLowerCase()) > -1
    })

    return filteredSuggestions.map((contact, index) => {
      const avatarObject = {
        first_name: contact.first_name,
        avatar: contact?.avatar,
      }

      return (
        <Touchable
          key={contact.id || index}
          style={styles.suggestionItem}
          onClick={() => handleOnSelectValue(contact)}
          onHover={() => hoverContact(contact.id)}
        >
          <View style={styles.avatar}>
            <Avatar variant={'small'} profile={avatarObject}/>
          </View>
          <View>
            <View style={[styles.suggestionItemHighlight, contactId === contact.id && styles.itemHover]}>
              {contact.first_name}
            </View>
            <View style={[styles.suggestionItemEmail, contactId === contact.id && styles.itemHover]}>
              {contact.email}
            </View>
          </View>
        </Touchable>
      )
    })
  }

  const renderMembers = () => {
    return members.map((user, index) => {
      const avatarObject = {
        first_name: user.member.first_name,
        avatar: user.member?.avatar,
      }

      return (
        <Touchable
          key={user.id || index}
          style={styles.suggestionItem}
          onClick={() => handleOnSelectValue(user.member)}
          onHover={() => hoverContact(user.id)}
        >
          <View style={styles.avatar}>
            <Avatar variant={'small'} profile={avatarObject}/>
          </View>
          <View style={[styles.suggestionItemHighlight, contactId === user.id && styles.itemHover]}>
            {user.member.first_name}
          </View>
        </Touchable>
      )
    })
  }

  return (
    <View ref={ref} style={styles.wrapper}>
      <Input
        variant={'flex'}
        labelMsg={labelMsg}
        value={value}
        placeholder={placeholder}
        onChangeText={handleOnChangeText}
        onFocus={handleOnFocus}
        {...props}
      />
      {(Boolean(contacts.length) && showSuggestions) && (
        <View style={[styles.suggestionList, suggestionsAsDropdown && styles.suggestionListPopup]}>
          {dataType === 'contacts'
            ? renderContacts()
            : renderMembers()
          }
        </View>
      )}
    </View>
  )
}

const styles = {
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  suggestionList: {
    height: 170,
    overflowY: 'auto',
    backgroundColor: Theme.colors.white,
    ...Theme.paddingVertical(2),
  },
  suggestionListPopup: {
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 999,
    borderColor: '#c4c4c4',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: Theme.borders.borderRadius,
  },
  suggestionItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: Theme.fontFamily,
    color: Theme.colors.black,
    height: 56,
    ...Theme.paddingHorizontal(2),

    '&:hover': {
      backgroundColor: Theme.colors.primary,
    },
  },
  itemHover: {
    color: Theme.colors.white,
  },
  suggestionItemHighlight: {
    color: Theme.colors.black,
  },
  suggestionItemEmail: {
    color: Theme.colors.black,
    marginTop: 4,
  },
  avatar: {
    marginRight: Theme.spacing(2),
  },
}

export default ContactsInput
